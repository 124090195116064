.c-related-profiles--offset {
    .c-related-profiles__list {
        padding-top: $spacer--xl;
        padding-bottom: $spacer--xl;
        display: flex;
    }

    .c-related-profiles__list-item  {
        margin-bottom: 0;
        flex: 1 1 20rem;

        &:nth-child(even) {
            transform: translateY($spacer--xl);
        }
        &:nth-child(odd) {
            transform: translateY(-$spacer--xl);
        }
    }
}

.c-related-profiles--grid {
    .c-related-profiles__list {
        display: grid;
        grid-column-gap: $grid-gutter-width;
        grid-row-gap: $grid-gutter-width;

        .no-cssgrid & {
            @include make-row();
        }

        @include media-breakpoint-down(m) {
            grid-template-columns: repeat(2, auto);
            justify-content: center;
            grid-row-gap: 3rem;
        }

        @include media-breakpoint-up(l) {
            grid-template-columns: repeat(4, 1fr);
            grid-row-gap: 6rem;
        }
    }

    .c-related-profiles__list-item {
        .no-cssgrid & {
            @include make-col-ready();
            flex: 0 0 auto;
            margin-bottom: $grid-gutter-width;

            @include media-breakpoint-down(m) {
                width: 50%;
            }
            @include media-breakpoint-up(l) {
                width: 25%;
            }

        }
    }
}