.c-hero {
    color: $white;
    background-color: $primary-dark;
    display: flex;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-bottom: percentage(232/1400);

    @include media-breakpoint-only(s) {
        padding-top: 5rem;
        min-height: 15rem;
    }
    @include media-breakpoint-only(m) {
        padding-top: 8.5rem;
        min-height: 25rem;
    }
    @include media-breakpoint-up(l) {
        padding-top: 12rem;
        min-height: 37.5rem;
    }
    @media (min-width: 87.5em) {
        padding-bottom: rem(232);
    }

    &:before {
        @include media-breakpoint-up(m) {
            content: '';
            background-image: url('#{$img-path}hero__top.png');
            position: absolute;
            background-repeat: no-repeat;
            background-position: top center;
            background-size: cover;
            width: 100%;
            padding-top: percentage(198/1400);
            height: 0;
            top: 0;
            right: 0;
        }

        @media (min-width: 87.5em) {
            padding-top: rem(198);
            background-size: 100% rem(198);
        }
    }

    &:after {
        content: '';
        background-image: url('#{$img-path}hero__bottom.png');
        position: absolute;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        width: 100%;
        height: 0;
        padding-top: percentage(232/1400);
        bottom: 0;
        right: 0;

        @media (min-width: 87.5em) {
            padding-top: rem(232);
            background-size: 100% rem(232);
        }
    }
    .c-hero__before {
        width: 0;
    }
}

.c-hero--home {
    overflow: hidden;

    &:before,
    &:after {
        z-index: 1;
    }

    .c-hero__content {
        z-index: 2;
    }

    .c-button--video {
        margin-top: 5rem;
    }
}

.c-hero__content {
    z-index: 2;
    position: relative;
}

.c-hero__main {
    padding-top: $spacer--xl;
    padding-bottom: $spacer--2xl;
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;
}

.c-hero__title {
    @include type-scale(3xl, 1);
    color: inherit;
}

.c-hero__logo {
    display: block;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.c-hero__description {
    @include type-scale(m, 1.5);
    color: rgba($white, 0.6);
    margin-top: $spacer--xl;

    > * {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.c-hero__button {
    margin-top: 2.5rem;
}

.c-hero__video {
    @include media-breakpoint-down(s) {
        display: none;
    }
    @include media-breakpoint-up(m) {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        transform: translate(-50%, -50%);
        background-size: cover;
    }
}

.c-hero--contact {
    .c-hero__content {
        @include make-row();

        @include media-breakpoint-up(l) {
            flex-wrap: nowrap;
            align-items: center;
        }
    }
    .c-hero__main,
    .c-hero__aside {
        @include make-col-ready();
        flex: 1 1 50%;
    }

    .c-hero__main {
        text-align: left;
    }

    .c-form {
        @include media-breakpoint-down(m) {
            max-width: 35rem;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.c-hero--linkbuilding {
    .c-hero__main {
        max-width: 48rem;
    }
}
