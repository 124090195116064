.c-brands {
}

.c-brands__title {
    text-align: center;
    margin-top: 0;
    margin-bottom: $spacer--4xl;
}

.c-brands__list {}
.c-brands__item {
    img {
        max-width: 100%;
    }
}



.c-brands--card {

    .c-brands__item {
        img {
            width: 100%;
            border-radius: $border-radius;
            box-shadow: $box-shadow--l;
        }
    }

}


.c-brands--grid {

    .c-brands__list {
        display: grid;

        @include media-breakpoint-down(m) {
            grid-template-columns: repeat(2, auto);
            grid-column-gap: $spacer;
            grid-row-gap: $spacer;
            justify-content: center;  
        }

        @include media-breakpoint-up(l) {
            grid-template-columns: repeat(5, 1fr);
            grid-column-gap: $spacer--m;
            grid-row-gap: $spacer--m;
            padding-top: $spacer--xl;
            padding-bottom: $spacer--xl;
        }

        .no-cssgrid & {
            @include make-row();
            justify-content: flex-start;
        }   
    }

    .c-brands__item {
        @include media-breakpoint-down(m) {
            grid-row-start: span 2;
            max-width: 20rem;
            

            &:nth-child(even) {
                transform: translateY(50%);
            }
        }
        
        @include media-breakpoint-up(l) {
            &:nth-child(even) {
                transform: translateY($spacer--xl);
            }
            &:nth-child(odd) {
                transform: translateY(-$spacer--xl);
            }
        }

        .no-cssgrid & {
            @include make-col-ready;

            @include media-breakpoint-down(m) {
                width: 50%;
                margin-bottom: $spacer--xl;
            }
        
            @include media-breakpoint-up(l) {
                width: 20%;
            }
        }   
    }
    
}

.c-brands--flex {
    .c-brands__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .c-brands__item {
        padding: $spacer--m;
        flex: 0 1 auto;

        @include media-breakpoint-up(l) {
            flex: 0 1 percentage(1/5);
        }
    }
}