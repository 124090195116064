.c-wysiwyg {
    max-width: $wysiwyg-max-width;
    margin-top: $wysiwyg-margin-top;
    margin-bottom: $wysiwyg-margin-bottom;

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }

    p {
        margin-top: $wysiwyg-p-margin-top;
        margin-bottom: $wysiwyg-p-margin-bottom;
    }

    h2 + p,
    h3 + p,
    h4 + p,
    h5 + p,
    h6 + p,
    .h2 + p,
    .h3 + p,
    .h4 + p,
    .h5 + p,
    .h6 + p {
        margin-top: 0;
    }

    ul,
    ol {
        padding-left: $spacer--xl;
        margin-top: $spacer--xl;
        margin-bottom: $spacer--xl;
    }

    ul {
        list-style-type: initial;
    }

    ol {
        list-style-type: decimal;
    }

    img,
    .wp-caption {
        max-width: 100%;
        height: auto;
        margin: $spacer--xl auto;
        display: table;

        @include media-breakpoint-up(m) {
            margin: $spacer--3xl auto;
            
            &.alignleft {
                float: left;
                max-width: 50%;
                margin-right: 2rem;
            }

            &.alignright {
                float: right;
                max-width: 50%;
                margin-left: 2rem;
            }
        }

        .wp-caption-text {
            @include type-scale(s, 1.5);
            display: table-caption;
            caption-side: bottom;
            margin: 0;
            text-align: center;
        }

    }

    .wp-caption img {
        margin: 0 0 $spacer;        
    }

    .wp-caption {
        width: auto !important;//don't let wordpress create different inline width
    }

}


.c-wysiwyg--center {
    margin-left: auto;
    margin-right: auto;
}

h2 + .c-wysiwyg,
h3 + .c-wysiwyg,
h4 + .c-wysiwyg,
h5 + .c-wysiwyg,
h6 + .c-wysiwyg {
    margin-top: 0;
}

