//Label
.c-form__label {
    @include get-styles-from-map($form-label-styles);
}

.c-form__label--error {
    color: $error;
    display: none;

    .c-form__group.has-error & {
        display: block;
    }
}




//Input, Textarea and Select
.c-form__control,
.c-form__textarea {
    @include get-styles-from-map($form-control-styles);

    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    .c-form__group.has-error & {
        @include get-styles-from-map($form-control-error-styles);
    }
}





//Textarea Only
.c-form__textarea {
    height: 6rem;
}





//Checkbox and Radio Inputs
.c-form__checkbox {}

.c-form__checkbox-input,
.c-form__radio-input {
    //display: none;//removing display none here. Bad for ada and html5 validation
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.c-form__label--checkbox,
.c-form__label--radio {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 0;
    font-weight: $font-weight--base;
}

.c-form__label--checkbox:before,
.c-form__label--radio:before {
    @include make-icon();
    display: inline-block;
    width: 1.25rem;
    text-align: left;
}

.c-form__label--checkbox:before {
    content: icon('square-o');
}
.c-form__label--radio:before {
    content: icon('radio_button_unchecked');
}

.c-form__checkbox-input:checked + .c-form__label--checkbox:before {
    content: icon('check-square-o');
}
.c-form__radio-input:checked + .c-form__label--radio:before {
    content: icon('radio_button_checked');
}





//Field help text
.c-form__help-text {}
