.c-work__intro {
    @include section-bg-after__wl--1(false);
}

.c-work__expertise {
    @include section-bg-after__lw--1;
} 



.c-work__approach {
    @include section-bg-after__wl--2;

    .c-section__content {
        display: grid;
        grid-column-gap: $grid-gutter-width * 2;
        grid-row-gap: $grid-gutter-width * 2;


        @include media-breakpoint-down(m) {
            max-width: 40rem;
            margin-left: auto;
            margin-right: auto;
        }

        @include media-breakpoint-up(l) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
@include media-breakpoint-down(m) {
    .c-work__approach-content,
    .c-work__approach-image {
        width: 100%;
    }
}

@include media-breakpoint-up(l) {
    .c-work__approach-content {
        grid-column: 1;
        grid-row: 1;
    }
    .c-work__approach-image {
        grid-column: 2;
        grid-row: 1;
        width: 100%;
        height: 100%;
        display: flex;

        img {
            max-height: 100%;
            object-fit: cover;
        }
    }
}

.c-work__approach-content {
    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}
.c-work__approach-image {
    img {
        width: 100%;
    }
}




.c-work__testimonials {
    .c-section__content {
    }
    .c-testimonials__list {
        @include make-row();
    }
    .c-testimonials__item {
        @include make-col-ready();
        flex: 1 1 30rem;
        margin-bottom: $spacer--2xl;
    }
    .c-blockquote {
        margin-top: 0;
        margin-bottom: 0;
        flex: 1 1 20rem;
    }
}