@include media-breakpoint-down(m) {
    .c-site-menu__list--d1 {
        padding-top: $spacer--5xl;
    }
    .c-site-menu__item--d1 {
        text-align: center;
        padding-bottom: $spacer--l;

        &:last-child {
            padding-bottom: 0;
        }
    }
    .c-site-menu__link--d1 {
        @include type-scale(xl, 1.75);
        font-weight: $font-weight--bold;
        color: $white;
        text-decoration: none;
        padding: 0 $spacer--2xs;

        &:hover {
            text-decoration: none;
            border-bottom: rem(2) solid $white;
            color: $white;
        }
    }
    button.c-site-menu__toggle {
        display: contents;
        color: $white;

        &:hover {
            color: $accent;
        }
    }

    ul.c-site-menu__list--d2 {
        display:none;

        a {
            color: $white;
        }
    }
}
@include media-breakpoint-up(l) {
    .c-site-menu__list--d1 {
        @include media-breakpoint-up(m) {
            display: flex;
        }
    }

    li.c-site-menu__item--d1 {
        @include type-scale(base, 1.2);
        font-family: $font-family--secondary;
        height: auto;
        padding-left: $spacer--xl;
        
        ul.c-site-menu__list--d2 {
            display: none;
        }

        &:hover {
            background: transparent;

            ul.c-site-menu__list--d2 {
                background-color: $white;
                border-radius: 0.25rem;
                display: block;
                padding: 1rem 1.5rem 0.5rem 1rem;
                position: absolute;
                margin-top: -0.25rem;
                min-width: 14rem;
            }
        }

        li.c-site-menu__item--d2 {
            margin-bottom: 0.75rem;
           
            a.c-site-menu__link--d2 {
                border-bottom: solid 0.15rem transparent;
                display: inline;
                margin-bottom: 0.5rem;
                text-decoration: none;
                font-weight: 400;

                &:before {
                    // display: block;
                    font-weight: 800;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                }

                &:hover {
                    border-bottom: solid 0.15rem $accent;
                    font-weight: 800;
                }
            }
        }       

        &.c-site-menu__item--has-children > .c-site-menu__toggle{
            display: none;
            
        }
    }

    .c-site-menu__link--d1 {
        color: $white;
        display: inline-block;
        font-weight: $font-weight--bold;
        padding: $spacer--m 0 $spacer;
        position: relative;
        text-decoration: none;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: rem(2);
            border-radius: rem(2);
            position: absolute;
            bottom: 0;
            left: 0;

            .c-site-menu__item--current & {
                background-color: $secondary;
            }
        }

        &:hover {
            color: $white;
            text-decoration: none;

            &:after {
                background-color: $accent;
            
                .c-site-menu__item--current & {
                    background-color: $secondary;
                }
            }
        }

    }
}