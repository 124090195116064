.c-tabs {
    position: relative;
}

.c-tabs__nav {
    display: flex;
    align-items: baseline;
    justify-content: center;
    justify-content: space-evenly;
    position: sticky;
    padding-top: $spacer;
    padding-bottom: $spacer;
    background-color: $white;
    z-index: 10;
    top: 0; 
}

.c-tabs__tabs {
    margin-top: $spacer--4xl;
    
    @include media-breakpoint-only(m) {
        margin-top: $spacer--5xl;
    }
    @include media-breakpoint-up(l) {
        margin-top: $spacer--6xl;
    }
}

.c-tabs__nav-item {
    list-style: none;
}

.c-tabs__nav-link {
    @include type-scale(l, 1.2);
    display: block;
    text-decoration: none;
    color: $dark;
    font-weight: $font-weight--normal;
    font-family: $font-family--secondary;
    padding: 0.125rem;
    margin: 0 0.5rem;

    &:hover {
        color: $dark;
        border-bottom: rem(2) solid rgba($accent, 0.5);
        text-decoration: none;
    }

    &.is-active {
        font-weight: $font-weight--bold;
        border-bottom: rem(2) solid $accent;
    }
}

.c-tabs__tabs {
}

.c-tabs__tab {
    display: none;

    &.is-active {
        display: block;
    }
}

.c-tabs__tab-content {
}
