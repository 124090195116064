.c-media-block {
    margin-top: $spacer--xl;
    margin-bottom: $spacer--xl;

    @include media-breakpoint-up(l) {
        @include make-row();
        justify-content: space-between;
        align-items: center;
    }
}

.c-media-block__media {
    text-align: center;

    @include media-breakpoint-only(s) {
        margin-bottom: 2rem;
    }

    @include media-breakpoint-only(m) {
        margin-bottom: 3rem;
    }

    @include media-breakpoint-up(l) {
        @include make-col-ready();
        width: 50%;
    }
}
.c-media-block__image {
    position: relative;
    display: inline-block;

    .o-image {
        max-width: 100%;
        z-index: 1;
        position: relative;
    }
}

.c-media-block__content {
    @include media-breakpoint-up(l) {
        @include make-col-ready();
        width: 50%;

        .c-wysiwyg {
            max-width: 100%;
        }
    }

    .c-button {
        margin-right: $spacer;
    }
}

.c-media-block--media-right {
    @include media-breakpoint-up(l) {
        .c-media-block__media {
            order: 2;
        }
    }
}


.c-media-block--media-smaller {
    @include media-breakpoint-up(l) {
        .c-media-block__content {
            @include make-col(7);
        }
        .c-media-block__media {
            @include make-col(5);
        }
    }
}

.c-media-block--third {
    @include media-breakpoint-up(l) {
        .c-media-block__content {
            @include make-col(8);
        }
        .c-media-block__media {
            @include make-col(4);
        }
    }
}
