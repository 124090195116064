h2, 
.h2 {
    &:after {
        content: '';
        display: block;
        width: rem(57);
        height: rem(8);
        background-image: url('../assets/img/underline.svg');
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: $spacer--xl;
    }
}

.c-section-br--text-align-center .h2:after {
    margin-left: auto;
    margin-right: auto;

}