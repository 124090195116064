.c-section {
    overflow-x: visible;
}

.c-section--light {
    background-color: $light;
}


.c-section--primary {
    background-color: $primary;

    .c-section__title,
    .c-section__description,
    .c-careers-list__title {
        color: $white;
    }

    .c-careers-list__location {
        color: rgba($white, 0.8)
    }
    
    .c-careers-list__item {
        border-bottom: rem(1) solid rgba($white, 0.1);
    }

}

.c-section--wide {
    .l-container {
        max-width: 120rem;
    }
}

.c-section__header {
    @include media-breakpoint-only(s) {
        margin-bottom: $spacer--2xl;
    }
    @include media-breakpoint-up(m) {
        margin-bottom: $spacer--4xl;
    }
}

.c-section__title {
    &:after {
        .c-section--center & {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.c-section__footer {
    @include media-breakpoint-only(s) {
        margin-top: $spacer--2xl;
    }
    @include media-breakpoint-up(m) {
        margin-top: $spacer--4xl;
    }
}




.c-section--simple {
    padding-top: $spacer--xl;

    .c-wysiwyg {
        margin-left: auto;
        margin-right: auto;
    }

    h2,
    .h2 {
        margin-top: $spacer--4xl;
    }
}