@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: '';
    }
}

@mixin clearfloat() {
    &:after,
    &:before {
        display: table;
        clear: both;
        content: '';
    }
}
