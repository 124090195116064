.c-list {
    margin-top: $spacer--2xl;
    margin-bottom: $spacer--2xl;
}

.c-list__title {
    @include make-h3();
    display: block;
    margin-bottom: $spacer;
}

.c-list__content {}