.c-form--contact {
    background-color: $white;
    border-radius: $border-radius;
    text-align: left;

    @include media-breakpoint-only(s) {
        padding: $spacer--l;
    }

    @include media-breakpoint-up(m) {
        padding: $spacer--2xl;
    }

    .c-form__submit {
        display: block;
        margin: $spacer--xl auto 0;
    }
}

.c-form__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $spacer--l;

    .no-cssgrid & {
        display: block;
    }
}

.c-form__group {
    margin-bottom: $spacer--s;

    &.has-error {
        .c-form__control,
        .c-form__textarea {
            background-color: rgba($error, 0.1);
        }
    }
}



.c-form__label {
    color: $primary-dark;
    font-family: $font-family--secondary;
}

.c-form__label--error {
    color: $error;
}

.c-form__control,
.c-form__textarea {
    background-color: $light-grey;
    border-color: $light-grey;
    color: $primary-dark;
    border-radius: $border-radius;
}

.c-form__textarea {
    height: 7.5rem;
}


.c-form__group--styled-select {
    display: flex;
    flex-wrap: no-wrap;
    align-items: baseline;

    .c-form__label {
        @include make-h3;
        flex-shrink: 0;
        margin: 0 $spacer--l 0 0;
    }

    .c-form__control {
        @include type-scale(xl);
        font-weight: $font-weight--bold;
        font-family: $font-family--secondary;
        color: $accent;
        border-radius: 0;
        text-transform: lowercase;
        flex-grow: 0;
        width: auto;
        background: transparent;
        border-color: transparent;
        border-bottom: rem(2) solid $accent; 
        padding-left: $spacer--2xs;
        padding-right: $spacer--l;
        appearance: none;
    }
}