.c-pagination {
    @include type-scale(m);
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-pagination__prev,
.c-pagination__next {
    padding: 0.5rem;
    color: $link-color;
    margin: 0 0.5rem;
    text-decoration: none;

    &:hover {
        color: $link-hover-color;
    }
}

//.c-pagination__prev {
//}
//.c-pagination__next {
//}

.c-pagination__pages {
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-pagination__page {
    list-style: none;
    padding: 0.5rem;
}

.c-pagination__page--active {
    .c-pagination__link,
    .c-pagination__link:hover {
        color: $primary;
        border: solid 0.0125rem $primary;
        border-radius: 50%;
        background-color: $white;
    }
}

.c-pagination__link {
    display: block;
    padding: 0.5rem 1.3rem;
    color: $link-color;
    border-radius: 50%;
    background-color: $light;

    &:hover {
        color: $link-hover-color;
    }
}
