.c-modal {
    @include make-card;
    background-color: $white;
    @include media-breakpoint-down(m) {
        padding:  $spacer--2xl $spacer--xl;
    }
    @include media-breakpoint-up(l) {
        padding: $spacer--3xl $spacer--2xl;
    }
}

.c-modal__header {
    margin-bottom: $spacer--xl;
    text-align: center;
}

.c-modal__footer {
    margin-top: $spacer--xl;
    text-align: center;
}

.c-modal__title {
    @include make-h3;
}