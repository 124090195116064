/* Slider styles from slick.js */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none; //sass-lint:disable-line no-misspelled-properties
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: rem(1);
    display: none;

    [dir='rtl'] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: rem(1) solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

/* end Slider styles from slick.js */

/* Dots */

$slick-arrow-color: $white  !default;
$slick-dot-color: $primary  !default;
$slick-dot-color-active: $slick-dot-color  !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 4rem !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 0.75 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-dotted.slick-slider {
    margin-bottom: rem(30);
}

.slick-dots {
    position: absolute;
    bottom: -2rem;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: 0.8rem;
        width: 0.8rem;
        margin: 0 rem(5);
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: 2rem;
            width: 2rem;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: rem(5);
            cursor: pointer;

            &:hover,
            &:focus {
                outline: none;

                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 0.8rem;
                height: 0.8rem;
                font-size: $slick-dot-size;
                line-height: 0.8rem;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}

.c-carousel {
    max-width: 70rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5rem;
    position: relative;
    // max-height: 44rem;

    @include media-breakpoint-only(s) {
        padding: 0 1rem;
    }

    @include media-breakpoint-only(m) {
        padding: 0 1rem;
    }

    img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-up(l) {
            height: 100%;
            max-height: 40rem;
        }
    }

    .slick-list {
        box-shadow: rgba(50, 50, 93, 0.25) 0 rem(50) rem(100) rem(-20), rgba(0, 0, 0, 0.3) 0 rem(30) rem(60) rem(-30);
    }

    .c-carousel__figure {
        margin: 0;
    }

    .c-carousel__figure {
        figcaption {
            background: $primary;
            color: $white;
            position: relative;
            width: 100%;
            bottom: 0;
            text-align: center;
            padding: 1rem 0;

            &:before {
                content: '';
                background-image: url('../assets/img/section-bg/pre-footer.png');
                position: absolute;
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: cover;
                width: 100%;
                height: 0;
                padding-top: 8%;
                bottom: rem(50);
                z-index: 0;
                left: 0;
            }
        }
    }

}

.c-carousel__nav {
    position: absolute;
    width: 100%;
    height: 2rem;
    top: 45%;
    text-align: center;
}

.slick-arrow {
    border: 0;
    color: $primary;
    background-color: $white;
    padding: 1rem;
    border-radius: 100%;
    font-size: 2rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.3) rem(0) rem(19) rem(38), rgba(0, 0, 0, 0.22) rem(0) rem(15) rem(12);

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $secondary;
        color: $white;
    }
}

.slick-prev,
.slick-next {
    position: absolute;
}

.slick-prev {
    left: 1rem;
}

.slick-next {
    right: 1rem;
}