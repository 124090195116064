.c-pagination {
    @include type-scale(m);
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-pagination__prev,
.c-pagination__next {
    padding: 0.5rem;
    color: $link-color;
    margin: 0 0.5rem;

    &:hover {
        color: $link-hover-color;
    }
}

.c-pagination__prev {
}
.c-pagination__next {
}

.c-pagination__pages {
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-pagination__page {
    list-style: none;
    padding: 0 0.5rem;
}

.c-pagination__page--active {
    .c-pagination__link,
    .c-pagination__link:hover {
        border-color: gray('500');
        color: gray('700');
    }
}

.c-pagination__link {
    display: block;
    padding: 0.5rem 0.5rem 0.375rem;
    border-bottom: 0.125rem solid transparent;
    color: $link-color;

    &:hover {
        color: $link-hover-color;
        border-color: $link-hover-color;
    }
}
