.c-action-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $action-block-max-width;
    padding: $action-block-padding;
    text-align: center;
    text-decoration: none;
    border-radius: $action-block-border-radius;
    margin-top: $action-block-margin-top;
    margin-bottom: $action-block-margin-bottom;
    color: $action-block-color;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        text-decoration: none;
        color: $action-block-hover-color;
    }
}

.c-action-block__image {
    margin-bottom: $action-block-image-margin-bottom;
}

.c-action-block__title {
    margin-bottom: $action-block-title-margin-bottom;
    @include make-h4();
}

.c-action-block__copy {
    color: inherit;
}

.c-action-block__button {
    margin-top: $action-block-button-margin-top;
}





.c-action-block--card {
    @include make-card;
    max-width: none;
}

a.c-action-block--card {
    @include make-card-hover;
}

.c-action-block--white {
    background-color: $white;
}

.c-action-block--light {
    background-color: theme-color('light');
}

.c-action-block--card-image-left {
    text-align: left;
    
    .c-action-block__content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }

    .c-action-block__image {
        flex: 0 0 $spacer--4xl;
        margin: 0;
    }

    .c-action-block__title {
        flex: 1 1 auto;
        padding-left: $spacer;
        margin: 0;
    }

    .c-action-block__copy {
        flex: 0 1 100%;
        margin-top: $spacer;
    }

}
