.c-button {
    text-align: center;
}

.c-button--secondary {
    color: $primary;
}

.c-button--icon {
    line-height: 3rem;
}


.c-button--video {
    color: $primary;
    background-color: transparent;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    position: relative;

    .u-hover-children:hover &,
    &:hover {
        background-color: transparent;

        .o-icon {
            color: $white;
            background-color: $secondary;
        }

        &:before {
            width: 137.5%;
            height: 137.5%;
        }
        &:after {
            width: 175%;
            height: 175%;
        }
    }

    &:before,
    &:after {
        width: 150%;
        height: 150%;
        content: '';
        display: block;
        left: 50%;
        top: 50%;
        border-radius: 100%;
        background: rgba($white, 0.12);
        position: absolute;
        transform: translate(-50%, -50%);
        transition: all 0.2s ease-in-out;
        z-index: 0;
    }

    &:after {
        width: 200%;
        height: 200%;
    }

    .o-icon {
        color: $primary;
        position: relative;
        z-index: 1;
        width: 100%;
        background-color: $white;
        border-radius: 100%;
        transition: all 0.2s ease-in-out;
    }
}

.c-cta__button {
    text-align: center;
}
