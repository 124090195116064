.c-cta--footer {
    @include section-bg__pre-footer;
    margin: 0;
    width: 100%;
    max-width: 100%;
    color: $white;
    border: 0;
    padding-top: percentage(90/1400);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: 87.5em) {
        padding-bottom: rem(90);
    }

    .c-cta__title {
        @include type-scale(xl);
        margin-top: 7rem;
        margin-bottom: $spacer--xl;
    }

    .c-cta__button {
        margin-top: 0;
        margin-bottom: 6rem;
    }

    .c-section + & {
        @include section-bg__wt--1(false);
    }

    .c-section--light + & {
        @include section-bg__lt--1(false);
    }
}



.c-cta--inline {
    border: 0;
    color: $white;

    .c-cta__title {
        @include get-type-scale(5, 1.2);
        margin-bottom: $spacer;
    }
}