@mixin make-card(
    $shadow-distance: $card-shadow-distance,
    $shadow-blur: $card-shadow-blur,
    $shadow-spread: $card-shadow-spread,
    $shadow-color: $card-shadow-color
) {
    box-shadow: 0 $shadow-distance $shadow-blur $shadow-spread $shadow-color;
}

@mixin make-card-hover(
    $shift: $card-hover-shift,
    $shadow-distance: $card-shadow-distance,
    $shadow-blur: $card-shadow-blur,
    $shadow-spread: $card-shadow-spread,
    $shadow-color: $card-shadow-color,
    $transition: $card-hover-transition
) {
    transform: translateY(0);
    transition: $transition;

    &:hover {
        transform: translateY(-$shift);
        box-shadow: 0 ($shadow-distance + $shift) $shadow-blur ($shadow-spread + $shift)
            $shadow-color;
    }
}
