//.c-profile-block {

//}

.c-profile-block-small {
    display: flex;
    align-items: center;
}

.c-profile-block--stacked {
    flex-direction: column;

    .c-profile-block__image-wrapper {
        .c-profile-block__image {
            width: 100%;
            box-shadow: $box-shadow;
            margin-bottom: $spacer;
            border-radius: $border-radius--s;
        }
    }

    .c-profile-block__name {
        @include type-scale(l,1.2);
    }
}


.c-profile-block__content-small {
    text-align: left;
    margin-left: 1rem;
}

.c-profile-block__name-small {
    font-size: 1.125rem;
    display: block;
    margin: 0 0 -0.25rem 0;
}

.c-profile-block__title-small {
    font-size: 1rem;
    display: block;
    margin: 0;
}

.c-profile-block__name {
    font-size: 1.25rem;
    line-height: 1.2;
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    color: $primary;
    display: block;
    margin-left: 0;
    margin-right: 0;
}

.c-profile-block__title {
    font-size: 1rem;
    line-height: 1.25;
    font-family: 'Muli', sans-serif;
    font-weight: 400;
    color: $accent-dark;
    margin-bottom: 1rem;
    display: block;
    margin-left: 0;
    margin-right: 0;
}

.c-profile-block__image-small {
    border-radius: $border-radius--circle;
    max-width: 120px;
    max-height: 120px;
}

.c-profile-block__after {
    color: $grey;
}