.c-profile-single {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding-top: 3.5rem;
    border-top: 2px solid $light-grey;

    @include media-breakpoint-only(s) {
        padding: 2rem 1rem 0 1rem;
        align-items: center;

        .c-profile-single__social {
            padding-top: 1.5rem;
        }
    }

    @include media-breakpoint-only(m) {
        padding: 2rem 1rem 0 1rem;
    }

    .c-profile-single__content {
        flex-grow: 2;
    }

}


.c-profile-single--stacked {
    flex-direction: column;

    .c-profile-single__image {
        max-width: 100%;
        box-shadow: $box-shadow;
        margin-bottom: $spacer;
        border-radius: $border-radius;

        @include media-breakpoint-only(s) {
            max-width: 75%;
        }
    }
    .c-profile-single__name {
        @include type-scale(l,1.2);
    }
}


.c-profile-single__content {
    text-align: left;
    margin-left: 2rem;

    @include media-breakpoint-only(s) {
        margin-left: .5rem;
    }
}

.c-profile-single__intro {
    max-width: 50rem;
    margin: 2rem auto;

    @include media-breakpoint-only(s) {
        padding: 0 1rem;
    }

    @include media-breakpoint-only(m) {
        padding: 0 1rem;
    }
}

.c-profile-single__name {
    font-size: 1.625rem;
    display: block;
    margin: 0;
    font-family: 'Metropolis', sans-serif;
    font-weight: 600;
    color: $primary;
}

.c-profile-single__title {
    font-size: 1.125rem;
    display: block;
    margin: 0;
    color: $accent-dark;
}

.c-profile-single__image {
    border-radius: $border-radius;
    max-width: 120px;
    max-height: 120px;
}

.c-profile-single__after {
    color: $grey;
}

.c-profile-single__link {
    text-decoration: none;
    font-size: 2rem;
    padding-left: 1rem;

    /*.o-icon {
        background-color: $primary;
        color: white;
        border-radius: $border-radius--circle;
        width: 7rem;
        height: 7rem;
        padding: .875rem;
    }*/

    &:hover {
        text-decoration: none;
    }

    @include media-breakpoint-only(s) {
        padding-left: 0;
        padding-right: 1rem;
    }
}