.c-figure {
    display: table;
    max-width: 100%;
    margin-top: $spacer--2xl;
    margin-bottom: $spacer--2xl;
    margin-left: auto;
    margin-right: auto;
}

.c-figure__image-link {
    display: block;
}

.c-figure__image {
    margin: 0 auto;
    display: block;
    max-width: 100%;
}

.c-figure__figcaption {
    @include type-scale(s, 1.5);
    display: table-caption;
    caption-side: bottom;
    margin-top: $spacer;
}

.c-figure__caption {
    font-style: italic;
}

.c-figure__sources {
    @include type-scale(xs, 1.5);
    margin-top: $spacer--s;
}

.c-figure--right {
    @include media-breakpoint-up(m) {
        float: right;
        max-width: 50%;
        margin: 0 0 $spacer--xl $spacer--xl;
    }
}

.c-figure--left {
    @include media-breakpoint-up(m) {
        float: left;
        max-width: 50%;
        margin-top: 0;
        margin: 0 $spacer--xl $spacer--xl 0;
    }
}
