@font-face {
    // sass-lint:disable-block indentation
    font-family: 'icomoon';
    src: url('#{$icon-font-folder}icomoon.eot?#{$icon-font-hash}');
    src: url('#{$icon-font-folder}icomoon.eot?#{$icon-font-hash}#iefix')
            format('embedded-opentype'),
        url('#{$icon-font-folder}icomoon.woff2?#{$icon-font-hash}')
            format('woff2'),
        url('#{$icon-font-folder}icomoon.ttf?#{$icon-font-hash}')
            format('truetype'),
        url('#{$icon-font-folder}icomoon.woff?#{$icon-font-hash}')
            format('woff'),
        url('#{$icon-font-folder}icomoon.svg?#{$icon-font-hash}#icomoon')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.o-icon {
    @include make-icon;
}

@each $key, $value in $icons {
    .o-icon--#{$key}:after {
        content: $value;
    }
}
