.c-page--one-column.c-digital-pr {
    .c-section--center {
        padding-bottom: 6rem;
    }

    .c-digital-pr__intro {
        padding-bottom: 0;

        h2.c-section__title {
            max-width: 50rem;
            margin: 0 auto;
        }
        .c-wysiwyg {
            max-width: 58rem;
        }
    }
    
    .c-digital-pr__focus {
        margin: 0 auto 6rem;
        max-width: 58rem;
        padding-top: 0;
        padding-bottom: 2rem;
    }
    
    .c-digital-pr__info-1 {

        &.c-section.c-section--light {
            padding-top: 8rem;
            padding-bottom: 9rem;
        }

        .c-action-block.c-action-block--card {
            background-color: $white;
            display: block;

            .c-action-block__image {
                text-align: left;
                margin-bottom: 2rem;
            }
        }

        .c-action-block__title {
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }
    }
    
    .c-digital-pr__media-block {
        display: block;

        .c-digital-pr__info-2-image img {
            display: block;
            margin: auto;
            max-width: -moz-available;
            max-width: -webkit-fill-available;
            max-width: stretch;
            text-align: center;
        }

        @include media-breakpoint-up(l) {
            display: flex;
            column-gap: 2rem;

            .c-digital-pr__info-2-image,
            .c-digital-pr__media-block-content {
                width: 48%;
            }
        }
        
        h3 {
            margin-top: 2rem;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    
    .c-digital-pr__spotlight {
        margin: 0 auto;
        max-width: 54rem;
        padding-bottom: 2rem;

        .c-digital-pr__spotlight-article {
            @include make-card;
            border-radius: 0.375rem;
            margin-bottom: 2rem;
            padding: 2rem;

            @include media-breakpoint-up(l) {
                margin-bottom: 4rem;
            }
        }
    }
    
    .c-digital-pr__info-1,
    .c-digital-pr__as-seen {
      @include section-bg-before__wl--3;
      @include section-bg-after__lw--2;
    }


    .c-digital-pr__as-seen {

        &.c-section--center {
            padding: 4rem 0 6rem;
        }
        h2 {
            text-align: center;
            &::after {
                margin: 1rem auto 0;
            }
        }
        .c-digital-pr__as-seen-list {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            grid-gap: 2rem;
            justify-content: center;
            margin: auto;
            padding: 0 2rem;

            @include media-breakpoint-up(l) {
                column-gap: 5rem;
            }
        
    
            .c-digital-pr__as-seen-logo img {
                max-width: 100%;
                max-height: 12.5rem;
                height: auto;
                width: auto;
            }
        }

    }
    
}

