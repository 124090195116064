.c-launchcon__intro {
    @include media-breakpoint-up(xl) {
        padding-bottom: 9rem;
    }
}

.c-launchcon__video {
    @include section-bg-before__wl--1;
    @include section-bg-after__lw--1;
    padding-top: 0;
}
