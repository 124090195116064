.c-action-list {
    .c-action-block--card {
        height: 100%;
    }
}

.c-action-list__items {
    display: grid;

    .no-cssgrid & {
        @include make-row();
    }

    @include media-breakpoint-only(s) {
        grid-gap: $spacer--2xl;
    }

    @include media-breakpoint-up(m) {
        grid-gap: $spacer--3xl;
        grid-row-gap: $spacer--4xl;
    }

    @include media-breakpoint-up(l) {
        grid-template-columns: repeat(2, auto);
    }

    @include media-breakpoint-up(xl) {
        grid-gap: $spacer--5xl;
    }
}
.c-action-list__item {
    padding: 0;

    .no-cssgrid & {
        @include make-col-ready();

        @include media-breakpoint-up(l) {
            width: 50%;
        }
    }
}
.c-action-block {
    margin-bottom: 0;
}
.c-action-block__media {
    flex: 0 0 auto;
}
.c-action-block__content {
    flex: 1 1 auto;
}



.c-action-list--offset {
    @include media-breakpoint-up(l) {

        .c-action-list__item {
            &:nth-child(odd) {
                padding-bottom: $spacer--4xl;
            }
            &:nth-child(even) {
                padding-top: $spacer--4xl;
            }
        }
    }
}








.c-action-list--events {
    .c-action-list__items {
        grid-template-columns: repeat(auto-fit, minmax(1fr, 25rem));
        grid-column-gap: $grid-gutter-width;
        justify-content: space-evenly;
    }
    .c-action-block {
        flex-direction: column;
        max-width: 25rem;
    }
    .c-action-block__media {
        width: 100%;
        margin-bottom: $spacer--xl;
    }
    .c-action-block__image {
        margin-right: 0;
    }
    .o-image {
        max-width: 100%;
        border-radius: 0.25rem;
    }
}



.c-action-list--3-col .c-action-list__items {
    grid-template-columns: repeat(3,auto);
}

.c-action-block--card-image-left {
    .c-action-block__content {
        flex-wrap: nowrap;

        .c-action-block__image img {
            max-width: 4rem;
            margin-right: 1rem;
        }
    }

}

.c-action-list--vertical-card {
    .c-action-list__item .c-action-block {
        background-color: $white;
        display: block;
        padding: 2rem;
    }

    .c-action-block {
        @include make-card;
    }

    .c-action-block__title {
        margin: 2rem auto;
        text-align: center;
    }

    .c-action-block__image {
        margin-right: 0;
    }

    .c-action-block__copy {
        margin-bottom: 2rem;
    }
    
}