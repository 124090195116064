.c-related-posts {
    margin-top: 4rem;
    padding: 4rem 5rem 2rem 5rem;
    max-width: 100%;
    background-color: $light;

    @include media-breakpoint-only(s) {
        padding: 2rem 1rem;
        margin-top: 2rem;
    }

    @include media-breakpoint-only(m) {
        padding: 2rem 1rem;
        margin-top: 2rem;
    }
}

.c-related-posts__title {
    margin-bottom: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &::after {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}

.c-related-posts__list {
    display: flex;
    align-items: stretch;
    overflow-x: auto;
    justify-content: center;
}

.c-related-posts__item {
    padding: 0 1rem;
    min-width: 18rem;
    display: flex;
    align-items: stretch;

    .c-post-block {
        margin-top: 0.25rem;
        margin-bottom: 1rem;
    }

    .c-post-block--card {
        &,
        .c-post-block__inner,
        .c-post-block__content {
            display: flex;
            align-items: stretch;
        }
        .c-post-block__inner,
        .c-post-block__content {
            flex-direction: column;
        }
        .c-post-block__content {
            flex-grow: 1;
        }
        .c-post-block__after {
            margin-top: auto;
        }
    }
}
