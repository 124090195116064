.c-ol {
    margin-bottom: 0;
    padding-left: 1.5rem;
}

.c-ol__item {
    margin-bottom: 0.75rem;

    &:last-child {
        margin-bottom: 0;
    }
}
