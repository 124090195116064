.c-ul {
    margin-bottom: 0;
    padding-left: $spacer--xl;
    list-style: initial;
}

.c-ul__item {
    margin-bottom: $spacer;

    &:last-child {
        margin-bottom: 0;
    }
}


.c-ul--split {
    column-count: 2;
    column-gap: $spacer--2xl;
}
