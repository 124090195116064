.c-button {
    @include make-button();
}

@each $color, $value in $theme-colors {
    .c-button--#{$color} {
        @include make-button-variant($value);
    }
}

@each $color, $value in $social-colors {
    .c-button--#{$color} {
        @include make-button-variant($value);
    }
}

.c-button--outline {
    @include make-button-variant(
        transparent,
        theme-color('primary'),
        theme-color('primary'),
        theme-color('primary'),
        theme-color('primary'),
        $white
    );
}

.c-button--gradient {
    background-image: linear-gradient(90deg, #084c7f, #34c6f4, #084c7f);
    background-size: 200% 200%;
    background-position: left;
    border-color: transparent;
    color: #ffffff;

    .u-hover-children:hover &,
    &:hover {
        border-color: transparent;
        background-position: right;
        color: #ffffff;
    }
}

.c-button--xs {
    @include make-button-xs();
}

.c-button--s {
    @include make-button-s();
}

.c-button--l {
    @include make-button-l();
}

.c-button--link {
    background: none;
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
    color: theme-color('primary');
    font-weight: bold;

    @supports (text-decoration-color: transparent) {
        text-decoration: underline;
        text-decoration-color: transparent;
    }

    .u-hover-children:hover &,
    &:hover {
        color: color-level(theme-color('primary'), 3);
        background-color: transparent;
        border-color: transparent;
        text-decoration: underline;
    }
}

.c-button--line {
    background: none;
    border-color: transparent;
    color: $white;
    font-weight: bold;
    border-radius: 0;
    padding: 0.25rem 0.5rem;
    border-bottom: rem(2) solid $white;
    border-left: 0;
    border-right: 0;

    .u-hover-children:hover &,
    &:hover {
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: $white;
    }
}

.c-button--icon {
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
    text-align: center;
    padding: 0;
    border: 0;
    font-size: inherit;
    line-height: 1;

    &.c-button--xs {
        width: 1.5rem;
        height: 1.5rem;
    }

    &.c-button--s {
        width: 2rem;
        height: 2rem;
    }
}

.c-button--ghost {
    color: inherit;
    background-color: transparent;
    border-color: transparent;

    .u-hover-children:hover &,
    &:hover {
        background-color: transparent;
        border-color: transparent;
        color: inherit;
    }
}
