.c-post-block {
    max-width: 30rem;
    margin-bottom: 2rem;
    position: relative;

    a {
        position: relative;
        z-index: 1;
        text-decoration: none;
    }
}

.c-post-block__image-wrapper {
    position: relative;

    img {
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &::after {
        display: block;
        content: '';
        /* 16:9 aspect ratio */
        padding-bottom: 56.25%;
    }
}

.c-post-block__image {
    width: 100%;
    display: block;
    border-radius: 8px;
}

.c-post-block__content {
    padding: 1.5rem 0;
}

.c-post-block__copy {
    padding: 1rem 0;
}

.c-post-date {
    color: $grey;
    font-size: .875rem;
}

//.c-post-block__before {
//}

.c-post-block__category {
    @include type-scale(s, 1.25);
    text-transform: uppercase;
    letter-spacing: rem(1);
    color: $accent-dark;
    margin-right: 2rem;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }
}

a.c-post-block__link {
    @include cover-parent;
}

.c-post-block__title {
    //@include make-h4;
    @include type-scale(xl, 1.25);
    color: $primary;
    font-weight: 400;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    transition: all 0.2s ease-in-out;

    @supports (text-decoration-color: transparent) {
        text-decoration: underline;
        text-decoration-color: transparent;
    }

    .c-post-block__link:hover & {
        color: theme-color('primary');
        text-decoration: underline;
        text-decoration-color: theme-color('primary');
    }
}

//.c-post-block__copy {
//}

.c-post-block__meta {
    margin-top: 1rem;
}

.c-post-block__button {
    margin-top: 1rem;
}

//.c-post-block__after {
//}

.c-post-block--card {
    .c-post-block__inner {
        @include make-card-hover;
        border-radius: 1rem;
    }

    .c-post-block__image {
        border-radius: 1rem 1rem 0 0;
    }

    .c-post-block__content {
        padding: 1.5rem;
    }

    &.c-post-block--horizontal {
        .c-post-block__image {
            border-radius: 1rem 0 0 1rem;
        }
        .c-post-block__content {
            padding: 1.5rem;
        }
    }
}

@mixin make-post-block-horizontal {
    .c-post-block__inner {
        display: flex;
        align-items: stretch;
    }

    .c-post-block__image-wrapper {
        width: percentage(1/3);
        flex-grow: 0;
        flex-shrink: 0;
    }

    .c-post-block__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .c-post-block__content {
        padding: 0 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

}

@mixin make-post-block-horizontal-minimal {
    @include make-post-block-horizontal;

    .c-post-block__image-wrapper {
        width: percentage(1/4);
    }

    .c-post-block__content {
        padding: 0 1rem;
    }

    .c-post-block__category,
    .c-post-block__copy,
    .c-profile-block {
        display: none;
    }
}

.c-post-block--horizontal {
    @include make-post-block-horizontal;
    max-width: 60rem;
}