.c-speakers {
    @include media-breakpoint-only(s) {
        margin-top: $spacer--4xl;
    }
    @include media-breakpoint-only(m) {
        margin-top: $spacer--5xl;
    }
    @include media-breakpoint-up(l) {
        margin-top: $spacer--6xl;
    }

    .c-profile-block {
        @include make-row();

        @include media-breakpoint-up(m) {
            justify-content: space-evenly;
            flex-wrap: nowrap;
        }
    }
    .c-profile-block__image-wrapper {
        @include make-col-ready();
        flex: 0 1 17rem;
        margin-bottom: $spacer;
    }
    .c-profile-block__content {
        @include make-col-ready();
        flex: 0 1 40rem;
    }
    .c-profile-block__image {
        max-width: 100%;
        display: block;
    }
}

.c-speakers__heading {
    text-align: center;

    &:after {
        margin-left: auto;
        margin-right: auto;
    }
}

.c-speakers__list-item {
    @include media-breakpoint-only(s) {
        margin-top: $spacer--4xl;
    }
    @include media-breakpoint-only(m) {
        margin-top: $spacer--5xl;
    }
    @include media-breakpoint-up(l) {
        margin-top: $spacer--6xl;
    }
}