.c-section {
    padding-top: $section-padding-top--s;
    padding-bottom: $section-padding-bottom--s;
    overflow-x: hidden;

    @include media-breakpoint-up(m) {
        padding-top: $section-padding-top--m;
        padding-bottom: $section-padding-bottom--m;
    }

    @include media-breakpoint-up(l) {
        padding-top: $section-padding-top--l;
        padding-bottom: $section-padding-bottom--l;
    }
}

.c-section__header {}

.c-section__title {}

.c-section__description {
    margin-top: $section-description-margin-top;
    margin-bottom: $section-description-margin-bottom;
}

.c-section__content {}

.c-section__footer {}

.c-section__button {}





//color variants
.c-section--light {
    background-color: theme-color('light');
}




//alignment variants
.c-section--center {
    .c-section__header,
    .c-section__footer {
        text-align: center;
    }
    .c-wysiwyg {
        margin-left: auto;
        margin-right: auto;
    }
}
