.c-blockquote {
    max-width: 40rem;
    width: 100%;
    margin: 2rem auto;
    text-align: center;
}

.c-blockquote__image {
    display: block;
    margin: 0 auto;
    border-radius: 100%;
}

.c-blockquote__quote {
    @include type-scale(l);
    margin: 1rem auto;
    font-family: $font-family--secondary;
}

.c-blockquote__footer {
}

.c-blockquote__title {
    @include type-scale(m);
    display: block;
}

.c-blockquote__subtitle {
    @include type-scale(s);
    display: block;
    text-transform: uppercase;
    letter-spacing: rem(1);
    font-weight: $font-weight--bold;
    color: gray('600');
}

.c-blockquote__sources {
    @include type-scale(xs, 1.5);
    margin-top: $spacer--xs;
}

.c-blockquote--left {
    text-align: left;
}
.c-blockquote--right {
    text-align: right;
}

.c-blockquote--small {
    .c-blockquote__quote {
        @include type-scale(m);
    }
    .c-blockquote__title {
        @include type-scale(s);
    }
    .c-blockquote__subtitle {
        @include type-scale(xs);
    }
}
