.c-video {
    display: block;
    position: relative;
    width: 100%;
}

.c-video__link {
    display: block;
}

.c-video__image {
    max-width: 100%;
    position: relative;
    z-index: 1;
}

.c-video__details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: $spacer;

    @include media-breakpoint-up(m) {
        padding: $spacer--l;
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        transform-origin: bottom center;
        transition: 0.2s all ease-in-out;
        transform: scale(1, 1);

        .c-video__link:hover & {
            transform: scale(1, 1.5);
        }
    }
}

.c-video__titles {
    margin-left: $spacer;
    position: relative;
    color: $white;

    @include media-breakpoint-up(m) {
        margin-left: $spacer--l;
    }
}
.c-video__title {
    display: block;
}
.c-video__subtitle {
    @include media-breakpoint-only(s) {
        display: none;
    }
}

.c-video__cta {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba($dark, 0.95);
    color: $white;

    &.is-active {
        display: flex;
    }
}

.c-video__cta-close {
    @include type-scale(xl, 1);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    z-index: 2;
}


.c-video__replay {
    @include media-breakpoint-only(s) {
        display: none;
    }
}
