.c-careers-list {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
}

.c-careers-list__item {
    border-bottom: rem(1) solid $gray-100;

    &:last-child {
        border-bottom: none;
    }
}

.c-careers-list__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding-top: $spacer--xl;
    padding-bottom: $spacer--xl;

    &:hover {
        text-decoration: none;
    }
}

.c-careers-list__title {
    @include make-h3;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: $grid-gutter-width;

    .c-careers-list__link:hover & {
        text-decoration: underline;
    }
}

.c-careers-list__location {
    @include type-scale(m);
    flex: 0 0 auto;
    color: $gray-600;
}