.c-social-links {
}

.c-social-links__title {
    padding-bottom: 0.5rem;
}

.c-social-links__list {
}

.c-social-links__item {
    @include type-scale(l);
    display: inline-block;
    margin-left: 1rem;

    &:first-child {
        margin-left: 0;
    }
}

.c-social-links--color {
    @each $color, $value in $social-colors {
        .o-icon--#{$color} {
            color: $value;
        }
    }
}
