@mixin section-bg-before($img-src, $height, $translate: false) {
    position: relative;
    padding-top: percentage($height/1400);

    @media (min-width: 87.5em) {
        padding-top: rem($height);
    }

    > .l-container {
        position: relative;
        z-index: 1;
    }

    &:before {
        content: '';
        background-image: url($img-src);
        position: absolute;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        width: 100%;
        height: 0;
        padding-top: percentage($height/1400);
        top: 0;
        left: 0;
        z-index: 0;
        @if $translate {
            transform: translateY(-50%);
        }

        @media (min-width: 87.5em) {
            padding-top: rem($height);
            background-size: 100% rem($height);
        }
    }
}
@mixin section-bg-after($img-src, $height, $translate: false) {
    position: relative;
    padding-bottom: percentage($height/1400);

    @media (min-width: 87.5em) {
        padding-bottom: rem($height);
    }

    > .l-container {
        position: relative;
        z-index: 1;
    }

    &:after {
        content: '';
        background-image: url($img-src);
        position: absolute;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        width: 100%;
        height: 0;
        padding-top: percentage($height/1400);
        bottom: 0;
        z-index: 0;
        left: 0;
        @if $translate {
            transform: translateY(50%);
        }

        @media (min-width: 87.5em) {
            padding-top: rem($height);
            background-size: 100% rem($height);
        }
    }
}

@mixin section-bg-after__wl--1($translate: true) {
    @include section-bg-after(
        '#{$img-path}section-bg/wl--1.png',
        340,
        $translate
    );
}
@mixin section-bg-before__wl--1($translate: true) {
    @include section-bg-before(
        '#{$img-path}section-bg/wl--1.png',
        340,
        $translate
    );
}

@mixin section-bg-after__wl--2($translate: true) {
    @include section-bg-after(
        '#{$img-path}section-bg/wl--2.png',
        164,
        $translate
    );
}
@mixin section-bg-before__wl--2($translate: true) {
    @include section-bg-before(
        '#{$img-path}section-bg/wl--2.png',
        164,
        $translate
    );
}

@mixin section-bg-after__wl--3($translate: true) {
    @include section-bg-after(
        '#{$img-path}section-bg/wl--3.png',
        96,
        $translate
    );
}
@mixin section-bg-before__wl--3($translate: true) {
    @include section-bg-before(
        '#{$img-path}section-bg/wl--3.png',
        96,
        $translate
    );
}

@mixin section-bg-after__lw--1($translate: true) {
    @include section-bg-after(
        '#{$img-path}section-bg/lw--1.png',
        115,
        $translate
    );
}
@mixin section-bg-before__lw--1($translate: true) {
    @include section-bg-before(
        '#{$img-path}section-bg/lw--1.png',
        115,
        $translate
    );
}

@mixin section-bg-after__lw--2($translate: true) {
    @include section-bg-after(
        '#{$img-path}section-bg/lw--2.png',
        80,
        $translate
    );
}
@mixin section-bg-before__lw--2($translate: true) {
    @include section-bg-before(
        '#{$img-path}section-bg/lw--2.png',
        80,
        $translate
    );
}

@mixin section-bg__wt--1($translate: true) {
    @include section-bg-before(
        '#{$img-path}section-bg/wt--1.png',
        90,
        $translate
    );
}
@mixin section-bg__lt--1($translate: true) {
    @include section-bg-before(
        '#{$img-path}section-bg/lt--1.png',
        90,
        $translate
    );
}

@mixin section-bg__pre-footer($translate: false) {
    @include section-bg-after(
        '#{$img-path}section-bg/pre-footer.png',
        112,
        $translate
    );
    padding-bottom: percentage(112/1400);

    &:after {
        bottom: rem(-1);
    }

    @media (min-width: 87.5em) {
        padding-bottom: rem(112);
    }
}

@mixin section-bg-after__tp--1() {
    @include section-bg-after(
        '#{$img-path}section-bg/pre-footer.png',
        112,
        false
    );
    padding-bottom: 0;

    @media (min-width: 87.5em) {
        padding-bottom: 0;
    }

    &:after {
        bottom: rem(-1);
    }

    &:after {
        display: block;
        position: relative;

        @include media-breakpoint-only(s) {
            margin-top: 3rem;
        }
        @include media-breakpoint-only(m) {
            margin-top: 4.5rem;
        }
        @include media-breakpoint-up(l) {
            margin-top: 6rem;
        }
    }
}

@mixin section-bg-before__pt--1() {
    @include section-bg-before('#{$img-path}section-bg/pt--1.png', 112, false);
    padding-top: percentage(112/1400);
    padding-top: 0;

    @media (min-width: 87.5em) {
        padding-top: 0;
    }

    &:before {
        bottom: rem(-1);
    }

    &:before {
        display: block;
        position: relative;

        @include media-breakpoint-only(s) {
            margin-bottom: 3rem;
        }
        @include media-breakpoint-only(m) {
            margin-bottom: 4.5rem;
        }
        @include media-breakpoint-up(l) {
            margin-bottom: 6rem;
        }
    }
}
