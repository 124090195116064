.c-page {
}

.c-page__header {
}

.c-page__content {
}

.c-page__main {
    margin-bottom:$spacer--4xl;
}

.c-page__aside {
    margin-bottom: $spacer--4xl;
}

.c-page__footer {
    padding-bottom: $spacer--3xl;
}

.c-page--two-column {
    .c-page__content {
        @include make-row;
    }
    .c-page__main {
        @include make-col-ready;

        @include media-breakpoint-up(l) {
            width: percentage(2/3);
        }
    }
    .c-page__aside {
        @include make-col-ready;

        @include media-breakpoint-up(l) {
            width: percentage(1/3);
        }
    }
}

.c-page--one-column {

    .c-page__header {
        margin-bottom: 0;
    }   

    .c-page__content .c-hummingbird {
        max-width: 75rem;
        margin: $spacer auto;
        padding: 0 $spacer;
        position: relative;
    }

    .c-content__after {

        position: relative;
        padding-left: $spacer;
        padding-right: $spacer;
        text-align: center;

        .c-share {
            text-align: center;
        }

        .c-share__list {
            justify-content: center;
        }

        > * {
            max-width: $content-builder-max-width;
            margin-left: auto;
            margin-right: auto;
        }

        > :first-child {
            margin-top: $spacer--xl;
            border-top: none;
            padding-top: 0;
        }

        > :last-child {
            margin-bottom: $spacer--xl;
        }

    }

    .c-page__main {
        margin-bottom: 0;
    }

}
