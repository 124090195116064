// mixin to get sass map of styles from variables and return them
@mixin get-styles-from-map( $map ) {
    // check if type-scale and line height are included in the map
    @if map-get($map, type-scale) or map-get($map, line-height) {
        // type-scale defaults to 'base'
        $ts: if(map-get($map, type-scale), map-get($map, type-scale), base );
        // line-height defaults to '$line-height--base'
        $lh: if(map-get($map, line-height), map-get($map, line-height), $line-height--base);
        // run type scale mixin based off the type-scale or line-height provided
        @include type-scale($ts, $lh);
    }

    // each key, value in style map should be returned as prop: value
    @each $prop, $value in $map {
        // outputs all iterations except type-scale or line height
        @if not ($prop == type-scale or $prop == line-height) {
            #{$prop}: #{$value};
        }
    }
}

@import 'buttons';
@import 'breakpoints';
@import 'card';
@import 'clearfix';
@import 'cover-parent';
@import 'grid';
@import 'icons';
@import 'link';
@import 'list';
@import 'screenreaders';
@import 'typography';
