.c-about {
    .c-page__main {
        @include section-bg__pre-footer;
    }
}


.c-about__intro {
    @include section-bg-after__wl--1(false);

    &:after {
        border-bottom: 10rem solid $light;
    }

    .c-figure {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;

        @include media-breakpoint-only(s) {
            margin-top: $spacer--2xl;
        }
        @include media-breakpoint-only(m) {
            margin-top: $spacer--3xl;
        }
        @include media-breakpoint-only(l) {
            margin-top: $spacer--4xl;
        }
        @include media-breakpoint-only(xl) {
            margin-top: $spacer--5xl;
        }
    }
}


.c-about__vision {
    @include section-bg-after__lw--1;

    .c-brands {
        @include media-breakpoint-only(s) {
            margin-top: $spacer--2xl;
        }
        @include media-breakpoint-only(m) {
            margin-top: $spacer--3xl;
        }
        @include media-breakpoint-only(l) {
            margin-top: $spacer--4xl;
        }
        @include media-breakpoint-only(xl) {
            margin-top: $spacer--5xl;
        }
    }
}  




.c-about__values {
    .c-action-list__items {
        grid-gap: $grid-gutter-width;
    }
}

.c-about__leadership {
    .c-related-profiles__list {
        @include media-breakpoint-up(l) {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}

.c-about__celebrate {
    @include section-bg-before__wl--3();
    @include section-bg-after__lw--2(false);

    &:after {
        border-bottom: 10rem solid $white;
    }
}




.c-about__invest {
    .c-media-block {
        margin-bottom: $spacer--5xl;

        &:last-child {
            margin-bottom: 0; 
        }
    }
}