.l-page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.c-page {
    flex: 1;
}

.l-container {
    @include make-container;
    max-width: $site-max-width;
}

.l-row {
    @include make-row;
}

.c-page--one-column > .l-container {
    max-width: $content-builder-max-width + ($spacer * 2);
}
