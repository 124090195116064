.c-home {
    .c-page__main {
        @include section-bg__pre-footer;
    }
}


.c-home__intro {
    @include section-bg-after__wl--1(false);

    &:after {
        border-bottom: 10rem solid $light;
    }
}


.c-home__what {
    @include section-bg-after__lw--1;
}