.c-media-block {
    align-items: center;
}

.c-media-block__content {
    @include media-breakpoint-down(m) {
        max-width: 40rem;
        margin: 0 auto;
    }

    *:first-child {
        margin-top: 0;
    }
    *:last-child {
        margin-bottom: 0;
    }
}

.c-media-block__media {

    .o-image {
        width: 100%;
    }
}
.c-figure {
    display: block;
    text-align: center;
}



.c-media-block--featured {
    .c-media-block__content {
        text-align: center;

        @include media-breakpoint-up(l) {
            text-align: left;
            width: 55%;
        }
    }

    .h2:after {
        @include media-breakpoint-down(m) {
            margin: 2rem auto 0;
        }
    }

    .c-media-block__media {
        @include media-breakpoint-up(l) {
            width: 45%;
            text-align: right;
        }
    }
}

.c-media-block--reverse {
  flex-direction: row-reverse;
}
