.c-icon-list {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.c-icon-list__item {
    @include make-col-ready;
    display: flex;
    align-items: center;
    margin-bottom: $spacer--l;
    max-width: 30rem;

    @include media-breakpoint-up(m) {
        width: 50%;
    }
}

.c-icon-list__image {
    width: 4rem;
}
.c-icon-list__copy {
    @include type-scale(m);
    flex: 0 4 auto;
    padding-right: $spacer--m;
    padding-left: $spacer--m;
}