.c-contact-list {
    @include section-bg-before__wl--1(false);

    &:before {
        border-top: 10rem solid $white;
    }

    .c-action-list__items {
        display: grid;
        grid-gap: $spacer--m;
        margin-left: auto;
        margin-right: auto;

        .no-cssgrid & {
            display: flex;
            margin: 0 auto;
        }

        @include media-breakpoint-only(s) {
            max-width: 25rem;
        }

        @include media-breakpoint-between(m, l) {
            grid-template-columns: repeat(2, 1fr);
            max-width: 50rem;
        }

        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(4, 1fr);
        }
        
    }

    .c-action-list__item {
        .no-cssgrid & {
            margin-bottom: $spacer--xl;

            @include media-breakpoint-between(m, l) {
                width: 50%;
            }

            @include media-breakpoint-up(xl) {
                width: 25%;
            }
        }
    }
    
    .c-action-block {
        padding: $spacer--3xl $spacer;
    }

    .c-action-block--card {
        background: $white;
    }
    
    .c-action-block__copy {
        @include type-scale(base);
    }

    a {
        color: $action-block-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .o-icon {
        @include type-scale(xl, 1)
    }

    .c-social-links__item {
        margin-left: 0;
    }
    .c-social-links__link {
        color: rgba($primary, 0.5);

        &:hover {
            color: rgba($primary, 1);
        }
    }
}