//Shared Hero Styles

.c-hero__before {
    @include make-col-ready();
    margin-bottom: $spacer;
}

.c-hero--single {
    .c-breadcrumbs {
        margin-bottom: 1rem;
    }

    .c-hero__content {
        padding-top: 2rem;
        padding-bottom: 3rem;
        border-bottom: rem(1) solid theme-color('light');
    }
}

.c-hero__bottom-menu {
    @include make-container;

    @include media-breakpoint-up(l) {
        display: none;
    }

    .c-hero__jump-to {
        @include make-col-ready();
        text-align: center;
        border: thin solid $gray-500;
        padding: 0;

        @include media-breakpoint-only(m) {
            max-width: $content-builder-max-width;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .c-jump-to__title {
        @include type-scale(xs);
        padding: $spacer 0;
        margin: 0;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }

    .c-jump-to.is-active .c-jump-to__title {
        background: rgba($primary, 0.1);
        color: $gray-700;

        &:after {
            transform: rotate(180deg);
        }
    }

    .c-jump-to__list {
        @include make-container;
        display: none;
    }

    .c-jump-to__item {
        margin: 0 auto;

        &:last-child {
            border: none;
        }
    }
}
