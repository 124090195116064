.c-feature--v1 {
    .c-feature__content {
        @include make-row();
    }
    .c-feature__col {
        flex: 1 1 20rem;
    }
    .c-wysiwyg {
        margin-top: 0;
        margin-bottom: 0;
    }
}