.c-gallery__items {
    display: grid;

    @include media-breakpoint-only(s) {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }
    @include media-breakpoint-up(m) {
        grid-column-gap: 1.25rem;
        grid-row-gap: 1.25rem;
    }
}

.c-gallery__item {
    position: relative;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
    }
}


.c-gallery--alpha {
    @include media-breakpoint-only(s) {
        .c-gallery__items {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 2fr;
            grid-template-areas:
            'one two'
            'three three';

            .no-cssgrid & {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .c-gallery__item:nth-child(1) {
            grid-area: one;

            .no-cssgrid & {
                width: 50%;
            }
        }
        .c-gallery__item:nth-child(2) {
            grid-area: two;

            .no-cssgrid & {
                width: 50%;
            }
        }
        .c-gallery__item:nth-child(3) {
            grid-area: three;

            .no-cssgrid & {
                width: 100%;
            }
        }
    }
    @include media-breakpoint-up(m) {
        .c-gallery__items {
            grid-template-columns: 1fr 2fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
            'one three'
            'two three';
    
            .no-cssgrid & {
                max-width: 45rem;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-wrap: wrap;
            }
        }
        .c-gallery__item {
            .no-cssgrid & {
                padding: $spacer--s;
            }
        }
        .c-gallery__item:nth-child(1) {
            grid-area: one;
            .no-cssgrid & {
                width: 50%;
            }

        }
        .c-gallery__item:nth-child(2) {
            grid-area: two;
            .no-cssgrid & {
                width: 50%;
            }

        }
        .c-gallery__item:nth-child(3) {
            grid-area: three;
            .no-cssgrid & {
                width: 100%;
            }
        }
    }
}


.c-gallery--bravo {
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-only(s) {
        margin-top: $spacer--2xl;
    }
    @include media-breakpoint-only(m) {
        margin-top: $spacer--3xl;
    }
    @include media-breakpoint-only(l) {
        margin-top: $spacer--4xl;
    }
    @include media-breakpoint-up(xl) {
        margin-top: $spacer--5xl;
    }
    
    .c-gallery__items {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
        'one three'
        'two three';
    }

    .c-gallery__item {
        .no-cssgrid & {
            padding: $spacer--s;
        }
    }

    .c-gallery__item:nth-child(1) {
        grid-area: one;
    }
    .c-gallery__item:nth-child(2) {
        grid-area: two;
    }
    .c-gallery__item:nth-child(3) {
        grid-area: three;
    }

    &.c-gallery--mirror {
        .c-gallery__items {
            grid-template-areas:
            'three one'
            'three two';
        }
    }
}