.c-site-footer {
    margin-top: 0;
    background-color: $primary;
    color: rgba($white, 0.8);
    padding-top: $spacer--5xl;
    padding-bottom: $spacer--5xl;

    a {
        color: $white;

        &:hover {
            color: $white;
        }
    }

    .c-social-links {
        @include media-breakpoint-up(l) {
            margin-left: -$spacer;
        }
    }

    .c-social-links__item {
        margin: 0;
    }

    .c-social-links__link {
        color: rgba($white, 0.8);
    }

    >.l-container {
        display: grid;
        align-items: flex-start;
        grid-column-gap: $spacer--xl;
        grid-row-gap: $spacer--xl;

        @include media-breakpoint-up(l) {
            grid-template-columns: 20rem 1fr;
            grid-template-rows: repeat(2, auto);
        }
    }
}

.c-logo--light {
    background-image: url($footer-logo-path);
}

.c-site-footer__logo {
    max-width: rem(232);
    width: 100%;

    @include media-breakpoint-down(m) {
        margin-left: auto;
        margin-right: auto;
    }
}

.c-site-footer__contact {
    @include type-scale(s, 1.5);

    li {
        margin-bottom: $spacer--s;

        span {
            display: inline-block;
        }
    }

    a {
        color: rgba($white, 0.8);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}


.c-site-footer__alpha {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(l) {
        justify-content: flex-start;
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
    }
}

.c-site-footer__bravo {
    display: grid;
    grid-column-gap: 5rem;

    @include media-breakpoint-down(m) {
        justify-content: center;
    }

    @include media-breakpoint-up(l) {
        grid-template-columns: repeat(2, max-content);
        justify-content: flex-end;
        grid-column: 2 / span 1;
        grid-row: 1 / span 2;
    }
}

.c-site-footer__charlie {

    @include media-breakpoint-down(m) {
        text-align: center;
    }

    @include media-breakpoint-up(l) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 2;
    }

}

.c-site-footer__delta {
    display: flex;


    @include media-breakpoint-down(m) {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    @include media-breakpoint-up(l) {
        justify-content: flex-end;
        align-items: flex-end;
        grid-column: 2 / span 1;
        grid-row: 3 / span 1;
    }

    .c-site-footer__bbb {
        padding-bottom: 1rem;

        @include media-breakpoint-down(m) {
            padding-top: 1rem;
        }
    }

    .c-site-footer__top-work {
        padding: 0 1rem;
    }
}

.c-site-footer__echo {
    grid-column: 1 / -1;
    text-align: center;
    padding-top: $spacer--3xl;
}




.c-site-footer__menu {
    @include type-scale(base, 2);

    .c-menu__list {
        @include media-breakpoint-down(m) {
            text-align: center;
        }

        @include media-breakpoint-up(l) {
            text-align: right;
        }
    }

    .c-menu__link {
        text-decoration: none;
        font-weight: $font-weight--bold;
    }

    .c-menu__item {
        margin-bottom: $spacer--xs;
    }
}


.c-site-footer__bbb {
    display: inline-block;
}

.c-site-footer__disclaimer {
    @include type-scale('xs', 4);
}

// .c-site-menu__list--d1 {
//     @include media-breakpoint-up(m) {
//         display: flex;
//     }
// }

// .c-site-menu__item--d1 {
//     @include type-scale(base, 1.2);
//     height: auto;
//     padding-left: $spacer--xl;
//     font-family: $font-family--secondary;

//     &:hover {
//         background: transparent;
//     }
// }

// .c-menu__link {
//     color: $white;
//     text-decoration: none;

//     &:after {
//         content: '';
//         display: block;
//         width: 100%;
//         height: rem(2);
//         border-radius: rem(2);
//         position: absolute;
//         bottom: 0;
//         left: 0;

//         .c-site-menu__item--current & {
//             background-color: $secondary;
//         }
//     }

//     &:hover {
//         color: $white;
//         text-decoration: none;

//         &:after {
//             background-color: $accent;

//             .c-site-menu__item--current & {
//                 background-color: $secondary;
//             }
//         }
//     }

// }