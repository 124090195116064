.c-page--one-column.c-linkbuilding {  

  .c-section.c-section--center {
    padding-bottom: 6rem;
  }

  .c-section--center .c-section__title:after {
    margin-bottom: 2rem;
  }

  div.c-section--center {
    h2.c-section__title {
      margin: 0 auto;
      max-width: 46rem;
    }
    .c-section__description .c-wysiwyg {
      margin: 0 auto;
      max-width: -moz-available;
      max-width: -webkit-fill-available;
      max-width: stretch;
      width: max-content;

      @include media-breakpoint-up(l) {
        max-width: 54rem;
      }
    }
  }

  .c-linkbuilding__intro {
    .c-section__header{
      margin-bottom: 0;
    }
  
    &.c-section.c-section--center {
      padding-bottom: 0;
    }
  
  }
  
  .c-linkbuilding__info-1 {
    .c-section__content {
      padding: 4rem 0;
      align-items: center;
    }
  
    .c-linkbuilding__info-1_content {
      margin-right: 2rem;
    }
  
    .c-linkbuilding__info-1_image img {
      max-width: -moz-available;
      max-width: -webkit-fill-available;
      max-width: stretch;
    }
  
    @include media-breakpoint-up(l) {
      .c-section__content {
        display: flex;
        column-gap: 2rem;
      }
  
      .c-linkbuilding__info-1_content, 
      .c-linkbuilding__info-1_image {
        width: 48%;
      }
    }
  
  }
  
  .c-linkbuilding__info-2 {
    padding-bottom: 4rem;
    padding-top: 8rem;
  
    .c-section__header {
      margin-bottom: 0;
    }
  
    .c-pro-con__list {
      max-width: 46rem;
      margin: 0 auto;
    }
  }
  
  .c-linkbuilding__info-3 {
  
    &.c-section--center.c-section {
      padding-top: 8rem;
      padding-bottom: 9rem;
    }
  
    .c-action-list__item .c-action-block--card{
      background-color: $white;
  
      &.c-action-block {
        display: block;
        text-align: left;
      
      }
  
      .c-action-block__title {
        padding-top: 2rem;
        margin-bottom: 1rem;
      }
    }
  }
  
  .c-linkbuilding__info-4 {
    padding-top: 8rem;
  
    .c-linkbuilding_media-block {
      align-items: center;
      column-gap: 4rem;
      display: block;
      justify-content: center;
      margin-bottom: 4rem;
      
      &.left {
        picture {order:1;}
        div {order:2;}
      }
  
      .c-linkbuilding__info-4-image {
        display: block;
        text-align: center;
        img {
          max-width: -moz-available;          
          max-width: -webkit-fill-available; 
          max-width: stretch;
        }
      }
  
      .c-linkbuilding__info-4-content {
        h3:first-child {
          margin-top: 0;
        }
      }
  
      @include media-breakpoint-up(l) {
        column-gap: 2rem;
        display: flex;
  
        .c-linkbuilding__info-4-image,
        .c-linkbuilding__info-4-content {
          width: 48%;
        }
      }
    }
  
    .c-section__title.c-linkbuilding__info-4{
      padding-top: 2rem;
    }
  
  }
  
  .c-linkbuilding__focus {
  
    margin-bottom: 4rem;
  
    &.c-section {
      padding-top: 4rem;
    }
    .c-section__title {
      max-width: 40rem;
      margin: 0 auto 2rem;
    }
    .c-section__header  {
      margin-bottom: 2rem;
    }
    .c-action-list__items {
        grid-gap: $grid-gutter-width;
  
        @include media-breakpoint-down(m) {
          max-width: 30rem;
          margin-left: auto;
          margin-right: auto;
        }
  
        @include media-breakpoint-up(l) {
          grid-template-columns: repeat(3, 1fr);
        }
    }
    .c-action-block {
      flex-direction: column;
  
      @include media-breakpoint-up(m) {
        flex-direction: row;
      }
    }
    .c-action-block__image {
      margin-right: 0;
      margin-bottom: 2rem;
  
      @include media-breakpoint-up(m) {
        margin-right: $grid-gutter-width;
        margin-bottom: 0;
      }
    }
    .c-action-block__copy {
      text-align: center;
  
      @include media-breakpoint-up(m) {
        text-align: left;
      }
    } 
  }
  
  .c-linkbuilding__work,
  .c-linkbuilding__info-1,
  .c-linkbuilding__info-3 {
    @include section-bg-before__wl--3;
    @include section-bg-after__lw--2;
  }
  
  .c-linkbuilding__cs-nav {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
    margin-bottom: 2rem;
  
    @include media-breakpoint-up(l) {
      grid-template-columns: repeat(3, 1fr);
  
      .c-linkbuilding__cs-cards:nth-child(even) {
        -webkit-transform: translateY(2rem);
        transform: translateY(2rem);
      }
    }
  
    li {
      text-align: center;
    }
    a {
      display: block;
      text-decoration: none;
  
      &:hover {
        strong {
          text-decoration: underline;
        }
      }
    }
    img {
      display: block;
      margin: 0 auto 1rem;
      padding: 2rem 0;
  
      @include media-breakpoint-up(l) {
        padding: 8rem 0;
      }
      
    }
    strong {
      display: block;
    }
  
    .c-linkbuilding__cs-cards {
      @include make-card;
  
      &:nth-child(1) {
        background-color: #005C45;
      }
      &:nth-child(2) {
        background-color: $white;
      }
      &:nth-child(3){
        background-color: #304EBB;
      }
    }
  }
  
  
  
  
  .c-linkbuilding__case-study {
    @include media-breakpoint-down(m) {
      margin-bottom: 6rem;
    }
    @include media-breakpoint-up(l) {
      margin-bottom: 9rem;
    }
  
    &:last-child {
      margin-bottom:0
    }
  
    .c-media-block__image {
      width: 100%;
      max-width: 25rem;
    }
  
    .c-media-block__content {
      
        span.c-case-study__title-duration {
          display: block;
          font-weight: 800;
        }
      
    }
  
    &.c-case-study__full-width-title {
      .c-media-block .c-media-block__media {
        display: none;
      }
      .c-media-block__content {
        width: 100%;
      }
    }
  
    .c-stat-text__black {
      color: $black;
    }
  
    &#asbestoscom .c-linkbuilding__stat {
      text-align: left;
    }
  }
  
  
  
  .c-linkbuilding__case-boxes {
    display: grid;
    grid-gap: $grid-gutter-width;
    margin-top: 3rem;
  
    @include media-breakpoint-down(m) {
      max-width: 40rem;
      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-up(l) {
      align-items: stretch;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .c-linkbuilding__case-stats {
    background-color: $light;
    display: grid;
    grid-template-columns: repeat(auto-fit, 14rem);
    justify-content: space-evenly;
    grid-gap: $grid-gutter-width;
    // align-items: center;
    border-radius: rem(6);
    padding: $grid-gutter-width;
    align-items: flex-start;
    align-content: center;
  }
  .c-linkbuilding__stat {
    font-weight: $font-weight--bold;
    text-align: center;
  
    .number {
      @include type-scale(3xl, 1.25);
      font-family: $font-family--secondary;
      color: $primary-dark;
      display: block;
      line-height: 1;
    }
    .text {
      @include type-scale(m, 1.5);
      color: $accent;
    }
  }
  .c-linkbuilding__case-image {
    img {
      border-radius: rem(6);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .c-linkbuilding__why {
    img {
      border-radius: rem(6);
    }
  }
  
  .c-icon-list {
    display: grid;
    
    @include media-breakpoint-up(m) {
      display: flex;
    }

    @include media-breakpoint-up(l) {
      li.c-icon-list__item {
        width: 35%;
      }
    }
  }
  
  .c-pro-con__list.c-pro-con__list--cons .c-ul {
    display: grid;
    grid-template-columns: repeat(2,auto);
  }

}

.c-services__form {
  @include section-bg-before__wl--3(false);
  @include section-bg__pre-footer;

  .c-section__content {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
    align-items: center;
    justify-content: center;
    grid-template-columns: minmax(1fr, 30rem);

    @include media-breakpoint-down(m) {
      text-align: center;
      h2 {
        &:after {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    @include media-breakpoint-up(l) {
      justify-content: space-between;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  h3.c-services__form-title {
    margin-top: 0;
  }

  .c-services__form-copy {
    max-width: 30rem;
  }
}