.c-section-br {
    position: relative;
    padding-top: $section-padding-top--s;
    padding-bottom: $section-padding-bottom--s;
    padding-right: $spacer;
    padding-left: $spacer;
    margin-right: auto;
    margin-left: auto;
    overflow-x: hidden;

    @include media-breakpoint-up(m) {
        padding-top: $section-padding-top--m;
        padding-bottom: $section-padding-bottom--m;
    }

    @include media-breakpoint-up(l) {
        padding-top: $section-padding-top--l;
        padding-bottom: $section-padding-bottom--l;
    }

    .c-figure {

        @include media-breakpoint-up(l) {
            
            &--right {
                max-width: 50%;
                padding-left: $spacer--xl;
            }

            &--left {
                max-width: 50%;
                padding-right: $spacer--xl;
            }
        }
    }

    @include media-breakpoint-up(l) {

        .c-figure--absolute-left {
            position: absolute;
            right: calc(50vw + #{$content-builder-max-width/2} );
            max-width: calc(50vw + #{$content-builder-max-width/2} );
            padding-right: $spacer;
            padding-left: $spacer;
            z-index: -1;

            .c-figure__image {
                max-width: none;
            }
        }

        .c-figure--absolute-right {
            position: absolute;
            left: calc(50vw + #{$content-builder-max-width/2} );
            max-width: calc(50vw - #{$content-builder-max-width/2} );
            margin-right: -20rem;
            padding-right: $spacer;
            padding-left: $spacer;
            z-index: -1;

            .c-figure__image {
                max-width: none;
            }
        }

    }
}

.c-section-br__inner {
    max-width: $site-max-width;
    margin: 0 auto;

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }

    > * {
        margin-left: auto;
        margin-right: auto;
        max-width: $content-builder-max-width;
    }

    .c-cta {
        margin-left: auto;
        margin-right: auto;
    }
}

.c-section-br--h2-center .c-section-br__inner {
    > .h2 {
        text-align: center;
    }
}

.c-section-br--text-align-center .c-section-br__inner {
    
    > .h2,
    > .h3,
    > .h4,
    > .h5,
    > .h6,
    > .c-wysiwyg {
        text-align: center;
    }

    .strong {
        display: block;
        text-align: center;
    }

    > .c-button__wrap {
        text-align: center;
    }

}

.c-section-br--overlap-center-column {
    
    @include media-breakpoint-up(l) {
        .c-blockquote,
        .c-factbox,
        .c-action-list, 
        .c-media-block,
        .c-related-posts,
        .c-related-profiles {
            max-width: $content-builder-max-width + ($spacer--5xl * 2);
            margin-top: $spacer--4xl;
            margin-bottom: $spacer--4xl;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        img.alignright,
        .wp-caption.alignright {
            margin-right: -$spacer--5xl;
        }

        img.alignleft,
        .wp-caption.alignleft {
            margin-left: -$spacer--5xl;
        }

    }

    .c-related-posts__list {
        justify-content: center;
    }
    
}

.c-section-br--overlap-center-column-lg {
    
    @include media-breakpoint-up(l) {
        .c-blockquote,
        .c-factbox,
        .c-action-list,
        .c-media-block,
        .c-related-posts,
        .c-related-profiles {
            max-width: none;
            margin-top: $spacer--4xl;
            margin-bottom: $spacer--4xl;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        img.alignright,
        .wp-caption.alignright {
            margin-right: -$spacer--5xl;
        }

        img.alignleft,
        .wp-caption.alignleft {
            margin-left: -$spacer--5xl;
        }

    }
    
    .c-related-posts__list {
        justify-content: center;
    }
}

.c-section-br--light {
    background-color: $gray-100;
}
