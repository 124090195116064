.c-site-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    z-index: 100;
}

.c-site-header__left {
    flex: 1 1 auto;

    @include media-breakpoint-only(s) {
        max-width: rem(148);
    }
    @include media-breakpoint-only(m) {
        max-width: rem(190);
    }
    @include media-breakpoint-up(l) {
        max-width: 14.5rem;
    }
}


.c-site-header__mobile {
    @include media-breakpoint-up(l) {
        display: none;
    }
}


.c-site-header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-only(s) {
        height: 5rem;
    }
    @include media-breakpoint-only(m) {
        height: 7rem;
    }
    @include media-breakpoint-up(l) {
        height: 12rem;
    }
}

.c-site-header__right {
    @include media-breakpoint-down(m) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        transition: height 0s ease-in-out 1s;

        &.is-active {
            height: 100%;
        }
    }
}


.c-site-header__button {
    @include type-scale(2xl, 1);
    color: $white;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-only(s) {
        margin-right: -($grid-gutter-width / 2);
    }

    @include media-breakpoint-only(m) {
        width: auto;
    }

    @include media-breakpoint-up(l) {
        display: none;
    }

    &:hover {
        color: $white;
    }

    span {
        @include type-scale(m, 1);
        text-transform: uppercase;
        padding-right: $spacer--xs;
        display: none;

        @include media-breakpoint-only(m) {
            display: inline-block;
        }
    }
}


.c-mobile-nav {
    @include media-breakpoint-down(m) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
    }
}

.c-mobile-nav__mask {
    @include media-breakpoint-down(m) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: rgba($white, 0.8);
        transition: opacity 0.5s ease-in-out, height 0s 0.5s ease-in-out;
        opacity: 0;
        z-index: 0;
        cursor: pointer;

        .is-active & {
            transition: opacity 0.5s ease-in-out, height 0s ease-in-out;
            opacity: 1;
            height: 100%;
        }
    }
}

.c-mobile-nav__drawer {
    @include media-breakpoint-down(m) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        padding-bottom: rem(231);
        background: linear-gradient(15deg, $accent 0%, $primary 100%);
        mask: url('#{$img-path}/section-bg/mobile-nav-mask.png');
        mask-repeat: no-repeat;
        mask-position: bottom;
        mask-size: 100% auto; 
        z-index: 1;
        transform: translateY(-100%);
        transition: transform 0.5s ease-in-out;

        .is-active & {
            transform: translateY(0);
        }
    }
}


.c-mobile-nav__button {
    @include media-breakpoint-down(m) {
        @include type-scale(2xl, 1);
        color: $white;
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        position: absolute;
        top: 0;
        right: 0;
    }
    @include media-breakpoint-up(l) {
        display: none;
    }
}