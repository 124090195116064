.c-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-top: 0.125rem solid gray('100');
    border-bottom: 0.125rem solid gray('100');
    max-width: 48rem;
    margin: 2rem auto;
    position: relative;
}

.c-cta__icon {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.c-cta__image {
    margin-bottom: 1rem;
}

.c-cta__title {
    @include type-scale(l);
    font-weight: normal;
    font-family: $font-family--secondary;
    max-width: 40rem;
    margin: 0 auto;
}
.c-cta__description {
    max-width: 40rem;
    margin: 1rem auto;
    text-align: center;
}

.c-cta__button {
    margin-top: $spacer;
}

.c-cta--video {
    margin: 0;
    border: 0;

    @include media-breakpoint-only(s) {
        padding: $spacer--3xl $spacer $spacer;
    }

    @include media-breakpoint-up(m) {
        padding: $spacer--l $spacer--3xl $spacer--2xl;
    }

    .c-cta__title {
        @include media-breakpoint-only(s) {
            @include type-scale(m, 1.25);
        }

        @include media-breakpoint-up(m) {
            @include type-scale(xl, 1.25);
        }
    }
    .c-cta__button {
        @include media-breakpoint-only(s) {
            margin-top: $spacer--s;
        }
    }
    .c-button {
        @include media-breakpoint-only(s) {
            @include make-button-xs;
        }
        @include make-button-variant(theme-color('accent'));
    }
}

.c-cta--video {

}

.c-cta--button {
    margin: 0;
    padding: 0;
    border: 0;
    display: block;

    > * {
        display: none;
    }

    > .c-cta__button {
        display: block;
    }
}
