.c-pro-con {
    display: flex;
    margin-left: -1rem;
    margin-right: -1rem;

    @include media-breakpoint-up(m) {
        @include make-row();
        flex-wrap: nowrap;
    }

    .c-ul {
        padding-left: 0;
        margin-top: 0;
        list-style-type: none;
    }

    .c-ul__item {
        @include type-scale(base, 1.5);
        padding-left: $spacer--xl;
        position: relative;

        &:first-child {
            margin-top: 0;
        }

        &:before {
            @include type-scale(m, 1);
            @include make-icon;
            position: absolute;
            font-size: 1rem;
            line-height: 1.5;
            top: 0;
            left: 0;
        }
    }
}

.c-pro-con__list {
    width: 100%;
    padding: $spacer--l;

    >:first-child {
        margin-top: 0;
    }
    >*:last-child {
        margin-bottom: 0;
    }
}

.c-pro-con__list--cons {
    .c-ul__item {
        &:before {
            content: icon('x');
            color: $error;
        }
    }
}

.c-pro-con__list--pros {
    .c-ul__item {
        &:before {
            content: icon('check');
            color: $success;
        }
    }
}

.c-pro-con__title {
    @include make-h4();
    margin-bottom: 1.5rem;
    display: block;

    .c-pro-con__list--pros & {
        color: $success;
    }
    .c-pro-con__list--cons & {
        color: $error;
    }
}