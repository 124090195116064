h1,
.h1 {
    @include make-h1;
}

h2,
.h2 {
    @include make-h2;
}

h3,
.h3 {
    @include make-h3;
}

h4,
.h4 {
    @include make-h4;
}

h5,
.h5 {
    @include make-h5;
}

h6,
.h6 {
    @include make-h6;
}
