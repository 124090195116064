.c-section-br--swirl {
    @include section-bg-after__lw--1;
    @include section-bg-before__wl--1(false);

    background-color: #e9f7fb;
    overflow: visible;

    .c-section-br__inner {
        margin: 4rem auto 2rem;
    }
}

.c-section-br--no-padding {
    padding-top: 0;
}