.c-logo {
    //child theme would set width, height, and background image
    display: block;
    text-indent: -10000rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 0;
    padding-top: $logo-padding-top;
    background-image: url($logo-path);
}
