.c-careers__intro {
    @include section-bg-after__wl--1(false);

    &:after {
        border-bottom: 10rem solid $light;
    }
}

.c-careers__business {
    @include section-bg-after__tp--1;
}

.c-careers__perks {
    @include section-bg-before__pt--1;
}

.c-careers__celebrate {
    @include section-bg-before__wl--3;
    @include section-bg-after__lw--1(false);

    &:after {
        border-bottom: 10rem solid $white;
    }

    .c-figure {
        @include media-breakpoint-up(m) {
            margin-top: 5rem;
        }
    }
}


// .c-careers__community {
//     @include section-bg-after__wl--1(false);

//     &:after {
//         border-bottom: 10rem solid $light;
//     }

// }