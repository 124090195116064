@charset "UTF-8";
*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.75;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 400;
}

@-ms-viewport {
  width: device-width;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  color: #4d4d4d;
  text-align: left;
  background-color: #ffffff;
  min-width: 20rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.75;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  padding-left: 0;
  list-style: none;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 0.75em;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #252e62;
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #171d3d;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: inherit;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: #333333;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.25rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 0.125rem dotted;
  outline: 0.25rem auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -0.125rem;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

@media (max-width: 54.9375em) {
  html.admin {
    margin-top: 0 !important;
  }
}

@media (max-width: 54.9375em) {
  #wpadminbar {
    display: none !important;
  }
}

.c-button {
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 2rem;
  border-width: 0.125rem;
  border-style: solid;
  border-color: #e9f7fb;
  padding: 1.25rem 2rem;
  color: #333333;
  background-color: #e9f7fb;
  text-transform: none;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  font-family: Muli, sans-serif;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
.u-hover-children:hover .c-button, .c-button:hover {
  text-decoration: none;
  border-color: #a3adb0;
  background-color: #a3adb0;
  color: #333333;
}
.c-button:focus {
  outline: 0;
}
.c-button .o-icon {
  line-height: inherit;
  font-size: inherit;
  display: inline-block;
}

.c-button--primary {
  background-color: #252e62;
  border-color: #252e62;
  color: #ffffff;
}
.u-hover-children:hover .c-button--primary, .c-button--primary:hover {
  background-color: #1a2045;
  border-color: #1a2045;
  color: #ffffff;
}

.c-button--secondary {
  background-color: #add454;
  border-color: #add454;
  color: #333333;
}
.u-hover-children:hover .c-button--secondary, .c-button--secondary:hover {
  background-color: #79943b;
  border-color: #79943b;
  color: #ffffff;
}

.c-button--accent {
  background-color: #35caf9;
  border-color: #35caf9;
  color: #333333;
}
.u-hover-children:hover .c-button--accent, .c-button--accent:hover {
  background-color: #258dae;
  border-color: #258dae;
  color: #ffffff;
}

.c-button--success {
  background-color: #00ff00;
  border-color: #00ff00;
  color: #ffffff;
}
.u-hover-children:hover .c-button--success, .c-button--success:hover {
  background-color: #00b300;
  border-color: #00b300;
  color: #ffffff;
}

.c-button--error {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #ffffff;
}
.u-hover-children:hover .c-button--error, .c-button--error:hover {
  background-color: #b30000;
  border-color: #b30000;
  color: #ffffff;
}

.c-button--light {
  background-color: #e9f7fb;
  border-color: #e9f7fb;
  color: #333333;
}
.u-hover-children:hover .c-button--light, .c-button--light:hover {
  background-color: #a3adb0;
  border-color: #a3adb0;
  color: #333333;
}

.c-button--dark {
  background-color: #333333;
  border-color: #333333;
  color: #ffffff;
}
.u-hover-children:hover .c-button--dark, .c-button--dark:hover {
  background-color: #242424;
  border-color: #242424;
  color: #ffffff;
}

.c-button--twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #ffffff;
}
.u-hover-children:hover .c-button--twitter, .c-button--twitter:hover {
  background-color: #1471a9;
  border-color: #1471a9;
  color: #ffffff;
}

.c-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #ffffff;
}
.u-hover-children:hover .c-button--facebook, .c-button--facebook:hover {
  background-color: #293e6a;
  border-color: #293e6a;
  color: #ffffff;
}

.c-button--google-plus {
  background-color: #ea4335;
  border-color: #ea4335;
  color: #ffffff;
}
.u-hover-children:hover .c-button--google-plus, .c-button--google-plus:hover {
  background-color: #a42f25;
  border-color: #a42f25;
  color: #ffffff;
}

.c-button--linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
  color: #ffffff;
}
.u-hover-children:hover .c-button--linkedin, .c-button--linkedin:hover {
  background-color: #00537f;
  border-color: #00537f;
  color: #ffffff;
}

.c-button--pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
  color: #ffffff;
}
.u-hover-children:hover .c-button--pinterest, .c-button--pinterest:hover {
  background-color: #840614;
  border-color: #840614;
  color: #ffffff;
}

.c-button--instagram {
  background-color: #f56040;
  border-color: #f56040;
  color: #ffffff;
}
.u-hover-children:hover .c-button--instagram, .c-button--instagram:hover {
  background-color: #ac432d;
  border-color: #ac432d;
  color: #ffffff;
}

.c-button--outline {
  background-color: transparent;
  border-color: #252e62;
  color: #252e62;
}
.u-hover-children:hover .c-button--outline, .c-button--outline:hover {
  background-color: #252e62;
  border-color: #252e62;
  color: #ffffff;
}

.c-button--gradient {
  background-image: linear-gradient(90deg, #084c7f, #34c6f4, #084c7f);
  background-size: 200% 200%;
  background-position: left;
  border-color: transparent;
  color: #ffffff;
}
.u-hover-children:hover .c-button--gradient, .c-button--gradient:hover {
  border-color: transparent;
  background-position: right;
  color: #ffffff;
}

.c-button--xs {
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.75rem;
  border-width: 0.0625rem;
  padding: 0.1875rem 0.5rem;
  letter-spacing: 0.03125rem;
  font-weight: normal;
}

.c-button--s {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  border-radius: 1.5rem;
  border-width: 0.125rem;
  padding: 0.375rem 1rem;
  letter-spacing: 0.0625rem;
  font-weight: normal;
}

.c-button--l {
  font-size: 1.0625rem;
  line-height: 0.9411764706;
  border-radius: 2rem;
  border-width: 0.25rem;
  padding: 1rem 2.5rem;
  letter-spacing: 0.125rem;
  font-weight: 700;
}
@media (min-width: 55em) {
  .c-button--l {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

.c-button--link {
  background: none;
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;
  color: #252e62;
  font-weight: bold;
}
@supports (text-decoration-color: transparent) {
  .c-button--link {
    text-decoration: underline;
    text-decoration-color: transparent;
  }
}
.u-hover-children:hover .c-button--link, .c-button--link:hover {
  color: #1a2045;
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
}

.c-button--line {
  background: none;
  border-color: transparent;
  color: #ffffff;
  font-weight: bold;
  border-radius: 0;
  padding: 0.25rem 0.5rem;
  border-bottom: 0.125rem solid #ffffff;
  border-left: 0;
  border-right: 0;
}
.u-hover-children:hover .c-button--line, .c-button--line:hover {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #ffffff;
}

.c-button--icon {
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  text-align: center;
  padding: 0;
  border: 0;
  font-size: inherit;
  line-height: 1;
}
.c-button--icon.c-button--xs {
  width: 1.5rem;
  height: 1.5rem;
}
.c-button--icon.c-button--s {
  width: 2rem;
  height: 2rem;
}

.c-button--ghost {
  color: inherit;
  background-color: transparent;
  border-color: transparent;
}
.u-hover-children:hover .c-button--ghost, .c-button--ghost:hover {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}

.c-form__label {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  margin-bottom: 0.25rem;
  font-weight: 700;
}

.c-form__label--error {
  color: #ff0000;
  display: none;
}
.c-form__group.has-error .c-form__label--error {
  display: block;
}

.c-form__control,
.c-form__textarea {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  width: 100%;
  padding: 0.4375rem;
  color: #1a1a1a;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  height: 3rem;
  border: 0.0625rem solid #cccccc;
}
.c-form__control::-ms-expand,
.c-form__textarea::-ms-expand {
  background-color: transparent;
  border: 0;
}
.c-form__group.has-error .c-form__control,
.c-form__group.has-error .c-form__textarea {
  border-color: rgba(255, 0, 0, 0.5);
}

.c-form__textarea {
  height: 6rem;
}

.c-form__checkbox-input,
.c-form__radio-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.c-form__label--checkbox,
.c-form__label--radio {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 400;
}

.c-form__label--checkbox:before,
.c-form__label--radio:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  width: 1.25rem;
  text-align: left;
}

.c-form__label--checkbox:before {
  content: "\f096";
}

.c-form__label--radio:before {
  content: "\e905";
}

.c-form__checkbox-input:checked + .c-form__label--checkbox:before {
  content: "\f046";
}

.c-form__radio-input:checked + .c-form__label--radio:before {
  content: "\e904";
}

h1,
.h1 {
  font-size: 2.0625rem;
  line-height: 1.2121212121;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #161c3b;
}
@media (min-width: 55em) {
  h1,
  .h1 {
    font-size: 3.625rem;
    line-height: 1.1724137931;
  }
}

h2,
.h2 {
  font-size: 1.5625rem;
  line-height: 1.28;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #161c3b;
  margin-top: 0;
  margin-bottom: 2rem;
}
@media (min-width: 55em) {
  h2,
  .h2 {
    font-size: 2.25rem;
    line-height: 1.2222222222;
  }
}

h3,
.h3 {
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  font-family: Muli, sans-serif;
  font-weight: 700;
  color: #161c3b;
  margin-top: 4rem;
  margin-bottom: 1rem;
}
@media (min-width: 55em) {
  h3,
  .h3 {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

h4,
.h4 {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #258dae;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

h5,
.h5 {
  font-size: 1.0625rem;
  line-height: 1.1764705882;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #161c3b;
}
@media (min-width: 55em) {
  h5,
  .h5 {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #161c3b;
}

.c-link-list {
  padding-left: 1rem;
  list-style-type: none;
}

.c-ol {
  margin-bottom: 0;
  padding-left: 1.5rem;
}

.c-ol__item {
  margin-bottom: 0.75rem;
}
.c-ol__item:last-child {
  margin-bottom: 0;
}

.c-ul {
  margin-bottom: 0;
  padding-left: 2rem;
  list-style: initial;
}

.c-ul__item {
  margin-bottom: 1rem;
}
.c-ul__item:last-child {
  margin-bottom: 0;
}

.c-ul--split {
  column-count: 2;
  column-gap: 2.5rem;
}

.c-logo {
  display: block;
  text-indent: -10000rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 0;
  padding-top: 24.4635193133%;
  background-image: url("../assets/img/logo.svg");
}

.c-button {
  text-align: center;
}

.c-button--secondary {
  color: #252e62;
}

.c-button--icon {
  line-height: 3rem;
}

.c-button--video {
  color: #252e62;
  background-color: transparent;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  position: relative;
}
.u-hover-children:hover .c-button--video, .c-button--video:hover {
  background-color: transparent;
}
.u-hover-children:hover .c-button--video .o-icon, .c-button--video:hover .o-icon {
  color: #ffffff;
  background-color: #add454;
}
.u-hover-children:hover .c-button--video:before, .c-button--video:hover:before {
  width: 137.5%;
  height: 137.5%;
}
.u-hover-children:hover .c-button--video:after, .c-button--video:hover:after {
  width: 175%;
  height: 175%;
}
.c-button--video:before, .c-button--video:after {
  width: 150%;
  height: 150%;
  content: "";
  display: block;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.12);
  position: absolute;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;
  z-index: 0;
}
.c-button--video:after {
  width: 200%;
  height: 200%;
}
.c-button--video .o-icon {
  color: #252e62;
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #ffffff;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.c-cta__button {
  text-align: center;
}

h2:after,
.h2:after {
  content: "";
  display: block;
  width: 3.5625rem;
  height: 0.5rem;
  background-image: url("../assets/img/underline.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 2rem;
}

.c-section-br--text-align-center .h2:after {
  margin-left: auto;
  margin-right: auto;
}

p {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
}
@media (min-width: 55em) {
  p {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-blockquote {
  max-width: 40rem;
  width: 100%;
  margin: 2rem auto;
  text-align: center;
}

.c-blockquote__image {
  display: block;
  margin: 0 auto;
  border-radius: 100%;
}

.c-blockquote__quote {
  font-size: 1.125rem;
  line-height: 1.7777777778;
  margin: 1rem auto;
  font-family: "Muli", sans-serif;
}
@media (min-width: 55em) {
  .c-blockquote__quote {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}

.c-blockquote__title {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  display: block;
}
@media (min-width: 55em) {
  .c-blockquote__title {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-blockquote__subtitle {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  color: #666666;
}

.c-blockquote__sources {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  margin-top: 0.5rem;
}

.c-blockquote--left {
  text-align: left;
}

.c-blockquote--right {
  text-align: right;
}

.c-blockquote--small .c-blockquote__quote {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
}
@media (min-width: 55em) {
  .c-blockquote--small .c-blockquote__quote {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
.c-blockquote--small .c-blockquote__title {
  font-size: 0.875rem;
  line-height: 1.7142857143;
}
.c-blockquote--small .c-blockquote__subtitle {
  font-size: 0.75rem;
  line-height: 1.6666666667;
}

.c-action-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  padding: 2rem;
  text-align: center;
  text-decoration: none;
  border-radius: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #4d4d4d;
  margin-left: auto;
  margin-right: auto;
}
.c-action-block:hover {
  text-decoration: none;
  color: #4d4d4d;
}

.c-action-block__image {
  margin-bottom: 1rem;
}

.c-action-block__title {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.25;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #258dae;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.c-action-block__copy {
  color: inherit;
}

.c-action-block__button {
  margin-top: 1rem;
}

.c-action-block--card {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  max-width: none;
}

a.c-action-block--card {
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
}
a.c-action-block--card:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.375rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2);
}

.c-action-block--white {
  background-color: #ffffff;
}

.c-action-block--light {
  background-color: #e9f7fb;
}

.c-action-block--card-image-left {
  text-align: left;
}
.c-action-block--card-image-left .c-action-block__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.c-action-block--card-image-left .c-action-block__image {
  flex: 0 0 4rem;
  margin: 0;
}
.c-action-block--card-image-left .c-action-block__title {
  flex: 1 1 auto;
  padding-left: 1rem;
  margin: 0;
}
.c-action-block--card-image-left .c-action-block__copy {
  flex: 0 1 100%;
  margin-top: 1rem;
}

.c-post-block {
  max-width: 30rem;
  margin-bottom: 2rem;
  position: relative;
}
.c-post-block a {
  position: relative;
  z-index: 1;
  text-decoration: none;
}

.c-post-block__image {
  width: 100%;
  display: block;
}

.c-post-block__content {
  padding: 1.5rem 0;
}

.c-post-block__category {
  font-size: 0.75rem;
  line-height: 1.3333333333;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  color: #666666;
  margin-bottom: 0.25rem;
  display: inline-block;
}
.c-post-block__category:hover {
  text-decoration: underline;
}

a.c-post-block__link {
  position: inherit;
  z-index: 0;
}
a.c-post-block__link:before {
  content: "";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}

.c-post-block__title {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #258dae;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease-in-out;
}
@supports (text-decoration-color: transparent) {
  .c-post-block__title {
    text-decoration: underline;
    text-decoration-color: transparent;
  }
}
.c-post-block__link:hover .c-post-block__title {
  color: #252e62;
  text-decoration: underline;
  text-decoration-color: #252e62;
}

.c-post-block__meta {
  margin-top: 1rem;
}

.c-post-block__button {
  margin-top: 1rem;
}

.c-post-block--card .c-post-block__inner {
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
  border-radius: 1rem;
}
.c-post-block--card .c-post-block__inner:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.375rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2);
}
.c-post-block--card .c-post-block__image {
  border-radius: 1rem 1rem 0 0;
}
.c-post-block--card .c-post-block__content {
  padding: 1.5rem;
}
.c-post-block--card.c-post-block--horizontal .c-post-block__image {
  border-radius: 1rem 0 0 1rem;
}
.c-post-block--card.c-post-block--horizontal .c-post-block__content {
  padding: 1.5rem;
}

.c-post-block--horizontal {
  max-width: 60rem;
}
.c-post-block--horizontal .c-post-block__inner {
  display: flex;
  align-items: stretch;
}
.c-post-block--horizontal .c-post-block__image-wrapper {
  width: 33.3333333333%;
  flex-grow: 0;
  flex-shrink: 0;
}
.c-post-block--horizontal .c-post-block__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.c-post-block--horizontal .c-post-block__content {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.c-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-top: 0.125rem solid #e6e6e6;
  border-bottom: 0.125rem solid #e6e6e6;
  max-width: 48rem;
  margin: 2rem auto;
  position: relative;
}

.c-cta__icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.c-cta__image {
  margin-bottom: 1rem;
}

.c-cta__title {
  font-size: 1.125rem;
  line-height: 1.7777777778;
  font-weight: normal;
  font-family: "Muli", sans-serif;
  max-width: 40rem;
  margin: 0 auto;
}
@media (min-width: 55em) {
  .c-cta__title {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}

.c-cta__description {
  max-width: 40rem;
  margin: 1rem auto;
  text-align: center;
}

.c-cta__button {
  margin-top: 1rem;
}

.c-cta--video {
  margin: 0;
  border: 0;
}
@media (max-width: 34.9375em) {
  .c-cta--video {
    padding: 3rem 1rem 1rem;
  }
}
@media (min-width: 35em) {
  .c-cta--video {
    padding: 1.5rem 3rem 2.5rem;
  }
}
@media (max-width: 34.9375em) {
  .c-cta--video .c-cta__title {
    font-size: 1.0625rem;
    line-height: 1.1764705882;
  }
}
@media (max-width: 34.9375em) and (min-width: 55em) {
  .c-cta--video .c-cta__title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 35em) {
  .c-cta--video .c-cta__title {
    font-size: 1.3125rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 35em) and (min-width: 55em) {
  .c-cta--video .c-cta__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
@media (max-width: 34.9375em) {
  .c-cta--video .c-cta__button {
    margin-top: 0.75rem;
  }
}
.c-cta--video .c-button {
  background-color: #35caf9;
  border-color: #35caf9;
  color: #333333;
}
@media (max-width: 34.9375em) {
  .c-cta--video .c-button {
    font-size: 0.75rem;
    line-height: 1;
    border-radius: 0.75rem;
    border-width: 0.0625rem;
    padding: 0.1875rem 0.5rem;
    letter-spacing: 0.03125rem;
    font-weight: normal;
  }
}
.u-hover-children:hover .c-cta--video .c-button, .c-cta--video .c-button:hover {
  background-color: #258dae;
  border-color: #258dae;
  color: #ffffff;
}

.c-cta--button {
  margin: 0;
  padding: 0;
  border: 0;
  display: block;
}
.c-cta--button > * {
  display: none;
}
.c-cta--button > .c-cta__button {
  display: block;
}

.c-figure {
  display: table;
  max-width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.c-figure__image-link {
  display: block;
}

.c-figure__image {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.c-figure__figcaption {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: table-caption;
  caption-side: bottom;
  margin-top: 1rem;
}

.c-figure__caption {
  font-style: italic;
}

.c-figure__sources {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  margin-top: 0.75rem;
}

@media (min-width: 35em) {
  .c-figure--right {
    float: right;
    max-width: 50%;
    margin: 0 0 2rem 2rem;
  }
}

@media (min-width: 35em) {
  .c-figure--left {
    float: left;
    max-width: 50%;
    margin-top: 0;
    margin: 0 2rem 2rem 0;
  }
}

.c-form {
  position: relative;
}

.c-form__group {
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
}

.c-form__group--archer-s1e5 {
  display: none;
}

.c-form__group.is-hidden {
  display: none;
}

.c-form__step {
  display: none;
}

.c-form__step:first-child {
  display: block;
}

.c-form__submit-error,
.c-form__network-error {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  color: #ff0000;
  background-color: #ffffff;
  position: absolute;
  z-index: 200;
}

.c-form__network-error {
  margin: 1rem;
}

.c-form__lightbox {
  content: "";
  position: absolute;
  z-index: 199;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.c-form__submit-animation {
  display: none;
}

.c-form--submitting .c-form__submit-animation {
  display: block;
  position: absolute;
  width: 5rem;
  height: 5rem;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-form--submitting .c-form__submit-animation:after {
  content: " ";
  display: block;
  width: 4rem;
  height: 4rem;
  margin: 0.5rem;
  border-radius: 50%;
  border: 0.375rem solid #add454;
  border-color: #add454 transparent #add454 transparent;
  animation: submit-animation 1.2s linear infinite;
}
@keyframes submit-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.c-form--submitting .c-form__lightbox {
  display: block;
}

.c-form--disabled .c-form__submit-error {
  visibility: hidden;
}
.c-form--disabled .c-form__lightbox {
  display: block;
}

.c-list {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.c-list__title {
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  font-family: Muli, sans-serif;
  font-weight: 700;
  color: #161c3b;
  margin-top: 4rem;
  margin-bottom: 1rem;
  display: block;
  margin-bottom: 1rem;
}
@media (min-width: 55em) {
  .c-list__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

.c-pro-con {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
}
@media (min-width: 35em) {
  .c-pro-con {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
    flex-wrap: nowrap;
  }
}
.c-pro-con .c-ul {
  padding-left: 0;
  margin-top: 0;
  list-style-type: none;
}
.c-pro-con .c-ul__item {
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 2rem;
  position: relative;
}
.c-pro-con .c-ul__item:first-child {
  margin-top: 0;
}
.c-pro-con .c-ul__item:before {
  font-size: 1.0625rem;
  line-height: 0.9411764706;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  font-size: 1rem;
  line-height: 1.5;
  top: 0;
  left: 0;
}
@media (min-width: 55em) {
  .c-pro-con .c-ul__item:before {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

.c-pro-con__list {
  width: 100%;
  padding: 1.5rem;
}
.c-pro-con__list > :first-child {
  margin-top: 0;
}
.c-pro-con__list > *:last-child {
  margin-bottom: 0;
}

.c-pro-con__list--cons .c-ul__item:before {
  content: "\f00d";
  color: #ff0000;
}

.c-pro-con__list--pros .c-ul__item:before {
  content: "\f00c";
  color: #00ff00;
}

.c-pro-con__title {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #258dae;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  margin-bottom: 1.5rem;
  display: block;
}
.c-pro-con__list--pros .c-pro-con__title {
  color: #00ff00;
}
.c-pro-con__list--cons .c-pro-con__title {
  color: #ff0000;
}

.c-pagination {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 55em) {
  .c-pagination {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-pagination__prev,
.c-pagination__next {
  padding: 0.5rem;
  color: #252e62;
  margin: 0 0.5rem;
}
.c-pagination__prev:hover,
.c-pagination__next:hover {
  color: #171d3d;
}

.c-pagination__pages {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-pagination__page {
  list-style: none;
  padding: 0 0.5rem;
}

.c-pagination__page--active .c-pagination__link,
.c-pagination__page--active .c-pagination__link:hover {
  border-color: #808080;
  color: #4d4d4d;
}

.c-pagination__link {
  display: block;
  padding: 0.5rem 0.5rem 0.375rem;
  border-bottom: 0.125rem solid transparent;
  color: #252e62;
}
.c-pagination__link:hover {
  color: #171d3d;
  border-color: #171d3d;
}

.c-social-links__title {
  padding-bottom: 0.5rem;
}

.c-social-links__item {
  font-size: 1.125rem;
  line-height: 1.7777777778;
  display: inline-block;
  margin-left: 1rem;
}
@media (min-width: 55em) {
  .c-social-links__item {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}
.c-social-links__item:first-child {
  margin-left: 0;
}

.c-social-links--color .o-icon--twitter {
  color: #1da1f2;
}
.c-social-links--color .o-icon--facebook {
  color: #3b5998;
}
.c-social-links--color .o-icon--google-plus {
  color: #ea4335;
}
.c-social-links--color .o-icon--linkedin {
  color: #0077b5;
}
.c-social-links--color .o-icon--pinterest {
  color: #bd081c;
}
.c-social-links--color .o-icon--instagram {
  color: #f56040;
}

.c-video {
  display: block;
  position: relative;
  width: 100%;
}

.c-video__link {
  display: block;
}

.c-video__image {
  max-width: 100%;
  position: relative;
  z-index: 1;
}

.c-video__details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 1rem;
}
@media (min-width: 35em) {
  .c-video__details {
    padding: 1.5rem;
  }
}
.c-video__details:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  transform-origin: bottom center;
  transition: 0.2s all ease-in-out;
  transform: scale(1, 1);
}
.c-video__link:hover .c-video__details:after {
  transform: scale(1, 1.5);
}

.c-video__titles {
  margin-left: 1rem;
  position: relative;
  color: #ffffff;
}
@media (min-width: 35em) {
  .c-video__titles {
    margin-left: 1.5rem;
  }
}

.c-video__title {
  display: block;
}

@media (max-width: 34.9375em) {
  .c-video__subtitle {
    display: none;
  }
}

.c-video__cta {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(51, 51, 51, 0.95);
  color: #ffffff;
}
.c-video__cta.is-active {
  display: flex;
}

.c-video__cta-close {
  font-size: 1.3125rem;
  line-height: 0.9523809524;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 2;
}
@media (min-width: 55em) {
  .c-video__cta-close {
    font-size: 1.625rem;
    line-height: 1.0769230769;
  }
}

@media (max-width: 34.9375em) {
  .c-video__replay {
    display: none;
  }
}

.c-wysiwyg {
  max-width: 40rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.c-wysiwyg > *:first-child {
  margin-top: 0;
}
.c-wysiwyg > *:last-child {
  margin-bottom: 0;
}
.c-wysiwyg p {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.c-wysiwyg h2 + p,
.c-wysiwyg h3 + p,
.c-wysiwyg h4 + p,
.c-wysiwyg h5 + p,
.c-wysiwyg h6 + p,
.c-wysiwyg .h2 + p,
.c-wysiwyg .h3 + p,
.c-wysiwyg .h4 + p,
.c-wysiwyg .h5 + p,
.c-wysiwyg .h6 + p {
  margin-top: 0;
}
.c-wysiwyg ul,
.c-wysiwyg ol {
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.c-wysiwyg ul {
  list-style-type: initial;
}
.c-wysiwyg ol {
  list-style-type: decimal;
}
.c-wysiwyg img,
.c-wysiwyg .wp-caption {
  max-width: 100%;
  height: auto;
  margin: 2rem auto;
  display: table;
}
@media (min-width: 35em) {
  .c-wysiwyg img,
  .c-wysiwyg .wp-caption {
    margin: 3rem auto;
  }
  .c-wysiwyg img.alignleft,
  .c-wysiwyg .wp-caption.alignleft {
    float: left;
    max-width: 50%;
    margin-right: 2rem;
  }
  .c-wysiwyg img.alignright,
  .c-wysiwyg .wp-caption.alignright {
    float: right;
    max-width: 50%;
    margin-left: 2rem;
  }
}
.c-wysiwyg img .wp-caption-text,
.c-wysiwyg .wp-caption .wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: table-caption;
  caption-side: bottom;
  margin: 0;
  text-align: center;
}
.c-wysiwyg .wp-caption img {
  margin: 0 0 1rem;
}
.c-wysiwyg .wp-caption {
  width: auto !important;
}

.c-wysiwyg--center {
  margin-left: auto;
  margin-right: auto;
}

h2 + .c-wysiwyg,
h3 + .c-wysiwyg,
h4 + .c-wysiwyg,
h5 + .c-wysiwyg,
h6 + .c-wysiwyg {
  margin-top: 0;
}

.c-brands__title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 4rem;
}

.c-brands__item img {
  max-width: 100%;
}

.c-brands--card .c-brands__item img {
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 1rem 3rem rgba(22, 28, 59, 0.3);
}

.c-brands--grid .c-brands__list {
  display: grid;
}
@media (max-width: 54.9375em) {
  .c-brands--grid .c-brands__list {
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: center;
  }
}
@media (min-width: 55em) {
  .c-brands--grid .c-brands__list {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.no-cssgrid .c-brands--grid .c-brands__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  justify-content: flex-start;
}
@media (max-width: 54.9375em) {
  .c-brands--grid .c-brands__item {
    grid-row-start: span 2;
    max-width: 20rem;
  }
  .c-brands--grid .c-brands__item:nth-child(even) {
    transform: translateY(50%);
  }
}
@media (min-width: 55em) {
  .c-brands--grid .c-brands__item:nth-child(even) {
    transform: translateY(2rem);
  }
  .c-brands--grid .c-brands__item:nth-child(odd) {
    transform: translateY(-2rem);
  }
}
.no-cssgrid .c-brands--grid .c-brands__item {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (max-width: 54.9375em) {
  .no-cssgrid .c-brands--grid .c-brands__item {
    width: 50%;
    margin-bottom: 2rem;
  }
}
@media (min-width: 55em) {
  .no-cssgrid .c-brands--grid .c-brands__item {
    width: 20%;
  }
}

.c-brands--flex .c-brands__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.c-brands--flex .c-brands__item {
  padding: 1.25rem;
  flex: 0 1 auto;
}
@media (min-width: 55em) {
  .c-brands--flex .c-brands__item {
    flex: 0 1 20%;
  }
}

.c-icon-list {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.c-icon-list__item {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  max-width: 30rem;
}
@media (min-width: 35em) {
  .c-icon-list__item {
    width: 50%;
  }
}

.c-icon-list__image {
  width: 4rem;
}

.c-icon-list__copy {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  flex: 0 4 auto;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
@media (min-width: 55em) {
  .c-icon-list__copy {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-gallery__items {
  display: grid;
}
@media (max-width: 34.9375em) {
  .c-gallery__items {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}
@media (min-width: 35em) {
  .c-gallery__items {
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
  }
}

.c-gallery__item {
  position: relative;
}
.c-gallery__item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
}

@media (max-width: 34.9375em) {
  .c-gallery--alpha .c-gallery__items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 2fr;
    grid-template-areas: "one two" "three three";
  }
  .no-cssgrid .c-gallery--alpha .c-gallery__items {
    display: flex;
    flex-wrap: wrap;
  }
  .c-gallery--alpha .c-gallery__item:nth-child(1) {
    grid-area: one;
  }
  .no-cssgrid .c-gallery--alpha .c-gallery__item:nth-child(1) {
    width: 50%;
  }
  .c-gallery--alpha .c-gallery__item:nth-child(2) {
    grid-area: two;
  }
  .no-cssgrid .c-gallery--alpha .c-gallery__item:nth-child(2) {
    width: 50%;
  }
  .c-gallery--alpha .c-gallery__item:nth-child(3) {
    grid-area: three;
  }
  .no-cssgrid .c-gallery--alpha .c-gallery__item:nth-child(3) {
    width: 100%;
  }
}
@media (min-width: 35em) {
  .c-gallery--alpha .c-gallery__items {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "one three" "two three";
  }
  .no-cssgrid .c-gallery--alpha .c-gallery__items {
    max-width: 45rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .no-cssgrid .c-gallery--alpha .c-gallery__item {
    padding: 0.75rem;
  }
  .c-gallery--alpha .c-gallery__item:nth-child(1) {
    grid-area: one;
  }
  .no-cssgrid .c-gallery--alpha .c-gallery__item:nth-child(1) {
    width: 50%;
  }
  .c-gallery--alpha .c-gallery__item:nth-child(2) {
    grid-area: two;
  }
  .no-cssgrid .c-gallery--alpha .c-gallery__item:nth-child(2) {
    width: 50%;
  }
  .c-gallery--alpha .c-gallery__item:nth-child(3) {
    grid-area: three;
  }
  .no-cssgrid .c-gallery--alpha .c-gallery__item:nth-child(3) {
    width: 100%;
  }
}

.c-gallery--bravo {
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 34.9375em) {
  .c-gallery--bravo {
    margin-top: 2.5rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-gallery--bravo {
    margin-top: 3rem;
  }
}
@media (min-width: 55em) and (max-width: 74.9375em) {
  .c-gallery--bravo {
    margin-top: 4rem;
  }
}
@media (min-width: 75em) {
  .c-gallery--bravo {
    margin-top: 5rem;
  }
}
.c-gallery--bravo .c-gallery__items {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "one three" "two three";
}
.no-cssgrid .c-gallery--bravo .c-gallery__item {
  padding: 0.75rem;
}
.c-gallery--bravo .c-gallery__item:nth-child(1) {
  grid-area: one;
}
.c-gallery--bravo .c-gallery__item:nth-child(2) {
  grid-area: two;
}
.c-gallery--bravo .c-gallery__item:nth-child(3) {
  grid-area: three;
}
.c-gallery--bravo.c-gallery--mirror .c-gallery__items {
  grid-template-areas: "three one" "three two";
}

.c-wysiwyg {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
}
@media (min-width: 55em) {
  .c-wysiwyg {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-action-block {
  margin-top: 0;
  padding: 0;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
}

.c-action-block--card {
  padding: 2.5rem;
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1rem rgba(22, 28, 59, 0.1);
}

.c-action-block__image {
  margin-bottom: 0;
  margin-right: 2rem;
}

.c-action-block__content {
  text-align: left;
}

.c-action-block__copy {
  font-size: 1.0625rem;
  line-height: 1.4117647059;
  text-align: left;
}
@media (min-width: 55em) {
  .c-action-block__copy {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}

.c-action-block__title {
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  font-family: Muli, sans-serif;
  font-weight: 700;
  color: #161c3b;
  margin-top: 4rem;
  margin-bottom: 1rem;
  display: block;
  margin-top: 0;
}
@media (min-width: 55em) {
  .c-action-block__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
@media (max-width: 54.9375em) {
  .c-action-block__title {
    margin-bottom: 1rem;
  }
}
@media (min-width: 55em) {
  .c-action-block__title {
    margin-bottom: 2rem;
  }
}

.c-action-block--stacked {
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.c-action-block--stacked .c-action-block__image {
  margin-right: 0;
  margin-bottom: 2rem;
}
.c-action-block--stacked .c-action-block__content {
  text-align: center;
}
.c-action-block--stacked .c-action-block__title {
  margin-bottom: 1rem;
}
.c-action-block--stacked .c-action-block__copy {
  text-align: center;
}
.c-action-block--stacked .c-action-block__copy span {
  display: block;
}

.c-pagination {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 55em) {
  .c-pagination {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-pagination__prev,
.c-pagination__next {
  padding: 0.5rem;
  color: #252e62;
  margin: 0 0.5rem;
  text-decoration: none;
}
.c-pagination__prev:hover,
.c-pagination__next:hover {
  color: #171d3d;
}

.c-pagination__pages {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-pagination__page {
  list-style: none;
  padding: 0.5rem;
}

.c-pagination__page--active .c-pagination__link,
.c-pagination__page--active .c-pagination__link:hover {
  color: #252e62;
  border: solid 0.0125rem #252e62;
  border-radius: 50%;
  background-color: #ffffff;
}

.c-pagination__link {
  display: block;
  padding: 0.5rem 1.3rem;
  color: #252e62;
  border-radius: 50%;
  background-color: #e9f7fb;
}
.c-pagination__link:hover {
  color: #171d3d;
}

.c-profile-block-small {
  display: flex;
  align-items: center;
}

.c-profile-block--stacked {
  flex-direction: column;
}
.c-profile-block--stacked .c-profile-block__image-wrapper .c-profile-block__image {
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(22, 28, 59, 0.1);
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}
.c-profile-block--stacked .c-profile-block__name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
@media (min-width: 55em) {
  .c-profile-block--stacked .c-profile-block__name {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

.c-profile-block__content-small {
  text-align: left;
  margin-left: 1rem;
}

.c-profile-block__name-small {
  font-size: 1.125rem;
  display: block;
  margin: 0 0 -0.25rem 0;
}

.c-profile-block__title-small {
  font-size: 1rem;
  display: block;
  margin: 0;
}

.c-profile-block__name {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: "Muli", sans-serif;
  font-weight: 700;
  color: #252e62;
  display: block;
  margin-left: 0;
  margin-right: 0;
}

.c-profile-block__title {
  font-size: 1rem;
  line-height: 1.25;
  font-family: "Muli", sans-serif;
  font-weight: 400;
  color: #258dae;
  margin-bottom: 1rem;
  display: block;
  margin-left: 0;
  margin-right: 0;
}

.c-profile-block__image-small {
  border-radius: 15rem;
  max-width: 120px;
  max-height: 120px;
}

.c-profile-block__after {
  color: #737373;
}

.c-profile-single {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-top: 3.5rem;
  border-top: 2px solid #dcdee2;
}
@media (max-width: 34.9375em) {
  .c-profile-single {
    padding: 2rem 1rem 0 1rem;
    align-items: center;
  }
  .c-profile-single .c-profile-single__social {
    padding-top: 1.5rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-profile-single {
    padding: 2rem 1rem 0 1rem;
  }
}
.c-profile-single .c-profile-single__content {
  flex-grow: 2;
}

.c-profile-single--stacked {
  flex-direction: column;
}
.c-profile-single--stacked .c-profile-single__image {
  max-width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(22, 28, 59, 0.1);
  margin-bottom: 1rem;
  border-radius: 0.375rem;
}
@media (max-width: 34.9375em) {
  .c-profile-single--stacked .c-profile-single__image {
    max-width: 75%;
  }
}
.c-profile-single--stacked .c-profile-single__name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
@media (min-width: 55em) {
  .c-profile-single--stacked .c-profile-single__name {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

.c-profile-single__content {
  text-align: left;
  margin-left: 2rem;
}
@media (max-width: 34.9375em) {
  .c-profile-single__content {
    margin-left: 0.5rem;
  }
}

.c-profile-single__intro {
  max-width: 50rem;
  margin: 2rem auto;
}
@media (max-width: 34.9375em) {
  .c-profile-single__intro {
    padding: 0 1rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-profile-single__intro {
    padding: 0 1rem;
  }
}

.c-profile-single__name {
  font-size: 1.625rem;
  display: block;
  margin: 0;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  color: #252e62;
}

.c-profile-single__title {
  font-size: 1.125rem;
  display: block;
  margin: 0;
  color: #258dae;
}

.c-profile-single__image {
  border-radius: 0.375rem;
  max-width: 120px;
  max-height: 120px;
}

.c-profile-single__after {
  color: #737373;
}

.c-profile-single__link {
  text-decoration: none;
  font-size: 2rem;
  padding-left: 1rem;
  /*.o-icon {
      background-color: $primary;
      color: white;
      border-radius: $border-radius--circle;
      width: 7rem;
      height: 7rem;
      padding: .875rem;
  }*/
}
.c-profile-single__link:hover {
  text-decoration: none;
}
@media (max-width: 34.9375em) {
  .c-profile-single__link {
    padding-left: 0;
    padding-right: 1rem;
  }
}

.c-post-block {
  max-width: 30rem;
  margin-bottom: 2rem;
  position: relative;
}
.c-post-block a {
  position: relative;
  z-index: 1;
  text-decoration: none;
}

.c-post-block__image-wrapper {
  position: relative;
}
.c-post-block__image-wrapper img {
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.c-post-block__image-wrapper::after {
  display: block;
  content: "";
  /* 16:9 aspect ratio */
  padding-bottom: 56.25%;
}

.c-post-block__image {
  width: 100%;
  display: block;
  border-radius: 8px;
}

.c-post-block__content {
  padding: 1.5rem 0;
}

.c-post-block__copy {
  padding: 1rem 0;
}

.c-post-date {
  color: #737373;
  font-size: 0.875rem;
}

.c-post-block__category {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  color: #258dae;
  margin-right: 2rem;
  display: inline-block;
}
.c-post-block__category:hover {
  text-decoration: underline;
}

a.c-post-block__link {
  position: inherit;
  z-index: 0;
}
a.c-post-block__link:before {
  content: "";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}

.c-post-block__title {
  font-size: 1.3125rem;
  line-height: 1.3333333333;
  color: #252e62;
  font-weight: 400;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 55em) {
  .c-post-block__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
@supports (text-decoration-color: transparent) {
  .c-post-block__title {
    text-decoration: underline;
    text-decoration-color: transparent;
  }
}
.c-post-block__link:hover .c-post-block__title {
  color: #252e62;
  text-decoration: underline;
  text-decoration-color: #252e62;
}

.c-post-block__meta {
  margin-top: 1rem;
}

.c-post-block__button {
  margin-top: 1rem;
}

.c-post-block--card .c-post-block__inner {
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
  border-radius: 1rem;
}
.c-post-block--card .c-post-block__inner:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.375rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2);
}
.c-post-block--card .c-post-block__image {
  border-radius: 1rem 1rem 0 0;
}
.c-post-block--card .c-post-block__content {
  padding: 1.5rem;
}
.c-post-block--card.c-post-block--horizontal .c-post-block__image {
  border-radius: 1rem 0 0 1rem;
}
.c-post-block--card.c-post-block--horizontal .c-post-block__content {
  padding: 1.5rem;
}

.c-post-block--horizontal {
  max-width: 60rem;
}
.c-post-block--horizontal .c-post-block__inner {
  display: flex;
  align-items: stretch;
}
.c-post-block--horizontal .c-post-block__image-wrapper {
  width: 33.3333333333%;
  flex-grow: 0;
  flex-shrink: 0;
}
.c-post-block--horizontal .c-post-block__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.c-post-block--horizontal .c-post-block__content {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.c-blockquote--card {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 0.375rem;
}
@media (max-width: 34.9375em) {
  .c-blockquote--card {
    padding: 1.5rem 2rem;
  }
}
@media (min-width: 35em) {
  .c-blockquote--card {
    padding: 3.75rem 3rem 4rem;
  }
}

.c-blockquote__quote {
  font-size: 1.125rem;
  line-height: 1.5555555556;
}
@media (min-width: 55em) {
  .c-blockquote__quote {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}

.c-blockquote__title {
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  font-family: Muli, sans-serif;
  font-weight: 700;
  color: #161c3b;
  margin-top: 4rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 55em) {
  .c-blockquote__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

.c-blockquote__subtitle {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #258dae;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  text-transform: none;
  margin-top: 0;
}

.c-cta--footer {
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 8%;
  margin: 0;
  width: 100%;
  max-width: 100%;
  color: #ffffff;
  border: 0;
  padding-top: 6.4285714286%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 87.5em) {
  .c-cta--footer {
    padding-bottom: 7rem;
  }
}
.c-cta--footer > .l-container {
  position: relative;
  z-index: 1;
}
.c-cta--footer:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-cta--footer:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
.c-cta--footer:after {
  bottom: -0.0625rem;
}
@media (min-width: 87.5em) {
  .c-cta--footer {
    padding-bottom: 7rem;
  }
}
@media (min-width: 87.5em) {
  .c-cta--footer {
    padding-bottom: 5.625rem;
  }
}
.c-cta--footer .c-cta__title {
  font-size: 1.3125rem;
  line-height: 1.7142857143;
  margin-top: 7rem;
  margin-bottom: 2rem;
}
@media (min-width: 55em) {
  .c-cta--footer .c-cta__title {
    font-size: 1.625rem;
    line-height: 1.6923076923;
  }
}
.c-cta--footer .c-cta__button {
  margin-top: 0;
  margin-bottom: 6rem;
}
.c-section + .c-cta--footer {
  position: relative;
  padding-top: 6.4285714286%;
}
@media (min-width: 87.5em) {
  .c-section + .c-cta--footer {
    padding-top: 5.625rem;
  }
}
.c-section + .c-cta--footer > .l-container {
  position: relative;
  z-index: 1;
}
.c-section + .c-cta--footer:before {
  content: "";
  background-image: url("../assets/img/section-bg/wt--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 6.4285714286%;
  top: 0;
  left: 0;
  z-index: 0;
}
@media (min-width: 87.5em) {
  .c-section + .c-cta--footer:before {
    padding-top: 5.625rem;
    background-size: 100% 5.625rem;
  }
}
.c-section--light + .c-cta--footer {
  position: relative;
  padding-top: 6.4285714286%;
}
@media (min-width: 87.5em) {
  .c-section--light + .c-cta--footer {
    padding-top: 5.625rem;
  }
}
.c-section--light + .c-cta--footer > .l-container {
  position: relative;
  z-index: 1;
}
.c-section--light + .c-cta--footer:before {
  content: "";
  background-image: url("../assets/img/section-bg/lt--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 6.4285714286%;
  top: 0;
  left: 0;
  z-index: 0;
}
@media (min-width: 87.5em) {
  .c-section--light + .c-cta--footer:before {
    padding-top: 5.625rem;
    background-size: 100% 5.625rem;
  }
}

.c-cta--inline {
  border: 0;
  color: #ffffff;
}
.c-cta--inline .c-cta__title {
  font-size: 1.375rem;
  line-height: 1.2727272727;
  margin-bottom: 1rem;
}
@media (min-width: 55em) {
  .c-cta--inline .c-cta__title {
    font-size: 1.8125rem;
    line-height: 1.2413793103;
  }
}

.c-video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.c-hero__before {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.c-hero--single .c-breadcrumbs {
  margin-bottom: 1rem;
}
.c-hero--single .c-hero__content {
  padding-top: 2rem;
  padding-bottom: 3rem;
  border-bottom: 0.0625rem solid #e9f7fb;
}

.c-hero__bottom-menu {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 55em) {
  .c-hero__bottom-menu {
    display: none;
  }
}
.c-hero__bottom-menu .c-hero__jump-to {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: center;
  border: thin solid #808080;
  padding: 0;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-hero__bottom-menu .c-hero__jump-to {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.c-hero__bottom-menu .c-jump-to__title {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  padding: 1rem 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.c-hero__bottom-menu .c-jump-to.is-active .c-jump-to__title {
  background: rgba(37, 46, 98, 0.1);
  color: #4d4d4d;
}
.c-hero__bottom-menu .c-jump-to.is-active .c-jump-to__title:after {
  transform: rotate(180deg);
}
.c-hero__bottom-menu .c-jump-to__list {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  display: none;
}
.c-hero__bottom-menu .c-jump-to__item {
  margin: 0 auto;
}
.c-hero__bottom-menu .c-jump-to__item:last-child {
  border: none;
}

.c-media-block {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 55em) {
  .c-media-block {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
    justify-content: space-between;
    align-items: center;
  }
}

.c-media-block__media {
  text-align: center;
}
@media (max-width: 34.9375em) {
  .c-media-block__media {
    margin-bottom: 2rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-media-block__media {
    margin-bottom: 3rem;
  }
}
@media (min-width: 55em) {
  .c-media-block__media {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 50%;
  }
}

.c-media-block__image {
  position: relative;
  display: inline-block;
}
.c-media-block__image .o-image {
  max-width: 100%;
  z-index: 1;
  position: relative;
}

@media (min-width: 55em) {
  .c-media-block__content {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 50%;
  }
  .c-media-block__content .c-wysiwyg {
    max-width: 100%;
  }
}
.c-media-block__content .c-button {
  margin-right: 1rem;
}

@media (min-width: 55em) {
  .c-media-block--media-right .c-media-block__media {
    order: 2;
  }
}

@media (min-width: 55em) {
  .c-media-block--media-smaller .c-media-block__content {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .c-media-block--media-smaller .c-media-block__media {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 55em) {
  .c-media-block--third .c-media-block__content {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .c-media-block--third .c-media-block__media {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.c-expand-menu__list--d1 {
  padding-left: 0;
}

.c-expand-menu__list--d2 {
  display: none;
  width: 100%;
  padding: 0.75rem 0;
}
.c-expand-menu__item--has-children.is-active .c-expand-menu__list--d2 {
  display: block;
}

.c-expand-menu__item {
  font-size: 1rem;
  line-height: 1.75;
}

.c-expand-menu__item--d1 {
  margin-bottom: 1rem;
}

.c-expand-menu__item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.c-expand-menu__item-wrapper--d1 {
  background-color: #e6e6e6;
  color: #333333;
}

.c-expand-menu__link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: inline-block;
  color: #333333;
  flex-grow: 1;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.c-expand-menu__link:hover {
  text-decoration: underline;
  color: #333333;
}
.c-expand-menu__link.c-expand-menu__link--current {
  font-weight: bold;
  color: #333333;
}

.c-expand-menu__link--d1 {
  font-size: 0.875rem;
  line-height: 2;
  border-left: 0.125rem solid #252e62;
  padding: 1rem;
}

.c-expand-menu__link--d2 {
  color: #666666;
  padding: 0.5rem 1rem;
}

.c-expand-menu__toggle {
  background-color: transparent;
  border-color: transparent;
  color: #252e62;
  width: 3.75rem;
  height: 3.75rem;
  line-height: 3.75rem;
  padding: 0;
  border: 0;
  display: block;
}
.u-hover-children:hover .c-expand-menu__toggle, .c-expand-menu__toggle:hover {
  background-color: transparent;
  border-color: transparent;
  color: #333333;
}
.c-expand-menu__toggle .o-icon {
  line-height: 3.75rem;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);
  font-size: 1.25rem;
}
.c-expand-menu__toggle .o-icon:after {
  display: block;
}
.c-expand-menu__item--has-children.is-active .c-expand-menu__toggle .o-icon {
  transform: rotate(180deg);
}

.c-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  overflow-x: hidden;
}
@media (min-width: 35em) {
  .c-section {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
}
@media (min-width: 55em) {
  .c-section {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.c-section__description {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
}

.c-section--light {
  background-color: #e9f7fb;
}

.c-section--center .c-section__header,
.c-section--center .c-section__footer {
  text-align: center;
}
.c-section--center .c-wysiwyg {
  margin-left: auto;
  margin-right: auto;
}

.c-section-br {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  overflow-x: hidden;
}
@media (min-width: 35em) {
  .c-section-br {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
}
@media (min-width: 55em) {
  .c-section-br {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
@media (min-width: 55em) {
  .c-section-br .c-figure--right {
    max-width: 50%;
    padding-left: 2rem;
  }
  .c-section-br .c-figure--left {
    max-width: 50%;
    padding-right: 2rem;
  }
}
@media (min-width: 55em) {
  .c-section-br .c-figure--absolute-left {
    position: absolute;
    right: calc(50vw + 20rem );
    max-width: calc(50vw + 20rem );
    padding-right: 1rem;
    padding-left: 1rem;
    z-index: -1;
  }
  .c-section-br .c-figure--absolute-left .c-figure__image {
    max-width: none;
  }
  .c-section-br .c-figure--absolute-right {
    position: absolute;
    left: calc(50vw + 20rem );
    max-width: calc(50vw - 20rem );
    margin-right: -20rem;
    padding-right: 1rem;
    padding-left: 1rem;
    z-index: -1;
  }
  .c-section-br .c-figure--absolute-right .c-figure__image {
    max-width: none;
  }
}

.c-section-br__inner {
  max-width: 75em;
  margin: 0 auto;
}
.c-section-br__inner > :first-child {
  margin-top: 0;
}
.c-section-br__inner > :last-child {
  margin-bottom: 0;
}
.c-section-br__inner > * {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
}
.c-section-br__inner .c-cta {
  margin-left: auto;
  margin-right: auto;
}

.c-section-br--h2-center .c-section-br__inner > .h2 {
  text-align: center;
}

.c-section-br--text-align-center .c-section-br__inner > .h2,
.c-section-br--text-align-center .c-section-br__inner > .h3,
.c-section-br--text-align-center .c-section-br__inner > .h4,
.c-section-br--text-align-center .c-section-br__inner > .h5,
.c-section-br--text-align-center .c-section-br__inner > .h6,
.c-section-br--text-align-center .c-section-br__inner > .c-wysiwyg {
  text-align: center;
}
.c-section-br--text-align-center .c-section-br__inner .strong {
  display: block;
  text-align: center;
}
.c-section-br--text-align-center .c-section-br__inner > .c-button__wrap {
  text-align: center;
}

@media (min-width: 55em) {
  .c-section-br--overlap-center-column .c-blockquote,
  .c-section-br--overlap-center-column .c-factbox,
  .c-section-br--overlap-center-column .c-action-list,
  .c-section-br--overlap-center-column .c-media-block,
  .c-section-br--overlap-center-column .c-related-posts,
  .c-section-br--overlap-center-column .c-related-profiles {
    max-width: 50rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .c-section-br--overlap-center-column .c-blockquote:first-child,
  .c-section-br--overlap-center-column .c-factbox:first-child,
  .c-section-br--overlap-center-column .c-action-list:first-child,
  .c-section-br--overlap-center-column .c-media-block:first-child,
  .c-section-br--overlap-center-column .c-related-posts:first-child,
  .c-section-br--overlap-center-column .c-related-profiles:first-child {
    margin-top: 0;
  }
  .c-section-br--overlap-center-column .c-blockquote:last-child,
  .c-section-br--overlap-center-column .c-factbox:last-child,
  .c-section-br--overlap-center-column .c-action-list:last-child,
  .c-section-br--overlap-center-column .c-media-block:last-child,
  .c-section-br--overlap-center-column .c-related-posts:last-child,
  .c-section-br--overlap-center-column .c-related-profiles:last-child {
    margin-bottom: 0;
  }
  .c-section-br--overlap-center-column img.alignright,
  .c-section-br--overlap-center-column .wp-caption.alignright {
    margin-right: -5rem;
  }
  .c-section-br--overlap-center-column img.alignleft,
  .c-section-br--overlap-center-column .wp-caption.alignleft {
    margin-left: -5rem;
  }
}
.c-section-br--overlap-center-column .c-related-posts__list {
  justify-content: center;
}

@media (min-width: 55em) {
  .c-section-br--overlap-center-column-lg .c-blockquote,
  .c-section-br--overlap-center-column-lg .c-factbox,
  .c-section-br--overlap-center-column-lg .c-action-list,
  .c-section-br--overlap-center-column-lg .c-media-block,
  .c-section-br--overlap-center-column-lg .c-related-posts,
  .c-section-br--overlap-center-column-lg .c-related-profiles {
    max-width: none;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .c-section-br--overlap-center-column-lg .c-blockquote:first-child,
  .c-section-br--overlap-center-column-lg .c-factbox:first-child,
  .c-section-br--overlap-center-column-lg .c-action-list:first-child,
  .c-section-br--overlap-center-column-lg .c-media-block:first-child,
  .c-section-br--overlap-center-column-lg .c-related-posts:first-child,
  .c-section-br--overlap-center-column-lg .c-related-profiles:first-child {
    margin-top: 0;
  }
  .c-section-br--overlap-center-column-lg .c-blockquote:last-child,
  .c-section-br--overlap-center-column-lg .c-factbox:last-child,
  .c-section-br--overlap-center-column-lg .c-action-list:last-child,
  .c-section-br--overlap-center-column-lg .c-media-block:last-child,
  .c-section-br--overlap-center-column-lg .c-related-posts:last-child,
  .c-section-br--overlap-center-column-lg .c-related-profiles:last-child {
    margin-bottom: 0;
  }
  .c-section-br--overlap-center-column-lg img.alignright,
  .c-section-br--overlap-center-column-lg .wp-caption.alignright {
    margin-right: -5rem;
  }
  .c-section-br--overlap-center-column-lg img.alignleft,
  .c-section-br--overlap-center-column-lg .wp-caption.alignleft {
    margin-left: -5rem;
  }
}
.c-section-br--overlap-center-column-lg .c-related-posts__list {
  justify-content: center;
}

.c-section-br--light {
  background-color: #e6e6e6;
}

.c-hero {
  color: #ffffff;
  background-color: #161c3b;
  display: flex;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding-bottom: 16.5714285714%;
}
@media (max-width: 34.9375em) {
  .c-hero {
    padding-top: 5rem;
    min-height: 15rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-hero {
    padding-top: 8.5rem;
    min-height: 25rem;
  }
}
@media (min-width: 55em) {
  .c-hero {
    padding-top: 12rem;
    min-height: 37.5rem;
  }
}
@media (min-width: 87.5em) {
  .c-hero {
    padding-bottom: 14.5rem;
  }
}
@media (min-width: 35em) {
  .c-hero:before {
    content: "";
    background-image: url("../assets/img/hero__top.png");
    position: absolute;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    width: 100%;
    padding-top: 14.1428571429%;
    height: 0;
    top: 0;
    right: 0;
  }
}
@media (min-width: 87.5em) {
  .c-hero:before {
    padding-top: 12.375rem;
    background-size: 100% 12.375rem;
  }
}
.c-hero:after {
  content: "";
  background-image: url("../assets/img/hero__bottom.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 16.5714285714%;
  bottom: 0;
  right: 0;
}
@media (min-width: 87.5em) {
  .c-hero:after {
    padding-top: 14.5rem;
    background-size: 100% 14.5rem;
  }
}
.c-hero .c-hero__before {
  width: 0;
}

.c-hero--home {
  overflow: hidden;
}
.c-hero--home:before, .c-hero--home:after {
  z-index: 1;
}
.c-hero--home .c-hero__content {
  z-index: 2;
}
.c-hero--home .c-button--video {
  margin-top: 5rem;
}

.c-hero__content {
  z-index: 2;
  position: relative;
}

.c-hero__main {
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
}

.c-hero__title {
  font-size: 2.0625rem;
  line-height: 0.9696969697;
  color: inherit;
}
@media (min-width: 55em) {
  .c-hero__title {
    font-size: 3.625rem;
    line-height: 1.0344827586;
  }
}

.c-hero__logo {
  display: block;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.c-hero__description {
  font-size: 1.0625rem;
  line-height: 1.4117647059;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 2rem;
}
@media (min-width: 55em) {
  .c-hero__description {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
.c-hero__description > *:first-child {
  margin-top: 0;
}
.c-hero__description > *:last-child {
  margin-bottom: 0;
}

.c-hero__button {
  margin-top: 2.5rem;
}

@media (max-width: 34.9375em) {
  .c-hero__video {
    display: none;
  }
}
@media (min-width: 35em) {
  .c-hero__video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translate(-50%, -50%);
    background-size: cover;
  }
}

.c-hero--contact .c-hero__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
@media (min-width: 55em) {
  .c-hero--contact .c-hero__content {
    flex-wrap: nowrap;
    align-items: center;
  }
}
.c-hero--contact .c-hero__main,
.c-hero--contact .c-hero__aside {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex: 1 1 50%;
}
.c-hero--contact .c-hero__main {
  text-align: left;
}
@media (max-width: 54.9375em) {
  .c-hero--contact .c-form {
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-hero--linkbuilding .c-hero__main {
  max-width: 48rem;
}

.c-section {
  overflow-x: visible;
}

.c-section--light {
  background-color: #e9f7fb;
}

.c-section--primary {
  background-color: #252e62;
}
.c-section--primary .c-section__title,
.c-section--primary .c-section__description,
.c-section--primary .c-careers-list__title {
  color: #ffffff;
}
.c-section--primary .c-careers-list__location {
  color: rgba(255, 255, 255, 0.8);
}
.c-section--primary .c-careers-list__item {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

.c-section--wide .l-container {
  max-width: 120rem;
}

@media (max-width: 34.9375em) {
  .c-section__header {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 35em) {
  .c-section__header {
    margin-bottom: 4rem;
  }
}

.c-section--center .c-section__title:after {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 34.9375em) {
  .c-section__footer {
    margin-top: 2.5rem;
  }
}
@media (min-width: 35em) {
  .c-section__footer {
    margin-top: 4rem;
  }
}

.c-section--simple {
  padding-top: 2rem;
}
.c-section--simple .c-wysiwyg {
  margin-left: auto;
  margin-right: auto;
}
.c-section--simple h2,
.c-section--simple .h2 {
  margin-top: 4rem;
}

.c-section-br--swirl {
  position: relative;
  padding-bottom: 8.2142857143%;
  position: relative;
  padding-top: 24.2857142857%;
  background-color: #e9f7fb;
  overflow: visible;
}
@media (min-width: 87.5em) {
  .c-section-br--swirl {
    padding-bottom: 7.1875rem;
  }
}
.c-section-br--swirl > .l-container {
  position: relative;
  z-index: 1;
}
.c-section-br--swirl:after {
  content: "";
  background-image: url("../assets/img/section-bg/lw--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8.2142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
  transform: translateY(50%);
}
@media (min-width: 87.5em) {
  .c-section-br--swirl:after {
    padding-top: 7.1875rem;
    background-size: 100% 7.1875rem;
  }
}
@media (min-width: 87.5em) {
  .c-section-br--swirl {
    padding-top: 21.25rem;
  }
}
.c-section-br--swirl > .l-container {
  position: relative;
  z-index: 1;
}
.c-section-br--swirl:before {
  content: "";
  background-image: url("../assets/img/section-bg/wl--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 24.2857142857%;
  top: 0;
  left: 0;
  z-index: 0;
}
@media (min-width: 87.5em) {
  .c-section-br--swirl:before {
    padding-top: 21.25rem;
    background-size: 100% 21.25rem;
  }
}
.c-section-br--swirl .c-section-br__inner {
  margin: 4rem auto 2rem;
}

.c-section-br--no-padding {
  padding-top: 0;
}

.c-site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  z-index: 100;
}

.c-site-header__left {
  flex: 1 1 auto;
}
@media (max-width: 34.9375em) {
  .c-site-header__left {
    max-width: 9.25rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__left {
    max-width: 11.875rem;
  }
}
@media (min-width: 55em) {
  .c-site-header__left {
    max-width: 14.5rem;
  }
}

@media (min-width: 55em) {
  .c-site-header__mobile {
    display: none;
  }
}

.c-site-header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 34.9375em) {
  .c-site-header__content {
    height: 5rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__content {
    height: 7rem;
  }
}
@media (min-width: 55em) {
  .c-site-header__content {
    height: 12rem;
  }
}

@media (max-width: 54.9375em) {
  .c-site-header__right {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: height 0s ease-in-out 1s;
  }
  .c-site-header__right.is-active {
    height: 100%;
  }
}

.c-site-header__button {
  font-size: 1.5625rem;
  line-height: 0.96;
  color: #ffffff;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 55em) {
  .c-site-header__button {
    font-size: 2.25rem;
    line-height: 1;
  }
}
@media (max-width: 34.9375em) {
  .c-site-header__button {
    margin-right: -1rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__button {
    width: auto;
  }
}
@media (min-width: 55em) {
  .c-site-header__button {
    display: none;
  }
}
.c-site-header__button:hover {
  color: #ffffff;
}
.c-site-header__button span {
  font-size: 1.0625rem;
  line-height: 0.9411764706;
  text-transform: uppercase;
  padding-right: 0.5rem;
  display: none;
}
@media (min-width: 55em) {
  .c-site-header__button span {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__button span {
    display: inline-block;
  }
}

@media (max-width: 54.9375em) {
  .c-mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
  }
}

@media (max-width: 54.9375em) {
  .c-mobile-nav__mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(255, 255, 255, 0.8);
    transition: opacity 0.5s ease-in-out, height 0s 0.5s ease-in-out;
    opacity: 0;
    z-index: 0;
    cursor: pointer;
  }
  .is-active .c-mobile-nav__mask {
    transition: opacity 0.5s ease-in-out, height 0s ease-in-out;
    opacity: 1;
    height: 100%;
  }
}

@media (max-width: 54.9375em) {
  .c-mobile-nav__drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding-bottom: 14.4375rem;
    background: linear-gradient(15deg, #35caf9 0%, #252e62 100%);
    mask: url("../assets/img//section-bg/mobile-nav-mask.png");
    mask-repeat: no-repeat;
    mask-position: bottom;
    mask-size: 100% auto;
    z-index: 1;
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;
  }
  .is-active .c-mobile-nav__drawer {
    transform: translateY(0);
  }
}

@media (max-width: 54.9375em) {
  .c-mobile-nav__button {
    font-size: 1.5625rem;
    line-height: 0.96;
    color: #ffffff;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (max-width: 54.9375em) and (min-width: 55em) {
  .c-mobile-nav__button {
    font-size: 2.25rem;
    line-height: 1;
  }
}
@media (min-width: 55em) {
  .c-mobile-nav__button {
    display: none;
  }
}

.c-feature--v1 .c-feature__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
.c-feature--v1 .c-feature__col {
  flex: 1 1 20rem;
}
.c-feature--v1 .c-wysiwyg {
  margin-top: 0;
  margin-bottom: 0;
}

.c-feature__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.c-feature__col {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.c-related-profiles--offset .c-related-profiles__list {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}
.c-related-profiles--offset .c-related-profiles__list-item {
  margin-bottom: 0;
  flex: 1 1 20rem;
}
.c-related-profiles--offset .c-related-profiles__list-item:nth-child(even) {
  transform: translateY(2rem);
}
.c-related-profiles--offset .c-related-profiles__list-item:nth-child(odd) {
  transform: translateY(-2rem);
}

.c-related-profiles--grid .c-related-profiles__list {
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
.no-cssgrid .c-related-profiles--grid .c-related-profiles__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
@media (max-width: 54.9375em) {
  .c-related-profiles--grid .c-related-profiles__list {
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    grid-row-gap: 3rem;
  }
}
@media (min-width: 55em) {
  .c-related-profiles--grid .c-related-profiles__list {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 6rem;
  }
}
.no-cssgrid .c-related-profiles--grid .c-related-profiles__list-item {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex: 0 0 auto;
  margin-bottom: 2rem;
}
@media (max-width: 54.9375em) {
  .no-cssgrid .c-related-profiles--grid .c-related-profiles__list-item {
    width: 50%;
  }
}
@media (min-width: 55em) {
  .no-cssgrid .c-related-profiles--grid .c-related-profiles__list-item {
    width: 25%;
  }
}

.c-related-posts {
  margin-top: 4rem;
  padding: 4rem 5rem 2rem 5rem;
  max-width: 100%;
  background-color: #e9f7fb;
}
@media (max-width: 34.9375em) {
  .c-related-posts {
    padding: 2rem 1rem;
    margin-top: 2rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-related-posts {
    padding: 2rem 1rem;
    margin-top: 2rem;
  }
}

.c-related-posts__title {
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.c-related-posts__title::after {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.c-related-posts__list {
  display: flex;
  align-items: stretch;
  overflow-x: auto;
  justify-content: center;
}

.c-related-posts__item {
  padding: 0 1rem;
  min-width: 18rem;
  display: flex;
  align-items: stretch;
}
.c-related-posts__item .c-post-block {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}
.c-related-posts__item .c-post-block--card,
.c-related-posts__item .c-post-block--card .c-post-block__inner,
.c-related-posts__item .c-post-block--card .c-post-block__content {
  display: flex;
  align-items: stretch;
}
.c-related-posts__item .c-post-block--card .c-post-block__inner,
.c-related-posts__item .c-post-block--card .c-post-block__content {
  flex-direction: column;
}
.c-related-posts__item .c-post-block--card .c-post-block__content {
  flex-grow: 1;
}
.c-related-posts__item .c-post-block--card .c-post-block__after {
  margin-top: auto;
}

.c-media-block {
  align-items: center;
}

@media (max-width: 54.9375em) {
  .c-media-block__content {
    max-width: 40rem;
    margin: 0 auto;
  }
}
.c-media-block__content *:first-child {
  margin-top: 0;
}
.c-media-block__content *:last-child {
  margin-bottom: 0;
}

.c-media-block__media .o-image {
  width: 100%;
}

.c-figure {
  display: block;
  text-align: center;
}

.c-media-block--featured .c-media-block__content {
  text-align: center;
}
@media (min-width: 55em) {
  .c-media-block--featured .c-media-block__content {
    text-align: left;
    width: 55%;
  }
}
@media (max-width: 54.9375em) {
  .c-media-block--featured .h2:after {
    margin: 2rem auto 0;
  }
}
@media (min-width: 55em) {
  .c-media-block--featured .c-media-block__media {
    width: 45%;
    text-align: right;
  }
}

.c-media-block--reverse {
  flex-direction: row-reverse;
}

.c-tabs {
  position: relative;
}

.c-tabs__nav {
  display: flex;
  align-items: baseline;
  justify-content: center;
  justify-content: space-evenly;
  position: sticky;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #ffffff;
  z-index: 10;
  top: 0;
}

.c-tabs__tabs {
  margin-top: 4rem;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-tabs__tabs {
    margin-top: 5rem;
  }
}
@media (min-width: 55em) {
  .c-tabs__tabs {
    margin-top: 6rem;
  }
}

.c-tabs__nav-item {
  list-style: none;
}

.c-tabs__nav-link {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: block;
  text-decoration: none;
  color: #333333;
  font-weight: 400;
  font-family: "Muli", sans-serif;
  padding: 0.125rem;
  margin: 0 0.5rem;
}
@media (min-width: 55em) {
  .c-tabs__nav-link {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
.c-tabs__nav-link:hover {
  color: #333333;
  border-bottom: 0.125rem solid rgba(53, 202, 249, 0.5);
  text-decoration: none;
}
.c-tabs__nav-link.is-active {
  font-weight: 700;
  border-bottom: 0.125rem solid #35caf9;
}

.c-tabs__tab {
  display: none;
}
.c-tabs__tab.is-active {
  display: block;
}

@media (max-width: 34.9375em) {
  .c-speakers {
    margin-top: 4rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-speakers {
    margin-top: 5rem;
  }
}
@media (min-width: 55em) {
  .c-speakers {
    margin-top: 6rem;
  }
}
.c-speakers .c-profile-block {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
@media (min-width: 35em) {
  .c-speakers .c-profile-block {
    justify-content: space-evenly;
    flex-wrap: nowrap;
  }
}
.c-speakers .c-profile-block__image-wrapper {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex: 0 1 17rem;
  margin-bottom: 1rem;
}
.c-speakers .c-profile-block__content {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex: 0 1 40rem;
}
.c-speakers .c-profile-block__image {
  max-width: 100%;
  display: block;
}

.c-speakers__heading {
  text-align: center;
}
.c-speakers__heading:after {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 34.9375em) {
  .c-speakers__list-item {
    margin-top: 4rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-speakers__list-item {
    margin-top: 5rem;
  }
}
@media (min-width: 55em) {
  .c-speakers__list-item {
    margin-top: 6rem;
  }
}

.c-site-footer {
  margin-top: 0;
  background-color: #252e62;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.c-site-footer a {
  color: #ffffff;
}
.c-site-footer a:hover {
  color: #ffffff;
}
@media (min-width: 55em) {
  .c-site-footer .c-social-links {
    margin-left: -1rem;
  }
}
.c-site-footer .c-social-links__item {
  margin: 0;
}
.c-site-footer .c-social-links__link {
  color: rgba(255, 255, 255, 0.8);
}
.c-site-footer > .l-container {
  display: grid;
  align-items: flex-start;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
@media (min-width: 55em) {
  .c-site-footer > .l-container {
    grid-template-columns: 20rem 1fr;
    grid-template-rows: repeat(2, auto);
  }
}

.c-logo--light {
  background-image: url("../assets/img/logo--white.svg");
}

.c-site-footer__logo {
  max-width: 14.5rem;
  width: 100%;
}
@media (max-width: 54.9375em) {
  .c-site-footer__logo {
    margin-left: auto;
    margin-right: auto;
  }
}

.c-site-footer__contact {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.c-site-footer__contact li {
  margin-bottom: 0.75rem;
}
.c-site-footer__contact li span {
  display: inline-block;
}
.c-site-footer__contact a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.c-site-footer__contact a:hover {
  text-decoration: underline;
}

.c-site-footer__alpha {
  display: flex;
  align-items: center;
}
@media (min-width: 55em) {
  .c-site-footer__alpha {
    justify-content: flex-start;
    grid-column: 1/span 1;
    grid-row: 1/span 1;
  }
}

.c-site-footer__bravo {
  display: grid;
  grid-column-gap: 5rem;
}
@media (max-width: 54.9375em) {
  .c-site-footer__bravo {
    justify-content: center;
  }
}
@media (min-width: 55em) {
  .c-site-footer__bravo {
    grid-template-columns: repeat(2, max-content);
    justify-content: flex-end;
    grid-column: 2/span 1;
    grid-row: 1/span 2;
  }
}

@media (max-width: 54.9375em) {
  .c-site-footer__charlie {
    text-align: center;
  }
}
@media (min-width: 55em) {
  .c-site-footer__charlie {
    grid-column: 1/span 1;
    grid-row: 2/span 2;
  }
}

.c-site-footer__delta {
  display: flex;
}
@media (max-width: 54.9375em) {
  .c-site-footer__delta {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media (min-width: 55em) {
  .c-site-footer__delta {
    justify-content: flex-end;
    align-items: flex-end;
    grid-column: 2/span 1;
    grid-row: 3/span 1;
  }
}
.c-site-footer__delta .c-site-footer__bbb {
  padding-bottom: 1rem;
}
@media (max-width: 54.9375em) {
  .c-site-footer__delta .c-site-footer__bbb {
    padding-top: 1rem;
  }
}
.c-site-footer__delta .c-site-footer__top-work {
  padding: 0 1rem;
}

.c-site-footer__echo {
  grid-column: 1/-1;
  text-align: center;
  padding-top: 3rem;
}

.c-site-footer__menu {
  font-size: 1rem;
  line-height: 2;
}
@media (max-width: 54.9375em) {
  .c-site-footer__menu .c-menu__list {
    text-align: center;
  }
}
@media (min-width: 55em) {
  .c-site-footer__menu .c-menu__list {
    text-align: right;
  }
}
.c-site-footer__menu .c-menu__link {
  text-decoration: none;
  font-weight: 700;
}
.c-site-footer__menu .c-menu__item {
  margin-bottom: 0.5rem;
}

.c-site-footer__bbb {
  display: inline-block;
}

.c-site-footer__disclaimer {
  font-size: 0.75rem;
  line-height: 4;
}

.c-action-list .c-action-block--card {
  height: 100%;
}

.c-action-list__items {
  display: grid;
}
.no-cssgrid .c-action-list__items {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
@media (max-width: 34.9375em) {
  .c-action-list__items {
    grid-gap: 2.5rem;
  }
}
@media (min-width: 35em) {
  .c-action-list__items {
    grid-gap: 3rem;
    grid-row-gap: 4rem;
  }
}
@media (min-width: 55em) {
  .c-action-list__items {
    grid-template-columns: repeat(2, auto);
  }
}
@media (min-width: 75em) {
  .c-action-list__items {
    grid-gap: 5rem;
  }
}

.c-action-list__item {
  padding: 0;
}
.no-cssgrid .c-action-list__item {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 55em) {
  .no-cssgrid .c-action-list__item {
    width: 50%;
  }
}

.c-action-block {
  margin-bottom: 0;
}

.c-action-block__media {
  flex: 0 0 auto;
}

.c-action-block__content {
  flex: 1 1 auto;
}

@media (min-width: 55em) {
  .c-action-list--offset .c-action-list__item:nth-child(odd) {
    padding-bottom: 4rem;
  }
  .c-action-list--offset .c-action-list__item:nth-child(even) {
    padding-top: 4rem;
  }
}

.c-action-list--events .c-action-list__items {
  grid-template-columns: repeat(auto-fit, minmax(1fr, 25rem));
  grid-column-gap: 2rem;
  justify-content: space-evenly;
}
.c-action-list--events .c-action-block {
  flex-direction: column;
  max-width: 25rem;
}
.c-action-list--events .c-action-block__media {
  width: 100%;
  margin-bottom: 2rem;
}
.c-action-list--events .c-action-block__image {
  margin-right: 0;
}
.c-action-list--events .o-image {
  max-width: 100%;
  border-radius: 0.25rem;
}

.c-action-list--3-col .c-action-list__items {
  grid-template-columns: repeat(3, auto);
}

.c-action-block--card-image-left .c-action-block__content {
  flex-wrap: nowrap;
}
.c-action-block--card-image-left .c-action-block__content .c-action-block__image img {
  max-width: 4rem;
  margin-right: 1rem;
}

.c-action-list--vertical-card .c-action-list__item .c-action-block {
  background-color: #ffffff;
  display: block;
  padding: 2rem;
}
.c-action-list--vertical-card .c-action-block {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
}
.c-action-list--vertical-card .c-action-block__title {
  margin: 2rem auto;
  text-align: center;
}
.c-action-list--vertical-card .c-action-block__image {
  margin-right: 0;
}
.c-action-list--vertical-card .c-action-block__copy {
  margin-bottom: 2rem;
}

.c-modal {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
@media (max-width: 54.9375em) {
  .c-modal {
    padding: 2.5rem 2rem;
  }
}
@media (min-width: 55em) {
  .c-modal {
    padding: 3rem 2.5rem;
  }
}

.c-modal__header {
  margin-bottom: 2rem;
  text-align: center;
}

.c-modal__footer {
  margin-top: 2rem;
  text-align: center;
}

.c-modal__title {
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  font-family: Muli, sans-serif;
  font-weight: 700;
  color: #161c3b;
  margin-top: 4rem;
  margin-bottom: 1rem;
}
@media (min-width: 55em) {
  .c-modal__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

.c-careers-list {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.c-careers-list__item {
  border-bottom: 0.0625rem solid #e6e6e6;
}
.c-careers-list__item:last-child {
  border-bottom: none;
}

.c-careers-list__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.c-careers-list__link:hover {
  text-decoration: none;
}

.c-careers-list__title {
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  font-family: Muli, sans-serif;
  font-weight: 700;
  color: #161c3b;
  margin-top: 4rem;
  margin-bottom: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 2rem;
}
@media (min-width: 55em) {
  .c-careers-list__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
.c-careers-list__link:hover .c-careers-list__title {
  text-decoration: underline;
}

.c-careers-list__location {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  flex: 0 0 auto;
  color: #666666;
}
@media (min-width: 55em) {
  .c-careers-list__location {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-contact-list {
  position: relative;
  padding-top: 24.2857142857%;
}
@media (min-width: 87.5em) {
  .c-contact-list {
    padding-top: 21.25rem;
  }
}
.c-contact-list > .l-container {
  position: relative;
  z-index: 1;
}
.c-contact-list:before {
  content: "";
  background-image: url("../assets/img/section-bg/wl--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 24.2857142857%;
  top: 0;
  left: 0;
  z-index: 0;
}
@media (min-width: 87.5em) {
  .c-contact-list:before {
    padding-top: 21.25rem;
    background-size: 100% 21.25rem;
  }
}
.c-contact-list:before {
  border-top: 10rem solid #ffffff;
}
.c-contact-list .c-action-list__items {
  display: grid;
  grid-gap: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
.no-cssgrid .c-contact-list .c-action-list__items {
  display: flex;
  margin: 0 auto;
}
@media (max-width: 34.9375em) {
  .c-contact-list .c-action-list__items {
    max-width: 25rem;
  }
}
@media (min-width: 35em) and (max-width: 74.9375em) {
  .c-contact-list .c-action-list__items {
    grid-template-columns: repeat(2, 1fr);
    max-width: 50rem;
  }
}
@media (min-width: 75em) {
  .c-contact-list .c-action-list__items {
    grid-template-columns: repeat(4, 1fr);
  }
}
.no-cssgrid .c-contact-list .c-action-list__item {
  margin-bottom: 2rem;
}
@media (min-width: 35em) and (max-width: 74.9375em) {
  .no-cssgrid .c-contact-list .c-action-list__item {
    width: 50%;
  }
}
@media (min-width: 75em) {
  .no-cssgrid .c-contact-list .c-action-list__item {
    width: 25%;
  }
}
.c-contact-list .c-action-block {
  padding: 3rem 1rem;
}
.c-contact-list .c-action-block--card {
  background: #ffffff;
}
.c-contact-list .c-action-block__copy {
  font-size: 1rem;
  line-height: 1.75;
}
.c-contact-list a {
  color: #4d4d4d;
  text-decoration: none;
}
.c-contact-list a:hover {
  text-decoration: underline;
}
.c-contact-list .o-icon {
  font-size: 1.3125rem;
  line-height: 0.9523809524;
}
@media (min-width: 55em) {
  .c-contact-list .o-icon {
    font-size: 1.625rem;
    line-height: 1.0769230769;
  }
}
.c-contact-list .c-social-links__item {
  margin-left: 0;
}
.c-contact-list .c-social-links__link {
  color: rgba(37, 46, 98, 0.5);
}
.c-contact-list .c-social-links__link:hover {
  color: #252e62;
}

.c-form--contact {
  background-color: #ffffff;
  border-radius: 0.375rem;
  text-align: left;
}
@media (max-width: 34.9375em) {
  .c-form--contact {
    padding: 1.5rem;
  }
}
@media (min-width: 35em) {
  .c-form--contact {
    padding: 2.5rem;
  }
}
.c-form--contact .c-form__submit {
  display: block;
  margin: 2rem auto 0;
}

.c-form__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}
.no-cssgrid .c-form__row {
  display: block;
}

.c-form__group {
  margin-bottom: 0.75rem;
}
.c-form__group.has-error .c-form__control,
.c-form__group.has-error .c-form__textarea {
  background-color: rgba(255, 0, 0, 0.1);
}

.c-form__label {
  color: #161c3b;
  font-family: "Muli", sans-serif;
}

.c-form__label--error {
  color: #ff0000;
}

.c-form__control,
.c-form__textarea {
  background-color: #dcdee2;
  border-color: #dcdee2;
  color: #161c3b;
  border-radius: 0.375rem;
}

.c-form__textarea {
  height: 7.5rem;
}

.c-form__group--styled-select {
  display: flex;
  flex-wrap: no-wrap;
  align-items: baseline;
}
.c-form__group--styled-select .c-form__label {
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  font-family: Muli, sans-serif;
  font-weight: 700;
  color: #161c3b;
  margin-top: 4rem;
  margin-bottom: 1rem;
  flex-shrink: 0;
  margin: 0 1.5rem 0 0;
}
@media (min-width: 55em) {
  .c-form__group--styled-select .c-form__label {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
.c-form__group--styled-select .c-form__control {
  font-size: 1.3125rem;
  line-height: 1.7142857143;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  color: #35caf9;
  border-radius: 0;
  text-transform: lowercase;
  flex-grow: 0;
  width: auto;
  background: transparent;
  border-color: transparent;
  border-bottom: 0.125rem solid #35caf9;
  padding-left: 0.25rem;
  padding-right: 1.5rem;
  appearance: none;
}
@media (min-width: 55em) {
  .c-form__group--styled-select .c-form__control {
    font-size: 1.625rem;
    line-height: 1.6923076923;
  }
}

@media (max-width: 54.9375em) {
  .c-site-menu__list--d1 {
    padding-top: 5rem;
  }
  .c-site-menu__item--d1 {
    text-align: center;
    padding-bottom: 1.5rem;
  }
  .c-site-menu__item--d1:last-child {
    padding-bottom: 0;
  }
  .c-site-menu__link--d1 {
    font-size: 1.3125rem;
    line-height: 1.7142857143;
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    padding: 0 0.25rem;
  }
}
@media (max-width: 54.9375em) and (min-width: 55em) {
  .c-site-menu__link--d1 {
    font-size: 1.625rem;
    line-height: 1.6923076923;
  }
}
@media (max-width: 54.9375em) {
  .c-site-menu__link--d1:hover {
    text-decoration: none;
    border-bottom: 0.125rem solid #ffffff;
    color: #ffffff;
  }
  button.c-site-menu__toggle {
    display: contents;
    color: #ffffff;
  }
  button.c-site-menu__toggle:hover {
    color: #35caf9;
  }
  ul.c-site-menu__list--d2 {
    display: none;
  }
  ul.c-site-menu__list--d2 a {
    color: #ffffff;
  }
}
@media (min-width: 55em) and (min-width: 35em) {
  .c-site-menu__list--d1 {
    display: flex;
  }
}
@media (min-width: 55em) {
  li.c-site-menu__item--d1 {
    font-size: 1rem;
    line-height: 1.25;
    font-family: "Muli", sans-serif;
    height: auto;
    padding-left: 2rem;
  }
  li.c-site-menu__item--d1 ul.c-site-menu__list--d2 {
    display: none;
  }
  li.c-site-menu__item--d1:hover {
    background: transparent;
  }
  li.c-site-menu__item--d1:hover ul.c-site-menu__list--d2 {
    background-color: #ffffff;
    border-radius: 0.25rem;
    display: block;
    padding: 1rem 1.5rem 0.5rem 1rem;
    position: absolute;
    margin-top: -0.25rem;
    min-width: 14rem;
  }
  li.c-site-menu__item--d1 li.c-site-menu__item--d2 {
    margin-bottom: 0.75rem;
  }
  li.c-site-menu__item--d1 li.c-site-menu__item--d2 a.c-site-menu__link--d2 {
    border-bottom: solid 0.15rem transparent;
    display: inline;
    margin-bottom: 0.5rem;
    text-decoration: none;
    font-weight: 400;
  }
  li.c-site-menu__item--d1 li.c-site-menu__item--d2 a.c-site-menu__link--d2:before {
    font-weight: 800;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  li.c-site-menu__item--d1 li.c-site-menu__item--d2 a.c-site-menu__link--d2:hover {
    border-bottom: solid 0.15rem #35caf9;
    font-weight: 800;
  }
  li.c-site-menu__item--d1.c-site-menu__item--has-children > .c-site-menu__toggle {
    display: none;
  }
  .c-site-menu__link--d1 {
    color: #ffffff;
    display: inline-block;
    font-weight: 700;
    padding: 1.25rem 0 1rem;
    position: relative;
    text-decoration: none;
  }
  .c-site-menu__link--d1:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.125rem;
    border-radius: 0.125rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .c-site-menu__item--current .c-site-menu__link--d1:after {
    background-color: #add454;
  }
  .c-site-menu__link--d1:hover {
    color: #ffffff;
    text-decoration: none;
  }
  .c-site-menu__link--d1:hover:after {
    background-color: #35caf9;
  }
  .c-site-menu__item--current .c-site-menu__link--d1:hover:after {
    background-color: #add454;
  }
}
/* Slider styles from slick.js */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 0.0625rem;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 0.0625rem solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* end Slider styles from slick.js */
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 1.875rem;
}

.slick-dots {
  position: absolute;
  bottom: -2rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 0.8rem;
  width: 0.8rem;
  margin: 0 0.3125rem;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 2rem;
  width: 2rem;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 0.3125rem;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 0.75;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 0.8rem;
  height: 0.8rem;
  font-size: 4rem;
  line-height: 0.8rem;
  text-align: center;
  color: #252e62;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: #252e62;
  opacity: 1;
}

.c-carousel {
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.5rem;
  position: relative;
}
@media (max-width: 34.9375em) {
  .c-carousel {
    padding: 0 1rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-carousel {
    padding: 0 1rem;
  }
}
.c-carousel img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 55em) {
  .c-carousel img {
    height: 100%;
    max-height: 40rem;
  }
}
.c-carousel .slick-list {
  box-shadow: rgba(50, 50, 93, 0.25) 0 3.125rem 6.25rem -1.25rem, rgba(0, 0, 0, 0.3) 0 1.875rem 3.75rem -1.875rem;
}
.c-carousel .c-carousel__figure {
  margin: 0;
}
.c-carousel .c-carousel__figure figcaption {
  background: #252e62;
  color: #ffffff;
  position: relative;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 1rem 0;
}
.c-carousel .c-carousel__figure figcaption:before {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 3.125rem;
  z-index: 0;
  left: 0;
}

.c-carousel__nav {
  position: absolute;
  width: 100%;
  height: 2rem;
  top: 45%;
  text-align: center;
}

.slick-arrow {
  border: 0;
  color: #252e62;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 100%;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.3) 0rem 1.1875rem 2.375rem, rgba(0, 0, 0, 0.22) 0rem 0.9375rem 0.75rem;
}
.slick-arrow:focus {
  outline: none;
}
.slick-arrow:hover {
  background-color: #add454;
  color: #ffffff;
}

.slick-prev,
.slick-next {
  position: absolute;
}

.slick-prev {
  left: 1rem;
}

.slick-next {
  right: 1rem;
}

.c-events__list {
  position: relative;
  padding-top: 24.2857142857%;
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 8%;
}
@media (min-width: 87.5em) {
  .c-events__list {
    padding-top: 21.25rem;
  }
}
.c-events__list > .l-container {
  position: relative;
  z-index: 1;
}
.c-events__list:before {
  content: "";
  background-image: url("../assets/img/section-bg/wl--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 24.2857142857%;
  top: 0;
  left: 0;
  z-index: 0;
}
@media (min-width: 87.5em) {
  .c-events__list:before {
    padding-top: 21.25rem;
    background-size: 100% 21.25rem;
  }
}
@media (min-width: 87.5em) {
  .c-events__list {
    padding-bottom: 7rem;
  }
}
.c-events__list > .l-container {
  position: relative;
  z-index: 1;
}
.c-events__list:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-events__list:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
.c-events__list:after {
  bottom: -0.0625rem;
}
@media (min-width: 87.5em) {
  .c-events__list {
    padding-bottom: 7rem;
  }
}

.c-home .c-page__main {
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 8%;
}
@media (min-width: 87.5em) {
  .c-home .c-page__main {
    padding-bottom: 7rem;
  }
}
.c-home .c-page__main > .l-container {
  position: relative;
  z-index: 1;
}
.c-home .c-page__main:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-home .c-page__main:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
.c-home .c-page__main:after {
  bottom: -0.0625rem;
}
@media (min-width: 87.5em) {
  .c-home .c-page__main {
    padding-bottom: 7rem;
  }
}

.c-home__intro {
  position: relative;
  padding-bottom: 24.2857142857%;
}
@media (min-width: 87.5em) {
  .c-home__intro {
    padding-bottom: 21.25rem;
  }
}
.c-home__intro > .l-container {
  position: relative;
  z-index: 1;
}
.c-home__intro:after {
  content: "";
  background-image: url("../assets/img/section-bg/wl--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 24.2857142857%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-home__intro:after {
    padding-top: 21.25rem;
    background-size: 100% 21.25rem;
  }
}
.c-home__intro:after {
  border-bottom: 10rem solid #e9f7fb;
}

.c-home__what {
  position: relative;
  padding-bottom: 8.2142857143%;
}
@media (min-width: 87.5em) {
  .c-home__what {
    padding-bottom: 7.1875rem;
  }
}
.c-home__what > .l-container {
  position: relative;
  z-index: 1;
}
.c-home__what:after {
  content: "";
  background-image: url("../assets/img/section-bg/lw--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8.2142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
  transform: translateY(50%);
}
@media (min-width: 87.5em) {
  .c-home__what:after {
    padding-top: 7.1875rem;
    background-size: 100% 7.1875rem;
  }
}

.c-about .c-page__main {
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 8%;
}
@media (min-width: 87.5em) {
  .c-about .c-page__main {
    padding-bottom: 7rem;
  }
}
.c-about .c-page__main > .l-container {
  position: relative;
  z-index: 1;
}
.c-about .c-page__main:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-about .c-page__main:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
.c-about .c-page__main:after {
  bottom: -0.0625rem;
}
@media (min-width: 87.5em) {
  .c-about .c-page__main {
    padding-bottom: 7rem;
  }
}

.c-about__intro {
  position: relative;
  padding-bottom: 24.2857142857%;
}
@media (min-width: 87.5em) {
  .c-about__intro {
    padding-bottom: 21.25rem;
  }
}
.c-about__intro > .l-container {
  position: relative;
  z-index: 1;
}
.c-about__intro:after {
  content: "";
  background-image: url("../assets/img/section-bg/wl--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 24.2857142857%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-about__intro:after {
    padding-top: 21.25rem;
    background-size: 100% 21.25rem;
  }
}
.c-about__intro:after {
  border-bottom: 10rem solid #e9f7fb;
}
.c-about__intro .c-figure {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
@media (max-width: 34.9375em) {
  .c-about__intro .c-figure {
    margin-top: 2.5rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-about__intro .c-figure {
    margin-top: 3rem;
  }
}
@media (min-width: 55em) and (max-width: 74.9375em) {
  .c-about__intro .c-figure {
    margin-top: 4rem;
  }
}
@media (min-width: 75em) {
  .c-about__intro .c-figure {
    margin-top: 5rem;
  }
}

.c-about__vision {
  position: relative;
  padding-bottom: 8.2142857143%;
}
@media (min-width: 87.5em) {
  .c-about__vision {
    padding-bottom: 7.1875rem;
  }
}
.c-about__vision > .l-container {
  position: relative;
  z-index: 1;
}
.c-about__vision:after {
  content: "";
  background-image: url("../assets/img/section-bg/lw--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8.2142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
  transform: translateY(50%);
}
@media (min-width: 87.5em) {
  .c-about__vision:after {
    padding-top: 7.1875rem;
    background-size: 100% 7.1875rem;
  }
}
@media (max-width: 34.9375em) {
  .c-about__vision .c-brands {
    margin-top: 2.5rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-about__vision .c-brands {
    margin-top: 3rem;
  }
}
@media (min-width: 55em) and (max-width: 74.9375em) {
  .c-about__vision .c-brands {
    margin-top: 4rem;
  }
}
@media (min-width: 75em) {
  .c-about__vision .c-brands {
    margin-top: 5rem;
  }
}

.c-about__values .c-action-list__items {
  grid-gap: 2rem;
}

@media (min-width: 55em) {
  .c-about__leadership .c-related-profiles__list {
    grid-template-columns: repeat(5, 1fr);
  }
}

.c-about__celebrate {
  position: relative;
  padding-top: 6.8571428571%;
  position: relative;
  padding-bottom: 5.7142857143%;
}
@media (min-width: 87.5em) {
  .c-about__celebrate {
    padding-top: 6rem;
  }
}
.c-about__celebrate > .l-container {
  position: relative;
  z-index: 1;
}
.c-about__celebrate:before {
  content: "";
  background-image: url("../assets/img/section-bg/wl--3.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 6.8571428571%;
  top: 0;
  left: 0;
  z-index: 0;
  transform: translateY(-50%);
}
@media (min-width: 87.5em) {
  .c-about__celebrate:before {
    padding-top: 6rem;
    background-size: 100% 6rem;
  }
}
@media (min-width: 87.5em) {
  .c-about__celebrate {
    padding-bottom: 5rem;
  }
}
.c-about__celebrate > .l-container {
  position: relative;
  z-index: 1;
}
.c-about__celebrate:after {
  content: "";
  background-image: url("../assets/img/section-bg/lw--2.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 5.7142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-about__celebrate:after {
    padding-top: 5rem;
    background-size: 100% 5rem;
  }
}
.c-about__celebrate:after {
  border-bottom: 10rem solid #ffffff;
}

.c-about__invest .c-media-block {
  margin-bottom: 5rem;
}
.c-about__invest .c-media-block:last-child {
  margin-bottom: 0;
}

.c-work__intro {
  position: relative;
  padding-bottom: 24.2857142857%;
}
@media (min-width: 87.5em) {
  .c-work__intro {
    padding-bottom: 21.25rem;
  }
}
.c-work__intro > .l-container {
  position: relative;
  z-index: 1;
}
.c-work__intro:after {
  content: "";
  background-image: url("../assets/img/section-bg/wl--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 24.2857142857%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-work__intro:after {
    padding-top: 21.25rem;
    background-size: 100% 21.25rem;
  }
}

.c-work__expertise {
  position: relative;
  padding-bottom: 8.2142857143%;
}
@media (min-width: 87.5em) {
  .c-work__expertise {
    padding-bottom: 7.1875rem;
  }
}
.c-work__expertise > .l-container {
  position: relative;
  z-index: 1;
}
.c-work__expertise:after {
  content: "";
  background-image: url("../assets/img/section-bg/lw--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8.2142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
  transform: translateY(50%);
}
@media (min-width: 87.5em) {
  .c-work__expertise:after {
    padding-top: 7.1875rem;
    background-size: 100% 7.1875rem;
  }
}

.c-work__approach {
  position: relative;
  padding-bottom: 11.7142857143%;
}
@media (min-width: 87.5em) {
  .c-work__approach {
    padding-bottom: 10.25rem;
  }
}
.c-work__approach > .l-container {
  position: relative;
  z-index: 1;
}
.c-work__approach:after {
  content: "";
  background-image: url("../assets/img/section-bg/wl--2.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 11.7142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
  transform: translateY(50%);
}
@media (min-width: 87.5em) {
  .c-work__approach:after {
    padding-top: 10.25rem;
    background-size: 100% 10.25rem;
  }
}
.c-work__approach .c-section__content {
  display: grid;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
}
@media (max-width: 54.9375em) {
  .c-work__approach .c-section__content {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 55em) {
  .c-work__approach .c-section__content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 54.9375em) {
  .c-work__approach-content,
  .c-work__approach-image {
    width: 100%;
  }
}
@media (min-width: 55em) {
  .c-work__approach-content {
    grid-column: 1;
    grid-row: 1;
  }
  .c-work__approach-image {
    grid-column: 2;
    grid-row: 1;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .c-work__approach-image img {
    max-height: 100%;
    object-fit: cover;
  }
}
.c-work__approach-content > *:first-child {
  margin-top: 0;
}
.c-work__approach-content > *:last-child {
  margin-bottom: 0;
}

.c-work__approach-image img {
  width: 100%;
}

.c-work__testimonials .c-testimonials__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
.c-work__testimonials .c-testimonials__item {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex: 1 1 30rem;
  margin-bottom: 2.5rem;
}
.c-work__testimonials .c-blockquote {
  margin-top: 0;
  margin-bottom: 0;
  flex: 1 1 20rem;
}

@media (min-width: 75em) {
  .c-launchcon__intro {
    padding-bottom: 9rem;
  }
}

.c-launchcon__video {
  position: relative;
  padding-top: 24.2857142857%;
  position: relative;
  padding-bottom: 8.2142857143%;
  padding-top: 0;
}
@media (min-width: 87.5em) {
  .c-launchcon__video {
    padding-top: 21.25rem;
  }
}
.c-launchcon__video > .l-container {
  position: relative;
  z-index: 1;
}
.c-launchcon__video:before {
  content: "";
  background-image: url("../assets/img/section-bg/wl--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 24.2857142857%;
  top: 0;
  left: 0;
  z-index: 0;
  transform: translateY(-50%);
}
@media (min-width: 87.5em) {
  .c-launchcon__video:before {
    padding-top: 21.25rem;
    background-size: 100% 21.25rem;
  }
}
@media (min-width: 87.5em) {
  .c-launchcon__video {
    padding-bottom: 7.1875rem;
  }
}
.c-launchcon__video > .l-container {
  position: relative;
  z-index: 1;
}
.c-launchcon__video:after {
  content: "";
  background-image: url("../assets/img/section-bg/lw--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8.2142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
  transform: translateY(50%);
}
@media (min-width: 87.5em) {
  .c-launchcon__video:after {
    padding-top: 7.1875rem;
    background-size: 100% 7.1875rem;
  }
}

.c-contact .c-page__main {
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 8%;
  background-color: #e9f7fb;
}
@media (min-width: 87.5em) {
  .c-contact .c-page__main {
    padding-bottom: 7rem;
  }
}
.c-contact .c-page__main > .l-container {
  position: relative;
  z-index: 1;
}
.c-contact .c-page__main:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-contact .c-page__main:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
.c-contact .c-page__main:after {
  bottom: -0.0625rem;
}
@media (min-width: 87.5em) {
  .c-contact .c-page__main {
    padding-bottom: 7rem;
  }
}

.c-careers__intro {
  position: relative;
  padding-bottom: 24.2857142857%;
}
@media (min-width: 87.5em) {
  .c-careers__intro {
    padding-bottom: 21.25rem;
  }
}
.c-careers__intro > .l-container {
  position: relative;
  z-index: 1;
}
.c-careers__intro:after {
  content: "";
  background-image: url("../assets/img/section-bg/wl--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 24.2857142857%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-careers__intro:after {
    padding-top: 21.25rem;
    background-size: 100% 21.25rem;
  }
}
.c-careers__intro:after {
  border-bottom: 10rem solid #e9f7fb;
}

.c-careers__business {
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 0;
}
@media (min-width: 87.5em) {
  .c-careers__business {
    padding-bottom: 7rem;
  }
}
.c-careers__business > .l-container {
  position: relative;
  z-index: 1;
}
.c-careers__business:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-careers__business:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
@media (min-width: 87.5em) {
  .c-careers__business {
    padding-bottom: 0;
  }
}
.c-careers__business:after {
  bottom: -0.0625rem;
}
.c-careers__business:after {
  display: block;
  position: relative;
}
@media (max-width: 34.9375em) {
  .c-careers__business:after {
    margin-top: 3rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-careers__business:after {
    margin-top: 4.5rem;
  }
}
@media (min-width: 55em) {
  .c-careers__business:after {
    margin-top: 6rem;
  }
}

.c-careers__perks {
  position: relative;
  padding-top: 8%;
  padding-top: 8%;
  padding-top: 0;
}
@media (min-width: 87.5em) {
  .c-careers__perks {
    padding-top: 7rem;
  }
}
.c-careers__perks > .l-container {
  position: relative;
  z-index: 1;
}
.c-careers__perks:before {
  content: "";
  background-image: url("../assets/img/section-bg/pt--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  top: 0;
  left: 0;
  z-index: 0;
}
@media (min-width: 87.5em) {
  .c-careers__perks:before {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
@media (min-width: 87.5em) {
  .c-careers__perks {
    padding-top: 0;
  }
}
.c-careers__perks:before {
  bottom: -0.0625rem;
}
.c-careers__perks:before {
  display: block;
  position: relative;
}
@media (max-width: 34.9375em) {
  .c-careers__perks:before {
    margin-bottom: 3rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-careers__perks:before {
    margin-bottom: 4.5rem;
  }
}
@media (min-width: 55em) {
  .c-careers__perks:before {
    margin-bottom: 6rem;
  }
}

.c-careers__celebrate {
  position: relative;
  padding-top: 6.8571428571%;
  position: relative;
  padding-bottom: 8.2142857143%;
}
@media (min-width: 87.5em) {
  .c-careers__celebrate {
    padding-top: 6rem;
  }
}
.c-careers__celebrate > .l-container {
  position: relative;
  z-index: 1;
}
.c-careers__celebrate:before {
  content: "";
  background-image: url("../assets/img/section-bg/wl--3.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 6.8571428571%;
  top: 0;
  left: 0;
  z-index: 0;
  transform: translateY(-50%);
}
@media (min-width: 87.5em) {
  .c-careers__celebrate:before {
    padding-top: 6rem;
    background-size: 100% 6rem;
  }
}
@media (min-width: 87.5em) {
  .c-careers__celebrate {
    padding-bottom: 7.1875rem;
  }
}
.c-careers__celebrate > .l-container {
  position: relative;
  z-index: 1;
}
.c-careers__celebrate:after {
  content: "";
  background-image: url("../assets/img/section-bg/lw--1.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8.2142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-careers__celebrate:after {
    padding-top: 7.1875rem;
    background-size: 100% 7.1875rem;
  }
}
.c-careers__celebrate:after {
  border-bottom: 10rem solid #ffffff;
}
@media (min-width: 35em) {
  .c-careers__celebrate .c-figure {
    margin-top: 5rem;
  }
}

.c-page--one-column.c-linkbuilding .c-section.c-section--center {
  padding-bottom: 6rem;
}
.c-page--one-column.c-linkbuilding .c-section--center .c-section__title:after {
  margin-bottom: 2rem;
}
.c-page--one-column.c-linkbuilding div.c-section--center h2.c-section__title {
  margin: 0 auto;
  max-width: 46rem;
}
.c-page--one-column.c-linkbuilding div.c-section--center .c-section__description .c-wysiwyg {
  margin: 0 auto;
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  max-width: stretch;
  width: max-content;
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding div.c-section--center .c-section__description .c-wysiwyg {
    max-width: 54rem;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__intro .c-section__header {
  margin-bottom: 0;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__intro.c-section.c-section--center {
  padding-bottom: 0;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-1 .c-section__content {
  padding: 4rem 0;
  align-items: center;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-1 .c-linkbuilding__info-1_content {
  margin-right: 2rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-1 .c-linkbuilding__info-1_image img {
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  max-width: stretch;
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-1 .c-section__content {
    display: flex;
    column-gap: 2rem;
  }
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-1 .c-linkbuilding__info-1_content,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-1 .c-linkbuilding__info-1_image {
    width: 48%;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-2 {
  padding-bottom: 4rem;
  padding-top: 8rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-2 .c-section__header {
  margin-bottom: 0;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-2 .c-pro-con__list {
  max-width: 46rem;
  margin: 0 auto;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-3.c-section--center.c-section {
  padding-top: 8rem;
  padding-bottom: 9rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-3 .c-action-list__item .c-action-block--card {
  background-color: #ffffff;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-3 .c-action-list__item .c-action-block--card.c-action-block {
  display: block;
  text-align: left;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-3 .c-action-list__item .c-action-block--card .c-action-block__title {
  padding-top: 2rem;
  margin-bottom: 1rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 {
  padding-top: 8rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-linkbuilding_media-block {
  align-items: center;
  column-gap: 4rem;
  display: block;
  justify-content: center;
  margin-bottom: 4rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-linkbuilding_media-block.left picture {
  order: 1;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-linkbuilding_media-block.left div {
  order: 2;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-linkbuilding_media-block .c-linkbuilding__info-4-image {
  display: block;
  text-align: center;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-linkbuilding_media-block .c-linkbuilding__info-4-image img {
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  max-width: stretch;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-linkbuilding_media-block .c-linkbuilding__info-4-content h3:first-child {
  margin-top: 0;
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-linkbuilding_media-block {
    column-gap: 2rem;
    display: flex;
  }
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-linkbuilding_media-block .c-linkbuilding__info-4-image,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-linkbuilding_media-block .c-linkbuilding__info-4-content {
    width: 48%;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-4 .c-section__title.c-linkbuilding__info-4 {
  padding-top: 2rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__focus {
  margin-bottom: 4rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__focus.c-section {
  padding-top: 4rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-section__title {
  max-width: 40rem;
  margin: 0 auto 2rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-section__header {
  margin-bottom: 2rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-action-list__items {
  grid-gap: 2rem;
}
@media (max-width: 54.9375em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-action-list__items {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-action-list__items {
    grid-template-columns: repeat(3, 1fr);
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-action-block {
  flex-direction: column;
}
@media (min-width: 35em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-action-block {
    flex-direction: row;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-action-block__image {
  margin-right: 0;
  margin-bottom: 2rem;
}
@media (min-width: 35em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-action-block__image {
    margin-right: 2rem;
    margin-bottom: 0;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-action-block__copy {
  text-align: center;
}
@media (min-width: 35em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__focus .c-action-block__copy {
    text-align: left;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__work,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-1,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-3 {
  position: relative;
  padding-top: 6.8571428571%;
  position: relative;
  padding-bottom: 5.7142857143%;
}
@media (min-width: 87.5em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__work,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-1,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-3 {
    padding-top: 6rem;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__work > .l-container,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-1 > .l-container,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-3 > .l-container {
  position: relative;
  z-index: 1;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__work:before,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-1:before,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-3:before {
  content: "";
  background-image: url("../assets/img/section-bg/wl--3.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 6.8571428571%;
  top: 0;
  left: 0;
  z-index: 0;
  transform: translateY(-50%);
}
@media (min-width: 87.5em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__work:before,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-1:before,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-3:before {
    padding-top: 6rem;
    background-size: 100% 6rem;
  }
}
@media (min-width: 87.5em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__work,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-1,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-3 {
    padding-bottom: 5rem;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__work > .l-container,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-1 > .l-container,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-3 > .l-container {
  position: relative;
  z-index: 1;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__work:after,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-1:after,
.c-page--one-column.c-linkbuilding .c-linkbuilding__info-3:after {
  content: "";
  background-image: url("../assets/img/section-bg/lw--2.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 5.7142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
  transform: translateY(50%);
}
@media (min-width: 87.5em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__work:after,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-1:after,
  .c-page--one-column.c-linkbuilding .c-linkbuilding__info-3:after {
    padding-top: 5rem;
    background-size: 100% 5rem;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav {
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  margin-bottom: 2rem;
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav {
    grid-template-columns: repeat(3, 1fr);
  }
  .c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav .c-linkbuilding__cs-cards:nth-child(even) {
    -webkit-transform: translateY(2rem);
    transform: translateY(2rem);
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav li {
  text-align: center;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav a {
  display: block;
  text-decoration: none;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav a:hover strong {
  text-decoration: underline;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav img {
  display: block;
  margin: 0 auto 1rem;
  padding: 2rem 0;
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav img {
    padding: 8rem 0;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav strong {
  display: block;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav .c-linkbuilding__cs-cards {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav .c-linkbuilding__cs-cards:nth-child(1) {
  background-color: #005C45;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav .c-linkbuilding__cs-cards:nth-child(2) {
  background-color: #ffffff;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__cs-nav .c-linkbuilding__cs-cards:nth-child(3) {
  background-color: #304EBB;
}
@media (max-width: 54.9375em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__case-study {
    margin-bottom: 6rem;
  }
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__case-study {
    margin-bottom: 9rem;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-study:last-child {
  margin-bottom: 0;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-study .c-media-block__image {
  width: 100%;
  max-width: 25rem;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-study .c-media-block__content span.c-case-study__title-duration {
  display: block;
  font-weight: 800;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-study.c-case-study__full-width-title .c-media-block .c-media-block__media {
  display: none;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-study.c-case-study__full-width-title .c-media-block__content {
  width: 100%;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-study .c-stat-text__black {
  color: #000000;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-study#asbestoscom .c-linkbuilding__stat {
  text-align: left;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-boxes {
  display: grid;
  grid-gap: 2rem;
  margin-top: 3rem;
}
@media (max-width: 54.9375em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__case-boxes {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__case-boxes {
    align-items: stretch;
    grid-template-columns: repeat(2, 1fr);
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-stats {
  background-color: #e9f7fb;
  display: grid;
  grid-template-columns: repeat(auto-fit, 14rem);
  justify-content: space-evenly;
  grid-gap: 2rem;
  border-radius: 0.375rem;
  padding: 2rem;
  align-items: flex-start;
  align-content: center;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__stat {
  font-weight: 700;
  text-align: center;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__stat .number {
  font-size: 2.0625rem;
  line-height: 1.2121212121;
  font-family: "Muli", sans-serif;
  color: #161c3b;
  display: block;
  line-height: 1;
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__stat .number {
    font-size: 3.625rem;
    line-height: 1.2413793103;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__stat .text {
  font-size: 1.0625rem;
  line-height: 1.4117647059;
  color: #35caf9;
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-linkbuilding__stat .text {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__case-image img {
  border-radius: 0.375rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.c-page--one-column.c-linkbuilding .c-linkbuilding__why img {
  border-radius: 0.375rem;
}
.c-page--one-column.c-linkbuilding .c-icon-list {
  display: grid;
}
@media (min-width: 35em) {
  .c-page--one-column.c-linkbuilding .c-icon-list {
    display: flex;
  }
}
@media (min-width: 55em) {
  .c-page--one-column.c-linkbuilding .c-icon-list li.c-icon-list__item {
    width: 35%;
  }
}
.c-page--one-column.c-linkbuilding .c-pro-con__list.c-pro-con__list--cons .c-ul {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.c-services__form {
  position: relative;
  padding-top: 6.8571428571%;
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 8%;
}
@media (min-width: 87.5em) {
  .c-services__form {
    padding-top: 6rem;
  }
}
.c-services__form > .l-container {
  position: relative;
  z-index: 1;
}
.c-services__form:before {
  content: "";
  background-image: url("../assets/img/section-bg/wl--3.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 6.8571428571%;
  top: 0;
  left: 0;
  z-index: 0;
}
@media (min-width: 87.5em) {
  .c-services__form:before {
    padding-top: 6rem;
    background-size: 100% 6rem;
  }
}
@media (min-width: 87.5em) {
  .c-services__form {
    padding-bottom: 7rem;
  }
}
.c-services__form > .l-container {
  position: relative;
  z-index: 1;
}
.c-services__form:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-services__form:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
.c-services__form:after {
  bottom: -0.0625rem;
}
@media (min-width: 87.5em) {
  .c-services__form {
    padding-bottom: 7rem;
  }
}
.c-services__form .c-section__content {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: minmax(1fr, 30rem);
}
@media (max-width: 54.9375em) {
  .c-services__form .c-section__content {
    text-align: center;
  }
  .c-services__form .c-section__content h2:after {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 55em) {
  .c-services__form .c-section__content {
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
  }
}
.c-services__form h3.c-services__form-title {
  margin-top: 0;
}
.c-services__form .c-services__form-copy {
  max-width: 30rem;
}

.c-404 .c-page__main {
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 8%;
}
@media (min-width: 87.5em) {
  .c-404 .c-page__main {
    padding-bottom: 7rem;
  }
}
.c-404 .c-page__main > .l-container {
  position: relative;
  z-index: 1;
}
.c-404 .c-page__main:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-404 .c-page__main:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
.c-404 .c-page__main:after {
  bottom: -0.0625rem;
}
@media (min-width: 87.5em) {
  .c-404 .c-page__main {
    padding-bottom: 7rem;
  }
}

.c-thank-you .c-page__main {
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 8%;
  background-color: #e9f7fb;
}
@media (min-width: 87.5em) {
  .c-thank-you .c-page__main {
    padding-bottom: 7rem;
  }
}
.c-thank-you .c-page__main > .l-container {
  position: relative;
  z-index: 1;
}
.c-thank-you .c-page__main:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-thank-you .c-page__main:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
.c-thank-you .c-page__main:after {
  bottom: -0.0625rem;
}
@media (min-width: 87.5em) {
  .c-thank-you .c-page__main {
    padding-bottom: 7rem;
  }
}

.c-page--default .c-page__main {
  position: relative;
  padding-bottom: 8%;
  padding-bottom: 8%;
}
@media (min-width: 87.5em) {
  .c-page--default .c-page__main {
    padding-bottom: 7rem;
  }
}
.c-page--default .c-page__main > .l-container {
  position: relative;
  z-index: 1;
}
.c-page--default .c-page__main:after {
  content: "";
  background-image: url("../assets/img/section-bg/pre-footer.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 8%;
  bottom: 0;
  z-index: 0;
  left: 0;
}
@media (min-width: 87.5em) {
  .c-page--default .c-page__main:after {
    padding-top: 7rem;
    background-size: 100% 7rem;
  }
}
.c-page--default .c-page__main:after {
  bottom: -0.0625rem;
}
@media (min-width: 87.5em) {
  .c-page--default .c-page__main {
    padding-bottom: 7rem;
  }
}

.c-vote {
  margin-left: auto;
  margin-right: auto;
}
.c-vote > div {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media (max-width: 34.9375em) {
  .c-vote > div {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.c-vote > div img {
  padding: 0 0 2rem 0;
  border-radius: 0.375rem;
}
@media (max-width: 34.9375em) {
  .c-vote > div img {
    padding: 0;
  }
}
.c-vote > div .c-figure__image {
  margin: 0;
}
.c-vote .c-wysiwyg {
  max-width: 48rem;
}
.c-vote .c-vote__list {
  padding: 2rem;
  background-color: #e9f7fb;
}
.c-vote .c-vote__list .c-vote__list-title {
  color: #000000;
  font-size: 1.25rem;
  margin: 1.25rem 0;
  font-weight: 700;
  border-bottom: 0.125rem solid #cfd7f7;
  padding-bottom: 1.25rem;
}
.c-vote .c-vote__list .c-vote__dl-entry {
  padding: 1.25rem 0;
}
.c-vote .c-vote__list li {
  font-weight: 500;
}
.c-vote .c-vote__faq-text {
  max-width: 50rem;
}
.c-vote .c-vote__factbox {
  padding: 1.25rem 2.5rem;
  border-left: solid 0.1875rem #add454;
}
.c-vote .c-vote__factbox .c-factbox__title {
  text-transform: uppercase;
  font-weight: 700;
  color: #add454;
  font-size: 1.067;
}
.c-vote .c-vote__factbox .c-factbox__copy {
  font-size: 1.75rem;
}
@media (max-width: 34.9375em) {
  .c-vote .c-vote__factbox .c-factbox__copy {
    font-size: 1.25rem;
  }
}

.c-vote__center {
  margin-left: auto;
  margin-right: auto;
  max-width: 48rem;
}

.c-hero--archive {
  margin-bottom: 3rem;
  padding-top: 4rem;
  padding-bottom: 5.25rem;
  background-color: #252e62;
  content: "";
}
@media (max-width: 34.9375em) {
  .c-hero--archive {
    padding-bottom: 2.2rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-hero--archive {
    padding-bottom: 4.5rem;
  }
}

.c-archive__featured {
  max-width: 100%;
}
@media (max-width: 34.9375em) {
  .c-archive__featured {
    margin-bottom: 2rem;
  }
}
@media (min-width: 35em) {
  .c-archive__featured {
    margin-bottom: 4rem;
  }
  .c-archive__featured .c-post-block__inner {
    display: flex;
    align-items: stretch;
  }
  .c-archive__featured .c-post-block__image-wrapper {
    width: 33.3333333333%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-archive__featured .c-post-block__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .c-archive__featured .c-post-block__content {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .c-archive__featured .c-post-block__image-wrapper {
    width: 60%;
  }
}
.c-archive__featured .c-post-block__content .c-post-block__link .c-post-block__title {
  font-size: 2.25rem;
}
.c-archive__featured .c-post-block__content .c-post-block__top-content {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.c-archive__featured .c-post-block__content .c-post-block__category {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.c-archive__featured .c-post-block__content .c-post-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 0.25rem;
}

@media (min-width: 35em) {
  .c-archive--full .c-archive__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-column-gap: 2rem;
  }
  .c-archive__list .c-archive__sub {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-column-gap: 2rem;
  }
  .c-archive__list .c-archive__sub .c-post-block {
    max-width: 100%;
  }
}

@media (max-width: 34.9375em) {
  .c-archive--sidebar .c-post-block .c-post-block__inner {
    display: flex;
    align-items: stretch;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image-wrapper {
    width: 33.3333333333%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .c-archive--sidebar .c-post-block .c-post-block__content {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image-wrapper {
    width: 25%;
  }
  .c-archive--sidebar .c-post-block .c-post-block__content {
    padding: 0 1rem;
  }
  .c-archive--sidebar .c-post-block .c-post-block__category,
  .c-archive--sidebar .c-post-block .c-post-block__copy,
  .c-archive--sidebar .c-post-block .c-profile-block {
    display: none;
  }
}
@media (min-width: 35em) {
  .c-archive--sidebar .c-post-block {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .c-archive--sidebar .c-post-block .c-post-block__inner {
    display: flex;
    align-items: stretch;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image-wrapper {
    width: 33.3333333333%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .c-archive--sidebar .c-post-block .c-post-block__content {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.c-archive__pagination {
  margin-top: 0.125rem;
  margin-bottom: 2rem;
}

@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-archive--sidebar .c-page__aside {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
}

.c-page--single .c-hero__content,
.c-page--single .c-page__footer {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}
.c-page--single .c-page__main {
  max-width: 100%;
  margin: 3rem auto 0 auto;
}
.c-page--single .c-page__main .c-wysiwyg {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.5rem;
}
@media (max-width: 34.9375em) {
  .c-page--single .c-page__main .c-wysiwyg {
    padding: 0 1rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-page--single .c-page__main .c-wysiwyg {
    padding: 0 1rem;
  }
}
.c-page--single .c-page__main .c-wysiwyg .c-blockquote {
  text-align: center;
}
.c-page--single .c-page__main .c-wysiwyg .c-blockquote .c-blockquote__copy {
  font-size: 1.25rem;
  color: #252e62;
  font-weight: 500;
  line-height: 2rem;
}
.c-page--single .c-page__main .c-wysiwyg .c-blockquote .c-blockquote__author {
  font-size: 0.9rem;
  color: #737373;
}
.c-page--single .c-page__main .c-wysiwyg .c-blockquote .c-blockquote__author:before {
  content: "— ";
}
.c-page--single .c-page__main .c-wysiwyg .c-blockquote img {
  border-radius: 0.375rem;
  object-fit: cover;
  width: 64px;
  height: 64px;
}
.c-page--single .c-page__main .c-post-intro {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  padding: 0 1rem;
}
@media (min-width: 35em) {
  .c-page--single .c-page__main .c-post-intro {
    padding: 0 1rem;
  }
}
.c-page--single .c-page__main .c-post-intro .c-hero__title {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  color: #161c3b;
}
.c-page--single .c-page__main .c-post-intro .c-post-block__category {
  text-decoration: none;
}
.c-page--single .c-page__featured {
  margin-top: 0;
  max-width: 70rem;
  position: relative;
}
.c-page--single .c-page__featured img {
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.c-page--single .c-page__featured::after {
  display: block;
  content: "";
  /* 16:9 aspect ratio */
  padding-bottom: 56.25%;
}

.c-page--one-column.c-services .c-services__info-content {
  display: block;
  margin: 0 auto;
}
@media (min-width: 55em) {
  .c-page--one-column.c-services .c-services__info-content {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
}
.c-page--one-column.c-services .c-services__info-content.left .c-services__info-copy {
  order: 2;
}
.c-page--one-column.c-services .c-services__info-content .c-services__info-copy {
  overflow: auto;
  margin-bottom: 2rem;
}
@media (min-width: 55em) {
  .c-page--one-column.c-services .c-services__info-content .c-services__info-copy,
  .c-page--one-column.c-services .c-services__info-content .c-services__info-image {
    width: 48%;
    margin-bottom: 0;
  }
}
.c-page--one-column.c-services .c-services__info-content .c-services__info-image img {
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  max-width: stretch;
}
.c-page--one-column.c-services .c-section.c-section--center {
  padding-bottom: 0;
}
.c-page--one-column.c-services .c-section.c-section--center.c-services__info-3 {
  padding-bottom: 6rem;
}

.c-page--one-column.c-digital-pr .c-section--center {
  padding-bottom: 6rem;
}
.c-page--one-column.c-digital-pr .c-digital-pr__intro {
  padding-bottom: 0;
}
.c-page--one-column.c-digital-pr .c-digital-pr__intro h2.c-section__title {
  max-width: 50rem;
  margin: 0 auto;
}
.c-page--one-column.c-digital-pr .c-digital-pr__intro .c-wysiwyg {
  max-width: 58rem;
}
.c-page--one-column.c-digital-pr .c-digital-pr__focus {
  margin: 0 auto 6rem;
  max-width: 58rem;
  padding-top: 0;
  padding-bottom: 2rem;
}
.c-page--one-column.c-digital-pr .c-digital-pr__info-1.c-section.c-section--light {
  padding-top: 8rem;
  padding-bottom: 9rem;
}
.c-page--one-column.c-digital-pr .c-digital-pr__info-1 .c-action-block.c-action-block--card {
  background-color: #ffffff;
  display: block;
}
.c-page--one-column.c-digital-pr .c-digital-pr__info-1 .c-action-block.c-action-block--card .c-action-block__image {
  text-align: left;
  margin-bottom: 2rem;
}
.c-page--one-column.c-digital-pr .c-digital-pr__info-1 .c-action-block__title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.c-page--one-column.c-digital-pr .c-digital-pr__media-block {
  display: block;
}
.c-page--one-column.c-digital-pr .c-digital-pr__media-block .c-digital-pr__info-2-image img {
  display: block;
  margin: auto;
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  max-width: stretch;
  text-align: center;
}
@media (min-width: 55em) {
  .c-page--one-column.c-digital-pr .c-digital-pr__media-block {
    display: flex;
    column-gap: 2rem;
  }
  .c-page--one-column.c-digital-pr .c-digital-pr__media-block .c-digital-pr__info-2-image,
  .c-page--one-column.c-digital-pr .c-digital-pr__media-block .c-digital-pr__media-block-content {
    width: 48%;
  }
}
.c-page--one-column.c-digital-pr .c-digital-pr__media-block h3 {
  margin-top: 2rem;
}
.c-page--one-column.c-digital-pr .c-digital-pr__media-block h3:first-child {
  margin-top: 0;
}
.c-page--one-column.c-digital-pr .c-digital-pr__spotlight {
  margin: 0 auto;
  max-width: 54rem;
  padding-bottom: 2rem;
}
.c-page--one-column.c-digital-pr .c-digital-pr__spotlight .c-digital-pr__spotlight-article {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  margin-bottom: 2rem;
  padding: 2rem;
}
@media (min-width: 55em) {
  .c-page--one-column.c-digital-pr .c-digital-pr__spotlight .c-digital-pr__spotlight-article {
    margin-bottom: 4rem;
  }
}
.c-page--one-column.c-digital-pr .c-digital-pr__info-1,
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen {
  position: relative;
  padding-top: 6.8571428571%;
  position: relative;
  padding-bottom: 5.7142857143%;
}
@media (min-width: 87.5em) {
  .c-page--one-column.c-digital-pr .c-digital-pr__info-1,
  .c-page--one-column.c-digital-pr .c-digital-pr__as-seen {
    padding-top: 6rem;
  }
}
.c-page--one-column.c-digital-pr .c-digital-pr__info-1 > .l-container,
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen > .l-container {
  position: relative;
  z-index: 1;
}
.c-page--one-column.c-digital-pr .c-digital-pr__info-1:before,
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen:before {
  content: "";
  background-image: url("../assets/img/section-bg/wl--3.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 6.8571428571%;
  top: 0;
  left: 0;
  z-index: 0;
  transform: translateY(-50%);
}
@media (min-width: 87.5em) {
  .c-page--one-column.c-digital-pr .c-digital-pr__info-1:before,
  .c-page--one-column.c-digital-pr .c-digital-pr__as-seen:before {
    padding-top: 6rem;
    background-size: 100% 6rem;
  }
}
@media (min-width: 87.5em) {
  .c-page--one-column.c-digital-pr .c-digital-pr__info-1,
  .c-page--one-column.c-digital-pr .c-digital-pr__as-seen {
    padding-bottom: 5rem;
  }
}
.c-page--one-column.c-digital-pr .c-digital-pr__info-1 > .l-container,
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen > .l-container {
  position: relative;
  z-index: 1;
}
.c-page--one-column.c-digital-pr .c-digital-pr__info-1:after,
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen:after {
  content: "";
  background-image: url("../assets/img/section-bg/lw--2.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 5.7142857143%;
  bottom: 0;
  z-index: 0;
  left: 0;
  transform: translateY(50%);
}
@media (min-width: 87.5em) {
  .c-page--one-column.c-digital-pr .c-digital-pr__info-1:after,
  .c-page--one-column.c-digital-pr .c-digital-pr__as-seen:after {
    padding-top: 5rem;
    background-size: 100% 5rem;
  }
}
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen.c-section--center {
  padding: 4rem 0 6rem;
}
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen h2 {
  text-align: center;
}
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen h2::after {
  margin: 1rem auto 0;
}
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen .c-digital-pr__as-seen-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2rem;
  justify-content: center;
  margin: auto;
  padding: 0 2rem;
}
@media (min-width: 55em) {
  .c-page--one-column.c-digital-pr .c-digital-pr__as-seen .c-digital-pr__as-seen-list {
    column-gap: 5rem;
  }
}
.c-page--one-column.c-digital-pr .c-digital-pr__as-seen .c-digital-pr__as-seen-list .c-digital-pr__as-seen-logo img {
  max-width: 100%;
  max-height: 12.5rem;
  height: auto;
  width: auto;
}

.post-type-archive-knwl-center-item .c-page__header .c-hero {
  background-image: none;
  margin-bottom: 6rem;
}

.l-page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.c-page {
  flex: 1;
}

.l-container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 75em;
}

.l-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.c-page--one-column > .l-container {
  max-width: 42rem;
}

.c-page__main {
  margin-bottom: 4rem;
}

.c-page__aside {
  margin-bottom: 4rem;
}

.c-page__footer {
  padding-bottom: 3rem;
}

.c-page--two-column .c-page__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
.c-page--two-column .c-page__main {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 55em) {
  .c-page--two-column .c-page__main {
    width: 66.6666666667%;
  }
}
.c-page--two-column .c-page__aside {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 55em) {
  .c-page--two-column .c-page__aside {
    width: 33.3333333333%;
  }
}

.c-page--one-column .c-page__header {
  margin-bottom: 0;
}
.c-page--one-column .c-page__content .c-hummingbird {
  max-width: 75rem;
  margin: 1rem auto;
  padding: 0 1rem;
  position: relative;
}
.c-page--one-column .c-content__after {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}
.c-page--one-column .c-content__after .c-share {
  text-align: center;
}
.c-page--one-column .c-content__after .c-share__list {
  justify-content: center;
}
.c-page--one-column .c-content__after > * {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
.c-page--one-column .c-content__after > :first-child {
  margin-top: 2rem;
  border-top: none;
  padding-top: 0;
}
.c-page--one-column .c-content__after > :last-child {
  margin-bottom: 2rem;
}
.c-page--one-column .c-page__main {
  margin-bottom: 0;
}

.c-page__main {
  margin-bottom: 0;
}

@font-face {
  font-family: "icomoon";
  src: url("../../Ares/global-assets/fonts/icomoon/icomoon.eot?20i1v");
  src: url("../../Ares/global-assets/fonts/icomoon/icomoon.eot?20i1v#iefix") format("embedded-opentype"), url("../../Ares/global-assets/fonts/icomoon/icomoon.woff2?20i1v") format("woff2"), url("../../Ares/global-assets/fonts/icomoon/icomoon.ttf?20i1v") format("truetype"), url("../../Ares/global-assets/fonts/icomoon/icomoon.woff?20i1v") format("woff"), url("../../Ares/global-assets/fonts/icomoon/icomoon.svg?20i1v#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.o-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.o-icon--radio_button_checked:after {
  content: "\e904";
}

.o-icon--radio_button_unchecked:after {
  content: "\e905";
}

.o-icon--home:after {
  content: "\e900";
}

.o-icon--chevron-right:after {
  content: "\e906";
}

.o-icon--chevron-left:after {
  content: "\e907";
}

.o-icon--chevron-down:after {
  content: "\e908";
}

.o-icon--chevron-up:after {
  content: "\e909";
}

.o-icon--close:after {
  content: "\e901";
}

.o-icon--search:after {
  content: "\e903";
}

.o-icon--menu:after {
  content: "\e902";
}

.o-icon--plus:after {
  content: "\f067";
}

.o-icon--minus:after {
  content: "\f068";
}

.o-icon--check:after {
  content: "\f00c";
}

.o-icon--x:after {
  content: "\f00d";
}

.o-icon--lock:after {
  content: "\f023";
}

.o-icon--location:after {
  content: "\f041";
}

.o-icon--check-square-o:after {
  content: "\f046";
}

.o-icon--play:after {
  content: "\f04b";
}

.o-icon--pause:after {
  content: "\f04c";
}

.o-icon--info:after {
  content: "\f05a";
}

.o-icon--arrow-left:after {
  content: "\f060";
}

.o-icon--arrow-right:after {
  content: "\f061";
}

.o-icon--arrow-up:after {
  content: "\f062";
}

.o-icon--arrow-down:after {
  content: "\f063";
}

.o-icon--warning:after {
  content: "\f071";
}

.o-icon--external:after {
  content: "\f08e";
}

.o-icon--phone:after {
  content: "\f095";
}

.o-icon--square-o:after {
  content: "\f096";
}

.o-icon--twitter:after {
  content: "\f099";
}

.o-icon--facebook:after {
  content: "\f09a";
}

.o-icon--expand:after {
  content: "\f0b2";
}

.o-icon--chain:after {
  content: "\f0c1";
}

.o-icon--link:after {
  content: "\f0c1";
}

.o-icon--google-plus:after {
  content: "\f0d5";
}

.o-icon--caret-down:after {
  content: "\f0d7";
}

.o-icon--caret-up:after {
  content: "\f0d8";
}

.o-icon--envelope:after {
  content: "\f0e0";
}

.o-icon--linkedin:after {
  content: "\f0e1";
}

.o-icon--angle-left:after {
  content: "\f104";
}

.o-icon--angle-right:after {
  content: "\f105";
}

.o-icon--angle-up:after {
  content: "\f106";
}

.o-icon--angle-down:after {
  content: "\f107";
}

.o-icon--quote-left:after {
  content: "\f10d";
}

.o-icon--quote-right:after {
  content: "\f10e";
}

.o-icon--youtube:after {
  content: "\f16a";
}

.o-icon--instagram:after {
  content: "\f16d";
}

.o-icon--question:after {
  content: "\f29c";
}

.o-icon--quora:after {
  content: "\f2c4";
}

.u-clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.u-cover-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

@media (max-width: 54.9375em) {
  body.no-scroll {
    overflow: hidden;
  }
}

.u-sr-only {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  color: #000000;
  background-color: #ffffff;
}

.u-sr-only-focusable:active, .u-sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
  max-width: 60rem;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #ccc;
}
.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.mfp-inline .mfp-content {
  max-width: 40rem;
}

.c-lightbox--video .mfp-close {
  color: #ffffff;
}