.c-vote {

  margin-left: auto;
  margin-right: auto;

  >div {
    padding-top: $spacer--2xl;
    padding-bottom: $spacer--2xl;

    @include media-breakpoint-only(s) {
      padding-top: $spacer--l;
      padding-bottom: $spacer--l;
    }

    img {
      padding: 0 0 $spacer--xl 0;
      border-radius: rem(6);

      @include media-breakpoint-only(s) {
        padding: 0;
      }
    }

    .c-figure__image {
      margin: 0;
    }

  }

  .c-wysiwyg {
    max-width: 48rem;
  }

  .c-vote__list {
    padding: $spacer--xl;
    background-color: $light;

    .c-vote__list-title {
      color: #000000;
      font-size: 1.25rem;
      margin: $spacer--m 0;
      font-weight: 700;
      border-bottom: $spacer--3xs solid #cfd7f7;
      padding-bottom: $spacer--m;
    }

    .c-vote__dl-entry {
      padding: $spacer--m 0;
    }

    li {
      font-weight: 500;
    }

  }

  .c-vote__faq-text {
    max-width: 50rem;
  }

  .c-vote__factbox {
    padding: $spacer--m $spacer--2xl;
    border-left: solid 0.1875rem $secondary;

    .c-factbox__title {
      text-transform: uppercase;
      font-weight: 700;
      color: $secondary;
      font-size: $type-scale-ratio--m;
    }

    .c-factbox__copy {
      font-size: 1.75rem;

      @include media-breakpoint-only(s) {
        font-size: 1.25rem;
      }

    }

  }

}

.c-vote__center {
  margin-left: auto;
  margin-right: auto;
  max-width: 48rem;
}