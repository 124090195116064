.c-page--single {
    .c-hero__content,
    .c-page__footer {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
    }

    //.c-page__content {
        //@include make-container;
    //}

    .c-page__main {
        max-width: 100%;
        margin: 3rem auto 0 auto;

        .c-wysiwyg {
            max-width: 50rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 3.5rem;

            @include media-breakpoint-only(s) {
                padding: 0 1rem;
            }

            @include media-breakpoint-only(m) {
                padding: 0 1rem;
            }

            .c-blockquote {
                text-align: center;

                .c-blockquote__copy {
                    font-size: 1.25rem;
                    color: $primary;
                    font-weight: 500;
                    line-height: 2rem;
                }

                .c-blockquote__author {
                    font-size: .9rem;
                    color: $grey;
    
                    &:before {
                        content: '— ';
                    }
                }
    
                img {
                    border-radius: $border-radius;
                    object-fit: cover;
                    width: 64px;
                    height: 64px;
                }
            }

        }

        .c-post-intro {
            max-width: 50rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 3rem;

            @include media-breakpoint-up(s) {
                padding: 0 1rem;
            }

            @include media-breakpoint-up(m) {
                padding: 0 1rem;
            }

            .c-hero__title {
                margin-top: 1rem;
                margin-bottom: 1.5rem;
                color: #161c3b;
            }

            .c-post-block__category {
                text-decoration: none;
            }
        }
    }

    .c-page__featured {
        margin-top: 0;
        max-width: 70rem;
        position: relative;

        img {
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &::after {
            display: block;
            content: '';
            /* 16:9 aspect ratio */
            padding-bottom: 56.25%;
        }

    }


}
