.c-page--one-column.c-services {
    .c-services__info-content {
        display: block;
        margin: 0 auto;
    
        @include media-breakpoint-up(l) {
            display: flex;
            gap: 2rem;
            align-items: center;
        }
        
        &.left {
            .c-services__info-copy {order:2;}
    
        }
        .c-services__info-copy {
            overflow: auto;
            margin-bottom: 2rem;
        }
        @include media-breakpoint-up(l) {
            .c-services__info-copy,
            .c-services__info-image {
                width: 48%;
                margin-bottom: 0;
            }
    
        }
        
        .c-services__info-image img {
            max-width: -moz-available;
            max-width: -webkit-fill-available;
            max-width: stretch;
        }
    }
        
    .c-section.c-section--center {
        padding-bottom: 0;
    
        &.c-services__info-3 {
            padding-bottom: 6rem;
        }
    }
}