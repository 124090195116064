@mixin cover-parent() {
    position: inherit;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 0;
    }
}
