.c-blockquote--card {
    @include make-card;
    background: $white;
    border-radius: $border-radius;

    @include media-breakpoint-down(s) {
        padding: 1.5rem 2rem;
    }
    @include media-breakpoint-up(m) {
        padding: 3.75rem 3rem 4rem;
    }
}

.c-blockquote__quote {
    @include type-scale(l, 1.5);
}



.c-blockquote__title {
    @include make-h3;
    margin-top: $spacer--l;
    margin-bottom: $spacer--xs;
}

.c-blockquote__subtitle {
    @include make-h4;
    text-transform: none;
    margin-top: 0;
}