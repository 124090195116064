.c-hero--archive {
    margin-bottom: 3rem;
    padding-top: 4rem;
    padding-bottom: 5.25rem;
    background-color: $primary;
    content: '';

    @include media-breakpoint-only(s) {
        padding-bottom: 2.2rem;
    }

    @include media-breakpoint-only(m) {
        padding-bottom: 4.5rem;
    }

}

.c-archive__featured {
    max-width: 100%;

    @include media-breakpoint-only(s) {
        margin-bottom: 2rem;
    }

    @include media-breakpoint-up(m) {
        @include make-post-block-horizontal;
        margin-bottom: 4rem;

        .c-post-block__image-wrapper {
            width: 60%;
        }
    }

    .c-post-block__content {
        .c-post-block__link {
            .c-post-block__title {
                font-size: 2.25rem;
            }
        }

        .c-post-block__top-content {
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }

        .c-post-block__category {
            -webkit-box-flex: 2;
            -ms-flex-positive: 2;
            flex-grow: 2;
            position: relative;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
        }

        .c-post-date {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            margin-bottom: 0.25rem;
        }
    }
}

.c-archive__list {
    @include media-breakpoint-up(m) {
        .c-archive--full & {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            grid-column-gap: $grid-gutter-width;
        }

        .c-archive__sub {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            grid-column-gap: $grid-gutter-width;

            .c-post-block {
                max-width: 100%;
            }
        }
    }
}

//.c-archive--full {
//.c-post-block {
//@include media-breakpoint-only(s) {
//@include make-post-block-horizontal-minimal;
//}
//}
//}

.c-archive--sidebar {
    .c-post-block {
        @include media-breakpoint-only(s) {
            @include make-post-block-horizontal-minimal;
        }
        @include media-breakpoint-up(m) {
            @include make-post-block-horizontal;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }
    }
}

.c-archive__pagination {
    margin-top: rem(2);
    margin-bottom: 2rem;
}

.c-archive--sidebar {
    .c-page__aside {
        @include media-breakpoint-only(m) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: $grid-gutter-width;
        }
    }
}
