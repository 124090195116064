.c-action-block {
    margin-top: 0;
    padding: 0;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
}

.c-action-block--card {
    padding: 2.5rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

.c-action-block__image {
    margin-bottom: 0;
    margin-right: $grid-gutter-width;
}

.c-action-block__content {
    text-align: left;
}

.c-action-block__copy {
    @include type-scale(m, 1.5);
    text-align: left;
}

.c-action-block__title {
    @include make-h3;
    display: block;
    margin-top: 0;
    @include media-breakpoint-down(m) {
        margin-bottom: $spacer
    }

    @include media-breakpoint-up(l) {
        margin-bottom: $spacer--xl;
    }
}





.c-action-block--stacked {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .c-action-block__image {
        margin-right: 0;
        margin-bottom: $spacer--xl;
    }

    .c-action-block__content {
        text-align: center;
    }

    .c-action-block__title {
        margin-bottom: $spacer;
    }

    .c-action-block__copy {
        text-align: center;
        
        span {
            display: block;
        }
    }
}